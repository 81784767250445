import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import Button from "@mui/material/Button"
import FormatItalicIcon from '@mui/icons-material/FormatItalic';

 const ItalicButton = () => {
	const chain = useChainedCommands()
	const active = useActive()
	const { toggleItalic } = useCommands()
	return (
		<Button
			onClick={() => {
				chain // Begin a chain
					.toggleItalic()
					.focus()
					.run() // A chain must always be terminated with `.run()`
			}}
            style={{ backgroundColor: active.italic() ? "rgb(25, 118, 210)" : undefined ,color:active.italic() ? "rgb(255, 255, 255)" : undefined,borderColor:active.italic() ? "rgb(255, 255, 255)" : undefined}}
            variant="outlined"
			disabled={toggleItalic.enabled() === false}
		>
			<FormatItalicIcon/>
		</Button>
	)
}
export default ItalicButton