import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox } from "@mui/material"
import { useMutation } from "@apollo/client"
import { createProcessParticipationTagGQL } from "../processParticipationTagsGQL"
import {
	createProcessParticipationTag as createProcessParticipationTagInterface,
	createProcessParticipationTagVariables,
} from "../__generated__/createProcessParticipationTag"
import { noLowerThan0 } from "../../shared/noLessThan0"
import { useFormik } from "formik"
import * as yup from "yup"

const CreateProcessParticipationTag = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
		description: yup
			.string()
			.required(`${t("description")} is required`)
			.max(200, `${t("description")} can not be longer than 100 characters`),
		priority: yup.number().required(`${t("priority")} is required`),
		limitPerProcess: yup.number(),
		isPrimary: yup.boolean().required(`${t("isPrimary")} is required`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
			description: "",
			priority: 0,
			isPrimary: false,
		},
		validationSchema: validationSchema,
		onSubmit: async (values: createProcessParticipationTagVariables) => {
			const createProcessParticipationTagData = await createProcessParticipationTag({ variables: values })
			if (createProcessParticipationTagData?.data?.createProcessParticipationTag?.__typename) {
				navigate("/process-participation-tags")
			}
		},
	})
	const [createProcessParticipationTag, { data, loading, error }] = useMutation<
		createProcessParticipationTagInterface,
		createProcessParticipationTagVariables
	>(createProcessParticipationTagGQL)
	let navigate = useNavigate()
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createProcessParticipationTags")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "35ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="description"
									name="description"
									label={t("description")}
									value={formik.values.description}
									onChange={formik.handleChange}
									error={formik.touched.description && Boolean(formik.errors.description)}
									helperText={formik.touched.description && formik.errors.description}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="priority"
									name="priority"
									label={t("priority")}
									type="number"
									value={formik.values.priority}
									onChange={formik.handleChange}
									error={formik.touched.priority && Boolean(formik.errors.priority)}
									helperText={formik.touched.priority && formik.errors.priority}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="limitPerProcess"
									name="limitPerProcess"
									label={t("limitPerProcess")}
									type="number"
									value={formik.values.limitPerProcess}
									onChange={formik.handleChange}
									error={formik.touched.limitPerProcess && Boolean(formik.errors.limitPerProcess)}
									helperText={formik.touched.limitPerProcess && formik.errors.limitPerProcess}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControl>
									<FormLabel>{t("isPrimary")}</FormLabel>
									<Checkbox checked={formik.values.isPrimary} id="isPrimary" onChange={formik.handleChange} />
								</FormControl>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default CreateProcessParticipationTag
