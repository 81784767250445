import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import { Button, SvgIcon } from "@mui/material"
import "../Editor.scss"
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

const BulletList = () => {
	// Using command chaining
	const chain = useChainedCommands()
	const { toggleBulletList, setTextCase } = useCommands()

	return (
		<Button
			onClick={() => {
				chain // Begin a chain
					.toggleBulletList()
					.focus()
					.run() // A chain must always be terminated with `.run()`
			}}
			variant="outlined"
			disabled={toggleBulletList.enabled() === false}
		>
			<FormatListBulletedOutlinedIcon />
		</Button>
	)
}
export default BulletList
