import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import Button from "@mui/material/Button"
import FormatBoldIcon from '@mui/icons-material/FormatBold';
 const BoldButton = () => {
	// Using command chaining
	const chain = useChainedCommands()
	const active = useActive()
	const { toggleBold,setTextCase } = useCommands()
 
	return (
		<Button
			onClick={() => {
				chain // Begin a chain
				.toggleBold()
				.focus()
				.run() // A chain must always be terminated with `.run()`
			}}
			style={{ backgroundColor: active.bold() ? "rgb(25, 118, 210)" : undefined ,color:active.bold() ? "rgb(255, 255, 255)" : undefined,borderColor:active.bold() ? "rgb(255, 255, 255)" : undefined}}
            variant="outlined"
			disabled={toggleBold.enabled() === false}
		>
			<FormatBoldIcon/>
		</Button>
	)
}
export default BoldButton