import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useMutation } from "@apollo/client"
import { createEquipmentGQL } from "../equipmentsGQL"
import { createEquipment as createEquipmentInterface, createEquipmentVariables } from "../__generated__/createEquipment"
import { EquipmentType } from "../../../__generated__/globalTypes"
import { useFormik } from "formik"
import * as yup from "yup"

const CreateEquipment = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
		type: yup.string().required(`${t("type")} is required`),
		priceExcludingVat: yup.number().required(`${t("priceExcludingVat")} is required`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
			type: EquipmentType.light,
			priceExcludingVat: "",
		},
		validationSchema: validationSchema,
		onSubmit: async values => {
			const createEquipmentData = await createEquipment({ variables: {
				label: values.label,
				type: values.type,
				priceExcludingVat: Math.round(parseFloat(values.priceExcludingVat) * 100),
			}})
			if (createEquipmentData?.data?.createEquipment?.__typename) {
				navigate("/equipments")
			}
		},
	})
	const [createEquipment, { data, loading, error }] = useMutation<createEquipmentInterface, createEquipmentVariables>(createEquipmentGQL)
	let navigate = useNavigate()

	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createEquipments")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="priceExcludingVat"
									name="priceExcludingVat"
									label={t("priceExcludingVat")}
									value={formik.values.priceExcludingVat}
									onChange={formik.handleChange}
									error={formik.touched.priceExcludingVat && Boolean(formik.errors.priceExcludingVat)}
									helperText={formik.touched.priceExcludingVat && formik.errors.priceExcludingVat}
									variant="outlined"
									type={"number"}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									select
									id="type"
									name="type"
									label={t("type")}
									value={formik.values.type}
									onChange={formik.handleChange}
									error={formik.touched.type && Boolean(formik.errors.type)}
									helperText={formik.touched.type && formik.errors.type}
									variant="outlined"
								>
									{Object.values(EquipmentType).map((category: EquipmentType, index: number) => {
										return (
											<MenuItem key={index} value={category}>
												{String(category)}
											</MenuItem>
										)
									})}
								</TextField>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default CreateEquipment
