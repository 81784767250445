import Routing from "./Module/Routing"

function App() {
	return (
		<div>
			<Routing />
		</div>
	)
}

export default App
