import { useQuery, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { documentVariables, document } from "./__generated__/document"
import { DocumentGQL, updateDocumentGQL } from "./ProcessDocumentGQL"
import { Grid, Paper, Button, Snackbar, Alert, Typography } from "@mui/material"
import MyEditor from "../shared/Editor/Editor"
import { updateDocument } from "./__generated__/updateDocument"
import useDebounce from "../shared/useDebounce"
import "./ProcessDocument.scss"

function documentFilePreviewUrl(type: string, eventId: string, recipientId: string, recipientAddress: any, content: any) {
	return `https://api-staging.mmptdb.de/documents/preview?type=${encodeURIComponent(type)}&eventId=${encodeURIComponent(
		eventId
	)}&recipientId=${encodeURIComponent(recipientId)}&recipientAddress=${encodeURIComponent(JSON.stringify(recipientAddress))}&content=${encodeURIComponent(
		JSON.stringify(content)
	)}`
}

const ProcessDocument = () => {
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const [document, setDocument] = useState<any>(null)
	const [content, setContent] = useState<any>(null)
	const debouncedContent = useDebounce(content, 500)
	const [pdfURL, setPdfURL] = useState<string>("")
	const { documentID, eventId } = JSON.parse(id ? id : "")
	const { loading: loadingGetProcessDocument, data: getProcessDocumentData } = useQuery<document, documentVariables>(DocumentGQL, {
		variables: { id: `${documentID}` },
	})
	useEffect(() => {
		if (!loadingGetProcessDocument) {
			setDocument(getProcessDocumentData?.document)
			setPdfURL(
				documentFilePreviewUrl(
					getProcessDocumentData?.document?.type ? getProcessDocumentData.document.type : "",
					eventId,
					getProcessDocumentData?.document?.recipient?.id ? getProcessDocumentData.document.recipient.id : "",
					JSON.parse(getProcessDocumentData?.document?.recipientAddress ? getProcessDocumentData?.document?.recipientAddress : ""),
					JSON.parse(getProcessDocumentData?.document?.content ? (getProcessDocumentData?.document?.content as unknown as string) : "")
				)
			)
		}
	}, [loadingGetProcessDocument])

	useEffect(() => {
		if (document) {
			setPdfURL(
				documentFilePreviewUrl(
					document?.type ? document.type : "",
					eventId,
					document?.recipient?.id ? document?.recipient.id : "",
					JSON.parse(document?.recipientAddress ? document?.recipientAddress : ""),
					JSON.parse(debouncedContent)
				)
			)
		}
	}, [debouncedContent])

	const [editDocument, { data, loading, error }] = useMutation<updateDocument>(updateDocumentGQL)
	const Submit = async () => {
		const editDocumentData = await editDocument({
			variables: {
				content: debouncedContent,
				id: documentID,
				name: document?.name,
				recipientAddress: document?.recipientAddress,
				recipientId: document?.recipient?.id,
			},
		})
		if (editDocumentData?.data?.updateDocument?.__typename) {
			window.location.replace(`${process.env.VUE_APP_Frontend_URL}/preise.php`)
			
			// window.location.href = "https://staging.mmptdb.de/preise.php"
		}
	}
	return (
		<Paper
			sx={{
				height: "100%",
				width: "100%",
			}}
		>
			<div className="process-document-layout">
				<div>
					<div className="title">
						<Typography variant="h4">{`${t("editDocument")} ${document?.name}`}</Typography>
					</div>
					<Grid item xs={12}>
						<div className="pdfPreview">
							{!loadingGetProcessDocument && document?.content !== null ? (
								<MyEditor content={document?.content} setContent={setContent} />
							) : (
								<div></div>
							)}
						</div>
					</Grid>
					<Grid item xs={12}>
						<Button variant="outlined" onClick={Submit} sx={{ margin: "20px" }}>
							{t("submit")}
						</Button>
					</Grid>
				</div>
				<div className="pdfPreviewContainer">
					<iframe className="pdfPreview" src={pdfURL} />
				</div>
			</div>
		</Paper>
	)
}
export default ProcessDocument
