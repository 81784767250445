import { useQuery } from "@apollo/client"
import { getUserInfoGQL } from "./headerGQL"
import AppBar from "./appBar"
import GreetingHeader from "./greetingHeader"
import {me} from "./__generated__/me"
const Header = () => {
	const { loading, error, data } = useQuery<me>(getUserInfoGQL)
	return (
		<div>
			<AppBar data={data?.me}/>
		</div>
	)
}
export default Header
