import { gql } from "@apollo/client"

export const createDocumentGQL = gql`
	mutation createDocument(
		$content: Text!
		$name: DocumentName!
		$processId: ID!
		$recipientAddress: InlineText = null
		$recipientId: ID!
		$type: DocumentType!
	) {
		createDocument(
			input: { content: $content, name: $name, processId: $processId, recipientAddress: $recipientAddress, recipientId: $recipientId, type: $type }
		) {
			__typename
		}
	}
`

export const ProcessGQL = gql`
	query process($id: ID!) {
		process(id: $id) {
			id
			externalId
			participations {
				contact {
					person1 {
						address {
							city
							country {
								code
								name(locale: "de-DE")
							}
							line1
							line2
							postalCode
						}
						birthday
						canUseInformalCommunication
						changedLastName
						changedLastNameIsAfterWedding
						emailAddress
						firstName
						homePhoneNumber
						id
						lastName
						mobilePhoneNumber
						noNewsletter
						salutation {
							id
							label
						}
						title
					}
					person2 {
						address {
							city
							country {
								code
								name(locale: "de-DE")
							}
							line1
							line2
							postalCode
						}
						birthday
						canUseInformalCommunication
						changedLastName
						changedLastNameIsAfterWedding
						emailAddress
						firstName
						homePhoneNumber
						id
						lastName
						mobilePhoneNumber
						noNewsletter
						salutation {
							id
							label
						}
						title
					}
				}
			}
		}
	}
`
