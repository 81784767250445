import { Box, ButtonGroup } from "@mui/material"
import ReDo from "./Redo"
import UnDo from "./Undo"
const History = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				"& > *": {
					m: 1,
				},
			}}
		>
			<ButtonGroup variant="outlined" aria-label="outlined button group">
                <UnDo/>
                <ReDo/>
			</ButtonGroup>
		</Box>
	)
}
export default History
