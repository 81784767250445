import { Mark, Node as ProsemirrorNode } from "prosemirror-model"
import EditorSchema from "./EditorSchema"
import MInlineText, {
	MInlineTextNode,
	MInlineTextNodeLineBreak,
	MInlineTextNodeSignature,
	MInlineTextNodeText,
	MInlineTextNodeVariable,
} from "./model/MInlineText"
import MInlineTextMark, { MInlineTextMarkEmphasized, MInlineTextMarkLink, MInlineTextMarkSmall, MInlineTextMarkStrong } from "./model/MInlineTextMark"
import MText, {
	MTextNode,
	MTextNodeHeading,
	MTextNodeList,
	MTextNodePageBreak,
	MTextNodeParagraph,
	MTextNodePricingTable,
	MTextNodeSignature,
} from "./model/MText"
import { error } from "../../error"
import { nodes } from "prosemirror-schema-basic"

export function editorDocumentForText(text: MText, schema: EditorSchema): any {
	return schema.node(
		schema.nodes.doc,
		undefined,
		text.nodes.map(it => editorNodeForTextNode(it, schema))
	)
}

export function editorDocumentToText(document: ProsemirrorNode<EditorSchema>, schema: EditorSchema): MText {
	const nodes: MTextNode[] = []
	document.forEach(it => nodes.push(editorNodeToTextNode(it, schema)))

	return new MText(nodes)
}

function editorMarkForInlineTextMark(mark: MInlineTextMark, schema: EditorSchema): Mark<EditorSchema> {
	if (mark instanceof MInlineTextMarkEmphasized) {
		return schema.marks.italic.create()
	}
	if (mark instanceof MInlineTextMarkLink) {
		return schema.marks.link.create({ href: mark.target })
	}
	if (mark instanceof MInlineTextMarkSmall) {
		return schema.marks.small.create()
	}
	if (mark instanceof MInlineTextMarkStrong) {
		return schema.marks.bold.create()
	}

	error()
}

function editorMarkToInlineTextMark(mark: Mark<EditorSchema>, schema: EditorSchema): MInlineTextMark {
	switch (mark.type) {
		case schema.marks.italic:
			return new MInlineTextMarkEmphasized()

		case schema.marks.link:
			return new MInlineTextMarkLink(mark.attrs.href)

		case schema.marks.small:
			return new MInlineTextMarkSmall()

		case schema.marks.bold:
			return new MInlineTextMarkStrong()

		default:
			error(`type: ${mark.type.name}`)
	}
}

function editorMarksForInlineTextMarks(marks: MInlineTextMark[], schema: EditorSchema): Mark<EditorSchema>[] {
	return marks.map(it => editorMarkForInlineTextMark(it, schema))
}

function editorMarksToInlineTextMarks(marks: Mark<EditorSchema>[], schema: EditorSchema): MInlineTextMark[] {
	return marks.map(it => editorMarkToInlineTextMark(it, schema))
}

function editorNodeForInlineTextNode(node: MInlineTextNode, schema: EditorSchema): ProsemirrorNode<EditorSchema> {
	if (node instanceof MInlineTextNodeLineBreak) {
		return schema.node(schema.nodes.hardBreak)
	}
	if (node instanceof MInlineTextNodeSignature) {
		return schema.node(schema.nodes.signature)
	}
	if (node instanceof MInlineTextNodeText) {
		return schema.text(node.value, editorMarksForInlineTextMarks(node.marks, schema))
	}
	// noinspection SuspiciousTypeOfGuard
	if (node instanceof MInlineTextNodeVariable) {
		return schema.node(schema.nodes.variable, { id: node.id }, undefined, editorMarksForInlineTextMarks(node.marks, schema))
	}

	error()
}

function editorNodeToInlineTextNode(node: ProsemirrorNode<EditorSchema>, schema: EditorSchema) {
	switch (node.type) {
		case schema.nodes.hardBreak:
			return new MInlineTextNodeLineBreak()

		case schema.nodes.signature:
			return new MInlineTextNodeSignature()

		case schema.nodes.text:
			return new MInlineTextNodeText(node.textContent, editorMarksToInlineTextMarks(node.marks, schema))

		case schema.nodes.variable:
			return new MInlineTextNodeVariable(node.attrs.id, editorMarksToInlineTextMarks(node.marks, schema))

		case schema.nodes.paragraph:
			return new MInlineTextNodeText(node.textContent, editorMarksToInlineTextMarks(node.marks, schema))

		default:
			error(`type: ${node.type.name}`)
	}
}

export function editorNodesForInlineText(text: MInlineText, schema: EditorSchema): ProsemirrorNode<EditorSchema>[] {
	return editorNodesForInlineTextNodes(text.nodes, schema)
}

function editorNodesForInlineTextNodes(nodes: readonly MInlineTextNode[], schema: EditorSchema): ProsemirrorNode<EditorSchema>[] {
	return nodes.map(it => editorNodeForInlineTextNode(it, schema))
}

export function editorNodesToInlineText(parent: ProsemirrorNode<EditorSchema>, schema: EditorSchema): MInlineText {
	const nodes: MInlineTextNode[] = []
	parent.forEach(it => nodes.push(editorNodeToInlineTextNode(it, schema)))

	return new MInlineText(nodes)
}

function editorNodesToInlineTexts(parent: ProsemirrorNode<EditorSchema>, schema: EditorSchema): MInlineText[] {
	const nodes: MInlineText[] = []
	parent.forEach(it => nodes.push(editorNodesToInlineText(it, schema)))

	return nodes
}

function editorNodeForTextNode(node: MTextNode, schema: EditorSchema): ProsemirrorNode<EditorSchema> {
	switch (node.type) {
		case "heading":
			return schema.node(schema.nodes.heading, { level: node.level }, editorNodesForInlineText(node.content, schema))
		case "list":
			return schema.node(
				node.style === "numeric" ? schema.nodes.orderedList : schema.nodes.bulletList,
				undefined,
				node.items.map(it =>
					schema.node(
						schema.nodes.listItem,
						undefined,
						editorNodesForInlineTextNodes(it.nodes, schema).map(e => schema.nodes["paragraph"].create({}, e, []))
					)
				)
			)

		case "page break":
			return error()

		case "paragraph":
			return schema.node(schema.nodes.paragraph, undefined, editorNodesForInlineText(node.content, schema))

		case "pricing table": // FIXME
			return error()

		default:
			error(`type: ${(node as any).type}`)
	}
}

function editorNodeToTextNode(node: ProsemirrorNode<EditorSchema>, schema: EditorSchema) {
	switch (node.type) {
		case schema.nodes.heading:
			return new MTextNodeHeading(editorNodesToInlineText(node, schema), node.attrs.level)

		case schema.nodes.bulletList:
			return new MTextNodeList(editorNodesToInlineTexts(node, schema), "bulleted")

		case schema.nodes.orderedList:
			return new MTextNodeList(editorNodesToInlineTexts(node, schema), "numeric")

		case schema.nodes.page_break:
			return new MTextNodePageBreak()

		case schema.nodes.paragraph:
			return new MTextNodeParagraph(editorNodesToInlineText(node, schema))

		case schema.nodes.pricing_table:
			return new MTextNodePricingTable()
		case schema.nodes.signature:
			return new MTextNodeSignature()

		default:
			error(`type: ${node.type.name}`)
	}
}
