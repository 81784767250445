import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Link } from "@mui/material"
import { AppBar, Box, Toolbar, MenuItem, Menu, Button, Divider, List, Drawer } from "@mui/material"
import { useTranslation } from "react-i18next"
import { me_me_User, me_me_AuthenticationRequiredError } from "./__generated__/me"
import "./headerComp.scss"
import GreetingHeader from "./greetingHeader"

const drawerWidth = 240

const AppAppBar = ({ data }: { data?: me_me_User | me_me_AuthenticationRequiredError }) => {
	const { t, i18n } = useTranslation()
	const [anchorDJ, setAnchorDJ] = React.useState(null)
	const [anchorActivity, setAnchorActivity] = React.useState(null)
	const [anchorEvents, setAnchorEvents] = React.useState(null) //process
	const [anchorProcess, setAnchorProcess] = React.useState(null) //Process
	const handleMenuDj = (event: any) => {
		setAnchorDJ(event.currentTarget)
	}

	const handleCloseDj = () => {
		setAnchorDJ(null)
	}

	const handleMenuActivity = (event: any) => {
		setAnchorActivity(event.currentTarget)
	}

	const handleCloseActivity = () => {
		setAnchorActivity(null)
	}

	const handleMenuEvents = (event: any) => {
		setAnchorEvents(event.currentTarget)
	}

	const handleCloseEvents = () => {
		setAnchorEvents(null)
	}
	const handleMenuProcess = (event: any) => {
		setAnchorProcess(event.currentTarget)
	}

	const handleCloseProcess = () => {
		setAnchorProcess(null)
	}

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
					},
				}}
				variant="permanent"
				anchor="left"
			>
				<Toolbar >
				<GreetingHeader data={data} />
				</Toolbar>
				<Divider />
				<List>
					<MenuItem component={RouterLink} to={"/dashboard"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("dashboard")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/users"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("users")}
						</Link>
					</MenuItem>

					<MenuItem component={RouterLink} to={"/dj-categories"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("djCategories")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/djs"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("djs")}
						</Link>
					</MenuItem>

					<MenuItem component={RouterLink} to={"/magazines"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("magazine")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/wedding-fairs"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("weddingFairs")}
						</Link>
					</MenuItem>

					<MenuItem component={RouterLink} to={"/event-consultations"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("eventConsultations")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/event-occasion"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("eventOccasion")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/music-preferences"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("musicPreferences")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/event-season"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("eventSeason")}
						</Link>
					</MenuItem>

					<MenuItem component={RouterLink} to={"/process-tags"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("processTags")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/equipments"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("equipments")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/process-templates"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("processEmailTemplates")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/process-participation-tags"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("processParticipationTags")}
						</Link>
					</MenuItem>

					<MenuItem component={RouterLink} to={"/document-template-blocks"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("documentTemplateBlocks")}
						</Link>
					</MenuItem>
					<MenuItem component={RouterLink} to={"/salutations"}>
						<Link variant="subtitle2" color="inherit" component="p" underline="none" sx={{ flexGrow: 1 }}>
							{t("salutations")}
						</Link>
					</MenuItem>
				</List>
			</Drawer>
		</Box>
	)
}
export default AppAppBar
