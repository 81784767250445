import { gql } from "@apollo/client"

export const processEmailTemplatesGQL = gql`
	query processEmailTemplates ($includeArchived: Boolean) {
		processEmailTemplates(includeArchived: $includeArchived) {
			id
			message
			subject
			archivalTimestamp
		}
	}
`
export const createProcessEmailTemplateGQL = gql`
	mutation createProcessEmailTemplate($message: EmailMessage!, $subject: EmailSubject!) {
		createProcessEmailTemplate(input: { message: $message, subject: $subject }) {
			__typename
		}
	}
`
export const ProcessEmailTemplateGQL = gql`
	query ProcessEmailTemplate($id: ID!) {
		processEmailTemplate(id: $id) {
			id
			message
			subject
		}
	}
`
export const updateProcessEmailTemplateGQL = gql`
	mutation updateProcessEmailTemplate($id: ID!, $message: EmailMessage!, $subject: EmailSubject!) {
		updateProcessEmailTemplate(input: { id: $id, message: $message, subject: $subject }) {
			__typename
		}
	}
`
export const archiveProcessEmailTemplateGQL = gql`
	mutation archiveProcessEmailTemplate($id: ID!) {
		archiveProcessEmailTemplate(id: $id) {
			__typename
		}
	}
`

export const unarchiveProcessEmailTemplateGQL = gql`
	mutation unarchiveProcessEmailTemplate($id: ID!) {
		unarchiveProcessEmailTemplate(id: $id) {
			__typename
		}
	}
`
