import { gql } from "@apollo/client"
export const djsGQL = gql`
	query djs($includeArchived: Boolean) {
		djs(includeArchived: $includeArchived) {
			alias
			businessPhoneNumber
			category {
				compensationPerHourOfCommute
				compensationPerOvertimeUnit
				compensationInHighSeason
				compensationInLowSeason
				id
				label
				priceExcludingVatInHighSeason
				priceExcludingVatInLowSeason
				pricePerOvertimeUnit
			}
			emailAddress
			firstName
			homePhoneNumber
			id
			lastName
			mobilePhoneNumber
			remarks
			archivalTimestamp
		}
	}
`
export const createDJGQL = gql`
	mutation createDj(
		$categoryId: ID!
		$firstName: FirstName!
		$lastName: LastName!
		$emailAddress: EmailAddress!
		$homePhoneNumber: PhoneNumber = null
		$mobilePhoneNumber: PhoneNumber = null
		$businessPhoneNumber: PhoneNumber = null
		$alias: DjAlias
		$remarks: Remarks = null
	) {
		createDj(
			input: {
				categoryId: $categoryId
				firstName: $firstName
				lastName: $lastName
				emailAddress: $emailAddress
				homePhoneNumber: $homePhoneNumber
				mobilePhoneNumber: $mobilePhoneNumber
				businessPhoneNumber: $businessPhoneNumber
				alias: $alias
				remarks: $remarks
			}
		) {
			__typename
		}
	}
`
export const djGQL = gql`
	query dj($id: ID!) {
		dj(id: $id) {
			alias
			businessPhoneNumber
			category {
				compensationPerHourOfCommute
				compensationPerOvertimeUnit
				compensationInHighSeason
				compensationInLowSeason
				id
				label
				priceExcludingVatInHighSeason
				priceExcludingVatInLowSeason
				pricePerOvertimeUnit
			}
			emailAddress
			firstName
			homePhoneNumber
			id
			lastName
			mobilePhoneNumber
			remarks
		}
	}
`
export const updateDjGQL = gql`
	mutation updateDj(
		$id: ID!
		$categoryId: ID!
		$firstName: FirstName!
		$lastName: LastName!
		$emailAddress: EmailAddress!
		$homePhoneNumber: PhoneNumber = null
		$mobilePhoneNumber: PhoneNumber = null
		$businessPhoneNumber: PhoneNumber = null
		$alias: DjAlias
		$remarks: Remarks = null
	) {
		updateDj(
			input: {
				id: $id
				categoryId: $categoryId
				firstName: $firstName
				lastName: $lastName
				emailAddress: $emailAddress
				homePhoneNumber: $homePhoneNumber
				mobilePhoneNumber: $mobilePhoneNumber
				businessPhoneNumber: $businessPhoneNumber
				alias: $alias
				remarks: $remarks
			}
		) {
			__typename
		}
	}
`
export const archiveDjGQL = gql`
	mutation archiveDj($id: ID!) {
		archiveDj(id: $id) {
			__typename
		}
	}
`
export const unarchiveDjGQL = gql`
	mutation unarchiveDj($id: ID!) {
		unarchiveDj(id: $id) {
			__typename
		}
	}
`
