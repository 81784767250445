import { gql } from "@apollo/client"

export const getUsersGQL = gql`
	query users($includeArchived: Boolean) {
		users(includeArchived: $includeArchived) {
			__typename
			id
			archivalTimestamp
			emailAddress
			firstName
			lastName
			phoneNumbers
			sipgateDeviceId
			archivalTimestamp
		}
	}
`
export const getUserGQL = gql`
	query user($id: ID!) {
		user(id: $id) {
			__typename
			id
			archivalTimestamp
			emailAddress
			firstName
			lastName
			phoneNumbers
			sipgateDeviceId
		}
	}
`
export const createUserGQL = gql`
	mutation createUser($emailAddress: EmailAddress!, $password: Password!, $firstName: FirstName!, $lastName: LastName!, $phoneNumbers: [PhoneNumber!]!) {
		createUser(input: { emailAddress: $emailAddress, password: $password, firstName: $firstName, lastName: $lastName, phoneNumbers: $phoneNumbers }) {
			__typename
		}
	}
`
export const updateUserGQL = gql`
	mutation updateUser($id: ID!, $emailAddress: EmailAddress!, $firstName: FirstName!, $lastName: LastName!, $phoneNumbers: [PhoneNumber!]!) {
		updateUser(input: { id: $id, emailAddress: $emailAddress, firstName: $firstName, lastName: $lastName, phoneNumbers: $phoneNumbers }) {
			__typename
		}
	}
`
export const archiveUserGQL = gql`
	mutation archiveUser($id: ID!) {
		archiveUser(id: $id) {
			__typename
		}
	}
`
export const unarchiveUserGQL = gql`
	mutation unarchiveUser($id: ID!) {
		unarchiveUser(id: $id) {
			__typename
		}
	}
`
