import { gql } from "@apollo/client"
export const processTagsGQL = gql`
	query processTags($includeArchived: Boolean) {
		processTags(includeArchived: $includeArchived) {
			id
			label
			archivalTimestamp
		}
	}
`
export const createProcessTagGQL = gql`
	mutation createProcessTag($label: TagLabel!) {
		createProcessTag(input: { label: $label }) {
			__typename
		}
	}
`
export const ProcessTagGQL = gql`
	query ProcessTag($id: ID!) {
		processTag(id: $id) {
			id
			label
		}
	}
`
export const updateProcessTagGQL = gql`
	mutation updateProcessTag($id: ID!, $label: TagLabel!) {
		updateProcessTag(input: { id: $id, label: $label }) {
			__typename
		}
	}
`
export const archiveProcessTagGQL = gql`
	mutation archiveProcessTag($id: ID!) {
		archiveProcessTag(id: $id) {
			__typename
		}
	}
`
//ProcessTags
export const unarchiveProcessTagGQL = gql`
	mutation unarchiveProcessTag($id: ID!) {
		unarchiveProcessTag(id: $id) {
			__typename
		}
	}
`
