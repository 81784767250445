import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import MagazinesList from "./MagazinesList"
import { magazinesGQL, archiveMagazineGQL, unarchiveMagazineGQL } from "./magazinesGQL"
import { magazines as magazinesInterface, magazines_magazines } from "./__generated__/magazines"
import { archiveMagazine, archiveMagazineVariables } from "./__generated__/archiveMagazine"
import "./magazines.scss"
import { unarchiveMagazine } from "./__generated__/unarchiveMagazine"
import ListHeader from "../shared/TableHeader"

const Magazines = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("magazines")
	const [getMagazines, { loading: magazinesLoading, error: magazinesError, data: magazines }] = useLazyQuery<magazinesInterface>(magazinesGQL, {
		fetchPolicy: "network-only", // Doesn't check cache before making a network request
		variables: { includeArchived: true },
	})
	const [archiveMagazineClick, { data, loading, error }] = useMutation<archiveMagazine, archiveMagazineVariables>(archiveMagazineGQL)

	const [unarchiveMagazineClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveMagazine,
		archiveMagazineVariables
	>(unarchiveMagazineGQL)

	React.useEffect(() => {
		getMagazines()
	}, [])
	const archive = async (id: string) => {
		const archiveMagazineData = await archiveMagazineClick({
			variables: { id: id },
		})
		if (archiveMagazineData?.data?.archiveMagazine?.__typename) {
			getMagazines()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveMagazineData = unarchiveMagazineClick({
			variables: { id: id },
		})
		if ((await unarchiveMagazineData)?.data?.unarchiveMagazine?.__typename ? true : false) {
			getMagazines()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<MagazinesList magazines={magazines?.magazines} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}

export default Magazines
