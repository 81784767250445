import * as React from "react"
import { useMutation } from "@apollo/client"
import { TextField, Button, Alert } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { signInGQL } from "./loginGQL"
import { Authentication, AuthenticationVariables } from "./__generated__/Authentication"
import { validateEmail } from "../shared/regex"
import PasswordTextField from "../shared/PasswordComponent"
import { useFormik } from "formik"
import * as yup from "yup"
import "./Login.scss"

const validationSchema = yup.object({
	emailAddress: yup.string().email("Enter a valid email").required("Email is required"),
	password: yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required"),
})

const Login = () => {
	const [authenticate, { data, loading, error }] = useMutation<Authentication>(signInGQL)

	const formik = useFormik({
		initialValues: {
			emailAddress: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: AuthenticationVariables) => {
			const authData = await authenticate({ variables: values })
			if (authData?.data?.authenticate.__typename === "AuthenticateOutputAuthenticated") {
				navigate("dashboard")
			}
		},
	})

	let navigate = useNavigate()

	const errorMessage = (data?.authenticate?.__typename !== "AuthenticateOutputAuthenticated" && data !== null && data !== undefined) || error?.message

	const { t, i18n } = useTranslation()

	React.useEffect(() => {
		const token: string | null = localStorage.getItem("accessToken")
		if (token) {
			if (token.length > 0) {
				navigate("dashboard")
			}
		}
	}, [])

	return (
		<div className="login-position">
			<div className="login-wrapper">
				<h1>{t("mmpt")}</h1>
				<h3>{t("registration")}</h3>
				<form onSubmit={formik.handleSubmit}>
					<div className={"textfield-pad"}>
						<TextField
							fullWidth
							id="emailAddress"
							name="emailAddress"
							label={t("emailAdress")}
							value={formik.values.emailAddress}
							onChange={formik.handleChange}
							error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
							helperText={formik.touched.emailAddress && formik.errors.emailAddress}
						/>
					</div>
					<div className={"textfield-pad"}>
						<PasswordTextField
							fullWidth
							id="password"
							name="password"
							label={t("password")}
							type="password"
							value={formik.values.password}
							onChange={formik.handleChange}
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password}
						/>
					</div>
					<div className={"textfield-pad"}>
						<Button className="loginButton" variant="outlined" type="submit">
							{t("register")}
						</Button>
					</div>
				</form>
				<div className={"textfield-pad"}>{errorMessage ? <Alert severity="error">{errorMessage}</Alert> : <div></div>}</div>
			</div>
		</div>
	)
}

export default Login
