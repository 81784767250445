import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateDjCategoryGQL, djCategoryGQL } from "../djCategoryGQL"
import { noLowerThan0 } from "../../shared/noLessThan0"
import { djCategoryVariables, djCategory } from "../__generated__/djCategory"
import { updateDjCategoryVariables, updateDjCategory } from "../__generated__/updateDjCategory"
import { useFormik } from "formik"
import * as yup from "yup"

const EditDjCategory = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
			priceExcludingVatInHighSeason: yup.number().required(`${t("priceExcludingVatInHighSeason")} is required`),
		priceExcludingVatInLowSeason: yup.number().required(`${t("priceExcludingVatInLowSeason")} is required`),
		pricePerOvertimeUnit: yup.number().required(`${t("pricePerOvertimeUnit")} is required`),
		compensationPerHourOfCommute: yup.number().required(`${t("compensationPerHourOfCommute")} is required`),
		compensationPerOvertimeUnit: yup.number().required(`${t("compensationPerOvertimeUnit")} is required`),
		compensationInHighSeason: yup.number().required(`${t("compensationInHighSeason")} is required`),
		compensationInLowSeason: yup.number().required(`${t("compensationInLowSeason")} is required`),
	})
	const { id } = useParams()
	const { loading: loadingGetDj, data } = useQuery<djCategory, djCategoryVariables>(djCategoryGQL, {
		variables: { id: `${id}` },
	})
	const [updateDJCategory, {}] = useMutation<updateDjCategory, updateDjCategoryVariables>(updateDjCategoryGQL)
	let navigate = useNavigate()
	const formik = useFormik({
		initialValues: {
			label: "",
			priceExcludingVatInHighSeason: "",
			priceExcludingVatInLowSeason: "",
			pricePerOvertimeUnit: "",
			compensationPerHourOfCommute: "",
			compensationPerOvertimeUnit: "",
			compensationInHighSeason: "",
			compensationInLowSeason: "",
		},
		validationSchema: validationSchema,
		onSubmit: async values => {
			const updateDJCategoryData = await updateDJCategory({ variables: {
				id: id!, // TODO Improve.
				label: values.label,
				priceExcludingVatInHighSeason: Math.round(parseFloat(values.priceExcludingVatInHighSeason) * 100),
				priceExcludingVatInLowSeason: Math.round(parseFloat(values.priceExcludingVatInLowSeason) * 100),
				pricePerOvertimeUnit: Math.round(parseFloat(values.pricePerOvertimeUnit) * 100),
				compensationInHighSeason: Math.round(parseFloat(values.compensationInHighSeason) * 100),
				compensationInLowSeason: Math.round(parseFloat(values.compensationInLowSeason) * 100),
				compensationPerHourOfCommute: Math.round(parseFloat(values.compensationPerHourOfCommute) * 100),
				compensationPerOvertimeUnit: Math.round(parseFloat(values.compensationPerOvertimeUnit) * 100),
			}})
			if (updateDJCategoryData?.data?.updateDjCategory?.__typename) {
				navigate("/dj-categories")
			}
		},
	})

	React.useEffect(() => {
		if (!loadingGetDj) {
			const category = data?.djCategory
			if (category) {
				formik.setFieldValue("label", category.label)
				formik.setFieldValue("priceExcludingVatInHighSeason", (category.priceExcludingVatInHighSeason / 100).toFixed(2))
				formik.setFieldValue("priceExcludingVatInLowSeason", (category.priceExcludingVatInLowSeason / 100).toFixed(2))
				formik.setFieldValue("pricePerOvertimeUnit", (category.pricePerOvertimeUnit / 100).toFixed(2))
				formik.setFieldValue("compensationPerHourOfCommute", (category.compensationPerHourOfCommute / 100).toFixed(2))
				formik.setFieldValue("compensationPerOvertimeUnit", (category.compensationPerOvertimeUnit / 100).toFixed(2))
				formik.setFieldValue("compensationInHighSeason", (category.compensationInHighSeason / 100).toFixed(2))
				formik.setFieldValue("compensationInLowSeason", (category.compensationInLowSeason / 100).toFixed(2))
			}
		}
	}, [loadingGetDj])
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editDjCategory")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						autoComplete="off"
						onSubmit={formik.handleSubmit}
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="priceExcludingVatInHighSeason"
									name="priceExcludingVatInHighSeason"
									label={t("priceExcludingVatInHighSeason")}
									value={formik.values.priceExcludingVatInHighSeason}
									onChange={formik.handleChange}
									error={formik.touched.priceExcludingVatInHighSeason && Boolean(formik.errors.priceExcludingVatInHighSeason)}
									helperText={formik.touched.priceExcludingVatInHighSeason && formik.errors.priceExcludingVatInHighSeason}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="priceExcludingVatInLowSeason"
									name="priceExcludingVatInLowSeason"
									label={t("priceExcludingVatInLowSeason")}
									value={formik.values.priceExcludingVatInLowSeason}
									onChange={formik.handleChange}
									error={formik.touched.priceExcludingVatInLowSeason && Boolean(formik.errors.priceExcludingVatInLowSeason)}
									helperText={formik.touched.priceExcludingVatInLowSeason && formik.errors.priceExcludingVatInLowSeason}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="pricePerOvertimeUnit"
									name="pricePerOvertimeUnit"
									label={t("pricePerOvertimeUnit")}
									value={formik.values.pricePerOvertimeUnit}
									onChange={formik.handleChange}
									error={formik.touched.pricePerOvertimeUnit && Boolean(formik.errors.pricePerOvertimeUnit)}
									helperText={formik.touched.pricePerOvertimeUnit && formik.errors.pricePerOvertimeUnit}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="compensationPerHourOfCommute"
									name="compensationPerHourOfCommute"
									label={t("compensationPerHourOfCommute")}
									value={formik.values.compensationPerHourOfCommute}
									onChange={formik.handleChange}
									error={formik.touched.compensationPerHourOfCommute && Boolean(formik.errors.compensationPerHourOfCommute)}
									helperText={formik.touched.compensationPerHourOfCommute && formik.errors.compensationPerHourOfCommute}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="compensationPerOvertimeUnit"
									name="compensationPerOvertimeUnit"
									label={t("compensationPerOvertimeUnit")}
									value={formik.values.compensationPerOvertimeUnit}
									onChange={formik.handleChange}
									error={formik.touched.compensationPerOvertimeUnit && Boolean(formik.errors.compensationPerOvertimeUnit)}
									helperText={formik.touched.compensationPerOvertimeUnit && formik.errors.compensationPerOvertimeUnit}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="compensationInHighSeason"
									name="compensationInHighSeason"
									label={t("compensationInHighSeason")}
									value={formik.values.compensationInHighSeason}
									onChange={formik.handleChange}
									error={formik.touched.compensationInHighSeason && Boolean(formik.errors.compensationInHighSeason)}
									helperText={formik.touched.compensationInHighSeason && formik.errors.compensationInHighSeason}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="compensationInLowSeason"
									name="compensationInLowSeason"
									label={t("compensationInLowSeason")}
									value={formik.values.compensationInLowSeason}
									onChange={formik.handleChange}
									error={formik.touched.compensationInLowSeason && Boolean(formik.errors.compensationInLowSeason)}
									helperText={formik.touched.compensationInLowSeason && formik.errors.compensationInLowSeason}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditDjCategory
