import { Navigate } from "react-router-dom"
import Header from "../Header/Header"
import Logout from "./Logout"

export default function AuthGuard({ children, notMain }: { children: JSX.Element; notMain?: boolean }) {
	let cookieValue = document?.cookie
		?.split("; ")
		?.find(row => row.startsWith("credentialsExpirationTimestamp="))
		?.split("=")[1]

	cookieValue = decodeURIComponent(cookieValue ? cookieValue : "")
	if (!cookieValue) {
		return <Navigate to="/" />
	} else {
		const expiryDateLogOut = new Date(new Date(cookieValue).getTime() - 300000)
		const dateNow = new Date()
		if (+expiryDateLogOut <= +dateNow) {
			return <Logout/>
		}

		if (notMain) {
			return (
				<>
					<div style={{ display: "flex", alignItems: "center", width: "100%", flexDirection: "column" }}>{children}</div>
				</>
			)
		}
		return (
			<div style={{ display: "flex", flexDirection: "row" }}>
				<Header />
				<div style={{ display: "flex", alignItems: "center", width: "100%", flexDirection: "column" }}>{children}</div>
			</div>
		)
	}
}
