import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import Button from "@mui/material/Button"
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';

const SignatureButton = () => {
	// Using command chaining
	const active = useActive()
	const { insertSignature } = useCommands()

	return (
		<Button
			onClick={() => {
				insertSignature()
			}}
			variant="outlined"
		>
			<FingerprintOutlinedIcon />
		</Button>
	)
}
export default SignatureButton
