import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "../shared/ConfirmDialog"
import { processParticipationTags_processParticipationTags } from "./__generated__/processParticipationTags"

const ProcessParticipationTagList = ({
	processParticipationTags,
	archive,
	unarchive,
	checked,
}: {
	processParticipationTags?: processParticipationTags_processParticipationTags[]
	archive: Function
	unarchive: Function
	checked: boolean
}) => {
	const { t, i18n } = useTranslation()
	const [open, openDialog] = React.useState(false)
	const [currentProcessParticipationTag, setCurrentProcessParticipationTag] = React.useState<processParticipationTags_processParticipationTags | null>(null)
	const openConfirmDialog = (row: processParticipationTags_processParticipationTags) => {
		setCurrentProcessParticipationTag(row)
		openDialog(true)
	}
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("label")}</TableCell>
							<TableCell align="right">{t("description")}</TableCell>
							<TableCell align="right">{t("priority")}</TableCell>
							<TableCell align="right">{t("limitPerProcess")}</TableCell>
							<TableCell align="right">{t("isPrimary")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{processParticipationTags?.map((row: processParticipationTags_processParticipationTags) =>
							row?.archivalTimestamp ? (
								checked ? (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{row?.label}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.description}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.priority}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.limitPerProcess}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{String(row?.isPrimary)}
										</TableCell>

										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											<div className="table-action-body">
												<RestoreFromTrashIcon className="delete-style" onClick={() => unarchive(row.id)} />
											</div>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)
							) : (
								<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row?.label}
									</TableCell>
									<TableCell align="right">{row?.description}</TableCell>
									<TableCell align="right">{row?.priority}</TableCell>
									<TableCell align="right">{row?.limitPerProcess}</TableCell>
									<TableCell align="right">{String(row?.isPrimary)}</TableCell>

									<TableCell align="right">
										<div className="table-action-body">
											<Link component={RouterLink} to={`${row.id}`}>
												<EditOutlinedIcon />
											</Link>
											<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={open}
				openDialog={openDialog}
				content={`Do you want to archive ${currentProcessParticipationTag?.label ? currentProcessParticipationTag.label : ""} ?`}
				Complete={() => {
					archive(currentProcessParticipationTag?.id ? currentProcessParticipationTag.id : "0")
				}}
			/>
		</Paper>
	)
}
export default ProcessParticipationTagList
