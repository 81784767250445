import { gql } from "@apollo/client"

export const getUserInfoGQL = gql`
	query me{
		me {
			__typename
		
			... on User {
				emailAddress
				firstName
				id
				lastName
			}
		}
	}
`
//
