import { gql } from "@apollo/client"

export const eventConsultationsGQL = gql`
	query eventConsultations($includeArchived: Boolean) {
		eventConsultations(includeArchived: $includeArchived)  {
			id
			label
			archivalTimestamp
		}
	}
`
export const createEventConsultationGQL = gql`
	mutation createEventConsultation($label: EventConsultationLabel!) {
		createEventConsultation(input: { label: $label }) {
			__typename
		}
	}
`
export const EventConsultationGQL = gql`
	query EventConsultation($id: ID!) {
		eventConsultation(id: $id) {
			id
			label
		}
	}
`
export const updateEventConsultationGQL = gql`
	mutation updateEventConsultation($id: ID!, $label: EventConsultationLabel!) {
		updateEventConsultation(input: { id: $id, label: $label }) {
			__typename
		}
	}
`
export const archiveEventConsultationGQL = gql`
	mutation archiveEventConsultation($id: ID!) {
		archiveEventConsultation(id: $id) {
			__typename
		}
	}
`
export const unarchiveEventConsultationGQL = gql`
	mutation unarchiveEventConsultation($id: ID!) {
		unarchiveEventConsultation(id: $id) {
			__typename
		}
	}
`