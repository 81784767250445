import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem, getTextFieldUtilityClass } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { createDJGQL } from "../djGQL"
import { djCategoriesGQL } from "../../DjCategories/djCategoryGQL"
import { noLowerThan0 } from "../../shared/noLessThan0"
import parsePhoneNumberFromString from "libphonenumber-js"
import { createDj, createDjVariables } from "../__generated__/createDj"
import { djCategories as djCategoriesInterface, djCategories_djCategories } from "../../DjCategories/__generated__/djCategories"
import { onlyPhoneNumber } from "../../shared/onlyPhoneNumber"
import { emptyStringIsNull } from "../../shared/emptyStringIsNull"
import { useFormik } from "formik"
import * as yup from "yup"

const parseOrNull = (string: string) => {
	const value = parsePhoneNumberFromString(string, "DE")
	if (!value || !value.isPossible()) {
		return null
	}

	return String(value?.number)
}

const CreateDj = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		alias: yup.string().max(100, `${t("alias")} can not be longer than 100 characters`),
		firstName: yup.string().required(`${t("firstName")} is required`),
		lastName: yup.string().required(`${t("lastName")} is required`),
		emailAddress: yup
			.string()
			.email("Enter a valid email")
			.required(`${t("emailAdress")} is required`),
		businessPhoneNumber: yup.string(),
		mobilePhoneNumber: yup.string(),
		homePhoneNumber: yup.string(),
		remarks: yup.string(),
		categoryId: yup.string().required(`Category is required`),
	})
	const formik = useFormik({
		initialValues: {
			alias: "",
			firstName: "",
			lastName: "",
			emailAddress: "",
			businessPhoneNumber: "",
			mobilePhoneNumber: "",
			homePhoneNumber: "",
			remarks: "",
			categoryId: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: createDjVariables) => {
			const createDjData = await createDJ({
				variables: {
					alias: emptyStringIsNull(values.alias),
					firstName: values.firstName,
					lastName: values.lastName,
					emailAddress: values.emailAddress,
					businessPhoneNumber: emptyStringIsNull(parseOrNull(values?.businessPhoneNumber ? values.businessPhoneNumber : "")),
					mobilePhoneNumber: emptyStringIsNull(parseOrNull(values?.mobilePhoneNumber ? values.mobilePhoneNumber : "")),
					homePhoneNumber: emptyStringIsNull(parseOrNull(values?.homePhoneNumber ? values?.homePhoneNumber : "")),
					remarks: emptyStringIsNull(values.remarks),
					categoryId: values?.categoryId ? values.categoryId : "0",
				},
			})
			if (createDjData?.data?.createDj?.__typename) {
				navigate("/djs")
			}
		},
	})
	const [categories, setCategories] = React.useState<djCategories_djCategories[]>([])
	const [createDJ, { data, loading, error }] = useMutation<createDj, createDjVariables>(createDJGQL)
	const { loading: djCategoriesLoading, error: djCategoriesError, data: djCategories } = useQuery(djCategoriesGQL)
	React.useEffect(() => {
		if (!djCategoriesLoading) {
			setCategories(djCategories?.djCategories)
			formik.setFieldValue("categoryId", djCategories?.djCategories[0]?.id ? djCategories.djCategories[0].id : "")
		}
	}, [djCategoriesLoading])
	let navigate = useNavigate()
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createDj")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="alias"
									name="alias"
									label={t("alias")}
									value={formik.values.alias}
									onChange={formik.handleChange}
									error={formik.touched.alias && Boolean(formik.errors.alias)}
									helperText={formik.touched.alias && formik.errors.alias}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="firstName"
									name="firstName"
									label={t("firstName")}
									value={formik.values.firstName}
									onChange={formik.handleChange}
									error={formik.touched.firstName && Boolean(formik.errors.firstName)}
									helperText={formik.touched.firstName && formik.errors.firstName}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="lastName"
									name="lastName"
									label={t("lastName")}
									value={formik.values.lastName}
									onChange={formik.handleChange}
									error={formik.touched.lastName && Boolean(formik.errors.lastName)}
									helperText={formik.touched.lastName && formik.errors.lastName}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="emailAddress"
									name="emailAddress"
									label={t("emailAdress")}
									value={formik.values.emailAddress}
									onChange={formik.handleChange}
									error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
									helperText={formik.touched.emailAddress && formik.errors.emailAddress}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="businessPhoneNumber"
									name="businessPhoneNumber"
									label={t("businessPhoneNumber")}
									value={formik.values.businessPhoneNumber}
									onChange={formik.handleChange}
									error={formik.touched.businessPhoneNumber && Boolean(formik.errors.businessPhoneNumber)}
									helperText={formik.touched.businessPhoneNumber && formik.errors.businessPhoneNumber}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="mobilePhoneNumber"
									name="mobilePhoneNumber"
									label={t("mobilePhoneNumber")}
									value={formik.values.mobilePhoneNumber}
									onChange={formik.handleChange}
									error={formik.touched.mobilePhoneNumber && Boolean(formik.errors.mobilePhoneNumber)}
									helperText={formik.touched.mobilePhoneNumber && formik.errors.mobilePhoneNumber}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="homePhoneNumber"
									name="homePhoneNumber"
									label={t("homePhoneNumber")}
									value={formik.values.homePhoneNumber}
									onChange={formik.handleChange}
									error={formik.touched.homePhoneNumber && Boolean(formik.errors.homePhoneNumber)}
									helperText={formik.touched.homePhoneNumber && formik.errors.homePhoneNumber}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="remarks"
									name="remarks"
									label={t("remarks")}
									value={formik.values.remarks}
									onChange={formik.handleChange}
									error={formik.touched.remarks && Boolean(formik.errors.remarks)}
									helperText={formik.touched.remarks && formik.errors.remarks}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									select
									id="categoryId"
									name="categoryId"
									label={t("djCategory")}
									value={formik.values.categoryId}
									onChange={formik.handleChange}
									error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
									helperText={formik.touched.categoryId && formik.errors.categoryId}
									variant="outlined"
								>
									{categories?.map((category: djCategories_djCategories, index: number) => {
										return (
											<MenuItem key={category.id} value={category.id}>
												{category.label}
											</MenuItem>
										)
									})}
								</TextField>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default CreateDj
