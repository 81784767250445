import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button } from "@mui/material"
import { useMutation } from "@apollo/client"
import { createSalutationGQL } from "../salutationsGQL"
import { createSalutation as createSalutationInterface, createSalutationVariables } from "../__generated__/createSalutation"
import { useFormik } from "formik"
import * as yup from "yup"

const CreateSalutation = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: createSalutationVariables) => {
			const createSalutationData = await createSalutation({ variables: values })
			if (createSalutationData?.data?.createSalutation?.__typename) {
				navigate("/salutations")
			}
		},
	})
	const [createSalutation, { data, loading, error }] = useMutation<createSalutationInterface, createSalutationVariables>(
		createSalutationGQL
	)
	let navigate = useNavigate()
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createSalutations")}</Typography>
			</div>
			<div className="container-centering">
			<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default CreateSalutation
