import * as React from "react"
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import AuthGuard from "./shared/Auth"
import Login from "./Login/Login"
import Dashboard from "./Dashboard/Dashboard"
import Users from "./Users/Users"
import CreateUser from "./Users/create/CreateUser"
import EditUser from "./Users/edit/EditUser"
import DjCategories from "./DjCategories/DjCategories"
import CreateDjCategory from "./DjCategories/create/CreateDjCategory"
import EditDjCategory from "./DjCategories/edit/EditDjCategory"
import DJs from "./DJs/DJs"
import CreateDJ from "./DJs/create/CreateDJ"
import EditDJ from "./DJs/edit/EditDj"
import Magazines from "./Magazines/Magazines"
import CreateMagazines from "./Magazines/create/CreateMagazines"
import EditMagazines from "./Magazines/edit/EditMagazines"
import WeddingFairs from "./WeddingFairs/WeddingFairs"
import CreateWeddingFairs from "./WeddingFairs/create/CreateWeddingFairs"
import EditWeddingFairs from "./WeddingFairs/edit/EditWeddingFairs"
import MusicPreferences from "./MusicPreferences/MusicPreferences"
import CreateMusicPreferences from "./MusicPreferences/create/CreateMusicPreferences"
import EditMusicPreferences from "./MusicPreferences/edit/EditMusicPreferences"
import EventConsultations from "./EventConsultations/EventConsultations"
import CreateEventConsultations from "./EventConsultations/create/CreateEventConsultations"
import EditEventConsultations from "./EventConsultations/edit/EditEventConsultations"
import EventOccasions from "./EventOccasion/EventOccasion"
import CreateEventOccasion from "./EventOccasion/create/CreateEventOccasion"
import EditEventOccasion from "./EventOccasion/edit/EditEventOccasion"
import ProcessTags from "./ProcessTags/ProcessTags"
import CreateProcessTags from "./ProcessTags/create/CreateProcessTags"
import EditProcessTags from "./ProcessTags/edit/EditProcessTags"
import Equipments from "./Equipments/Equipments"
import CreateEquipment from "./Equipments/create/CreateEquipments"
import EditEquipment from "./Equipments/edit/EditEquipments"
import ProcessEmailTemplates from "./ProcessEmailTemplate/ProcessEmailTemplates"
import CreateProcessEmailTemplate from "./ProcessEmailTemplate/create/CreateProcessEmailTemplates"
import EditProcessEmailTemplate from "./ProcessEmailTemplate/edit/EditProcessEmailTemplates"
import ProcessParticipationTags from "./ProcessParticipationTag/ProcessParticipationTags"
import CreateProcessParticipationTag from "./ProcessParticipationTag/create/CreateProcessParticipationTags"
import EditProcessParticipationTag from "./ProcessParticipationTag/edit/EditProcessParticipationTags"
import EventSeason from "./EventSeason/EventSeason"
import DocumentTemplateBlocks from "./DocumentTemplateBlock/DocumentTemplateBlock"
import CreateDocumentTemplateBlock from "./DocumentTemplateBlock/create/CreateDocumentTemplateBlocks"
import EditDocumentTemplateBlock from "./DocumentTemplateBlock/edit/EditDocumentTemplateBlocks"
import Salutations from "./Salutations/Salutations"
import CreateSalutation from "./Salutations/create/CreateSalutations"
import EditSalutation from "./Salutations/edit/EditSalutations"
import ProcessDocument from "./ProcessDocument/ProcessDocument"
import ProcessNewDocument from "./ProcessNewDocument/ProcessNewDocument"
const Routing = () => {
	return (
		<React.Fragment>
			<Router>
				<Routes>
					<Route path={"/"} element={<Login />} />
					<Route
						path={"/process-document/:id"}
						element={
							<AuthGuard notMain={true}>
								<ProcessDocument />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-new-document/:id"}
						element={
							<AuthGuard notMain={true}>
								<ProcessNewDocument />
							</AuthGuard>
						}
					/>
					<Route
						path={"/dashboard"}
						element={
							<AuthGuard>
								<Dashboard />
							</AuthGuard>
						}
					/>
					<Route
						path={"/users"}
						element={
							<AuthGuard>
								<Users />
							</AuthGuard>
						}
					/>
					<Route
						path={"/users/create"}
						element={
							<AuthGuard>
								<CreateUser />
							</AuthGuard>
						}
					/>
					<Route
						path={"/users/:id"}
						element={
							<AuthGuard>
								<EditUser />
							</AuthGuard>
						}
					/>
					<Route
						path={"/dj-categories"}
						element={
							<AuthGuard>
								<DjCategories />
							</AuthGuard>
						}
					/>
					<Route
						path={"/dj-categories/create"}
						element={
							<AuthGuard>
								<CreateDjCategory />
							</AuthGuard>
						}
					/>
					<Route
						path={"/dj-categories/:id"}
						element={
							<AuthGuard>
								<EditDjCategory />
							</AuthGuard>
						}
					/>
					<Route
						path={"/djs"}
						element={
							<AuthGuard>
								<DJs />
							</AuthGuard>
						}
					/>
					<Route
						path={"/djs/create"}
						element={
							<AuthGuard>
								<CreateDJ />
							</AuthGuard>
						}
					/>
					<Route
						path={"/djs/:id"}
						element={
							<AuthGuard>
								<EditDJ />
							</AuthGuard>
						}
					/>
					<Route
						path={"/magazines"}
						element={
							<AuthGuard>
								<Magazines />
							</AuthGuard>
						}
					/>
					<Route
						path={"/magazines/create"}
						element={
							<AuthGuard>
								<CreateMagazines />
							</AuthGuard>
						}
					/>
					<Route
						path={"/magazines/:id"}
						element={
							<AuthGuard>
								<EditMagazines />
							</AuthGuard>
						}
					/>
					<Route
						path={"/wedding-fairs"}
						element={
							<AuthGuard>
								<WeddingFairs />
							</AuthGuard>
						}
					/>
					<Route
						path={"/wedding-fairs/create"}
						element={
							<AuthGuard>
								<CreateWeddingFairs />
							</AuthGuard>
						}
					/>
					<Route
						path={"/wedding-fairs/:id"}
						element={
							<AuthGuard>
								<EditWeddingFairs />
							</AuthGuard>
						}
					/>
					<Route
						path={"/music-preferences"}
						element={
							<AuthGuard>
								<MusicPreferences />
							</AuthGuard>
						}
					/>
					<Route
						path={"/music-preferences/create"}
						element={
							<AuthGuard>
								<CreateMusicPreferences />
							</AuthGuard>
						}
					/>
					<Route
						path={"/music-preferences/:id"}
						element={
							<AuthGuard>
								<EditMusicPreferences />
							</AuthGuard>
						}
					/>
					<Route
						path={"/event-consultations"}
						element={
							<AuthGuard>
								<EventConsultations />
							</AuthGuard>
						}
					/>
					<Route
						path={"/event-consultations/create"}
						element={
							<AuthGuard>
								<CreateEventConsultations />
							</AuthGuard>
						}
					/>
					<Route
						path={"/event-consultations/:id"}
						element={
							<AuthGuard>
								<EditEventConsultations />
							</AuthGuard>
						}
					/>
					<Route
						path={"/event-occasion"}
						element={
							<AuthGuard>
								<EventOccasions />
							</AuthGuard>
						}
					/>
					<Route
						path={"/event-occasion/create"}
						element={
							<AuthGuard>
								<CreateEventOccasion />
							</AuthGuard>
						}
					/>
					<Route
						path={"/event-occasion/:id"}
						element={
							<AuthGuard>
								<EditEventOccasion />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-tags"}
						element={
							<AuthGuard>
								<ProcessTags />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-tags/create"}
						element={
							<AuthGuard>
								<CreateProcessTags />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-tags/:id"}
						element={
							<AuthGuard>
								<EditProcessTags />
							</AuthGuard>
						}
					/>
					<Route
						path={"/equipments"}
						element={
							<AuthGuard>
								<Equipments />
							</AuthGuard>
						}
					/>
					<Route
						path={"/equipments/create"}
						element={
							<AuthGuard>
								<CreateEquipment />
							</AuthGuard>
						}
					/>
					<Route
						path={"/equipments/:id"}
						element={
							<AuthGuard>
								<EditEquipment />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-templates"}
						element={
							<AuthGuard>
								<ProcessEmailTemplates />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-templates/create"}
						element={
							<AuthGuard>
								<CreateProcessEmailTemplate />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-templates/:id"}
						element={
							<AuthGuard>
								<EditProcessEmailTemplate />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-participation-tags"}
						element={
							<AuthGuard>
								<ProcessParticipationTags />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-participation-tags/create"}
						element={
							<AuthGuard>
								<CreateProcessParticipationTag />
							</AuthGuard>
						}
					/>
					<Route
						path={"/process-participation-tags/:id"}
						element={
							<AuthGuard>
								<EditProcessParticipationTag />
							</AuthGuard>
						}
					/>
					<Route
						path={"/event-season"}
						element={
							<AuthGuard>
								<EventSeason />
							</AuthGuard>
						}
					/>

					<Route
						path={"/document-template-blocks"}
						element={
							<AuthGuard>
								<DocumentTemplateBlocks />
							</AuthGuard>
						}
					/>
					<Route
						path={"/document-template-blocks/create"}
						element={
							<AuthGuard>
								<CreateDocumentTemplateBlock />
							</AuthGuard>
						}
					/>
					<Route
						path={"/document-template-blocks/:id"}
						element={
							<AuthGuard>
								<EditDocumentTemplateBlock />
							</AuthGuard>
						}
					/>
					<Route
						path={"/salutations"}
						element={
							<AuthGuard>
								<Salutations />
							</AuthGuard>
						}
					/>
					<Route
						path={"/salutations/create"}
						element={
							<AuthGuard>
								<CreateSalutation />
							</AuthGuard>
						}
					/>
					<Route
						path={"/salutations/:id"}
						element={
							<AuthGuard>
								<EditSalutation />
							</AuthGuard>
						}
					/>

					<Route path="*" element={<Login />} />
				</Routes>
			</Router>
		</React.Fragment>
	)
}
export default Routing
