import { ComponentType } from "react"
import {
	ApplySchemaAttributes,
	command,
	CommandFunction,
	DOMCompatibleAttributes,
	DOMOutputSpec,
	extension,
	ExtensionPriority,
	ExtensionTag,
	getTextSelection,
	Handler,
	keyBinding,
	KeyBindingProps,
	NodeExtension,
	NodeExtensionSpec,
	NodeSpecOverride,
	NodeWithPosition,
	omitExtraAttributes,
	PrimitiveSelection,
	ProsemirrorNode,
	Transaction,
} from "@remirror/core"
import { NodeViewComponentProps } from "@remirror/react"
import VariablesComponent, { VariablesComponentProps } from "./VariableComponent"

export interface VariableOptions {
	render?: (props: VariablesComponentProps) => React.ReactElement<HTMLElement> | null

	/**
	 * Called after the `commands.deleteFile` has been called.
	 */
	onDeleteFile?: Handler<(props: { tr: Transaction; pos: number; node: ProsemirrorNode }) => void>
}

/**
 * Adds a file node to the editor
 */
@extension<VariableOptions>({
	defaultOptions: {
		render: VariablesComponent,
	},
	handlerKeys: ["onDeleteFile"],
})
export class VariableExtension extends NodeExtension<VariableOptions> {
	get name() {
		return "variable" as const
	}

	ReactComponent: ComponentType<NodeViewComponentProps> = props => {
		return this.options.render({ ...props, abort: () => {}, context: undefined })
	}

	createTags() {
		return [ExtensionTag.InlineNode]
	}

	createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
		return {
			attrs: {
				...extra.defaults(),
				id: { default: "" },
			},
			atom: true,
			draggable: true,
			group: "inline",
			
			inline: true,
				parseDOM: [
				{
					tag: "span[data-variable]",
					getAttrs: dom => {
						const id = (dom as Element).getAttribute("data-variable")
						if (!id) {
							return false
						}

						return { id }
					},
				},
			],
			selectable: true,
			toDOM(node): DOMOutputSpec {
				const { id } = node.attrs

				return ["span", { "data-variable": id }, `FIXME`]
			},
		}
	}

	@command()
	insertVariable(attributes: VariableAttributes,selection?: PrimitiveSelection): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc)
			const node = this.type.create(attributes)

			dispatch?.(tr.replaceRangeWith(from, to, node))

			return true
		}
	}

	@command()
	deleteVariable(pos: number): CommandFunction {
		return ({ tr, state, dispatch }) => {
			const node = state.doc.nodeAt(pos)

			if (node && node.type === this.type) {
				tr.delete(pos, pos + 1).scrollIntoView()
				this.options.onDeleteFile({ tr, pos, node })
				dispatch?.(tr)
				return true
			}

			return false
		}
	}

	@keyBinding({ shortcut: ["Backspace", "Delete"] })
	backspaceShortcut(props: KeyBindingProps): boolean {
		const { tr, state } = props
		const { from, to, empty } = tr.selection

		if (!this.hasHandlers("onDeleteFile") || empty) {
			return false
		}

		// Collect a list of files nodes contained within this delete range
		const onDeleteFileCallbacks: NodeWithPosition[] = []
		state.doc.nodesBetween(from, to, (node, pos) => {
			if (node.type === this.type) {
				onDeleteFileCallbacks.push({ node, pos })
			}

			return true
		})

		// Call the onDeleteFile callback for each file being deleted.
		onDeleteFileCallbacks.forEach(({ node, pos }) => {
			this.options.onDeleteFile({ tr, node, pos })
		})

		// Don't need to handle the delete ourselves, just the callbacks
		return false
	}

}
export interface VariableAttributes {
	id?: string;
  
  }
declare global {
	namespace Remirror {
		interface AllExtensions {
			variable: VariableExtension
		}
	}
}
