export default class MCountry {

	static fieldSelection(): string {
		return `{
			code
			name(locale: "de-DE")
		}`
	}


	readonly code: string
	readonly name: string


	constructor(properties: {
		readonly code: string
		readonly name: string
	}) {
		this.code = properties.code
		this.name = properties.name
	}


	static fromJson(json: any): MCountry {
		return new this({
			code: json.code,
			name: json.name
		})
	}
}
