import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, FormGroup, FormControlLabel, Switch } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "../shared/ConfirmDialog"
import { djs_djs } from "./__generated__/djs"
import parsePhoneNumberFromString from "libphonenumber-js"
import { formatInGermany } from "../shared/formatPhoneNumber"

const DjList = ({ djs, archive, unarchive, checked }: { djs?: djs_djs[]; archive: Function; unarchive: Function; checked: boolean }) => {
	const { t, i18n } = useTranslation()
	const [open, openDialog] = React.useState(false)
	const [currentDj, setCurrentDj] = React.useState<djs_djs | null>(null)
	const openConfirmDialog = (row: djs_djs) => {
		setCurrentDj(row)
		openDialog(true)
	}
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("alias")}</TableCell>
							<TableCell align="right">{t("firstName")}</TableCell>
							<TableCell align="right">{t("lastName")}</TableCell>
							<TableCell align="right">{t("emailAdress")}</TableCell>
							<TableCell align="right">{t("mobilePhoneNumber")}</TableCell>
							<TableCell align="right">{t("businessPhoneNumber")}</TableCell>
							<TableCell align="right">{t("homePhoneNumber")}</TableCell>
							<TableCell align="right">{t("remarks")}</TableCell>
							<TableCell align="right">{t("djCategory")}</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{djs?.map((row: djs_djs) =>
							row.archivalTimestamp ? (
								checked ? (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{row?.alias}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.firstName}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.lastName}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.emailAddress}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{formatInGermany(row?.mobilePhoneNumber)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{formatInGermany(row?.businessPhoneNumber)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{formatInGermany(row?.homePhoneNumber)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.remarks}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.category.label}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											<div className="table-action-body">
												<RestoreFromTrashIcon className="delete-style" onClick={() => unarchive(row.id)} />
											</div>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)
							) : (
								<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row?.alias}
									</TableCell>
									<TableCell align="right">{row?.firstName}</TableCell>
									<TableCell align="right">{row?.lastName}</TableCell>
									<TableCell align="right">{row?.emailAddress}</TableCell>
									<TableCell align="right">{formatInGermany(row?.mobilePhoneNumber)}</TableCell>
									<TableCell align="right">{formatInGermany(row?.businessPhoneNumber)}</TableCell>
									<TableCell align="right">{formatInGermany(row?.homePhoneNumber)}</TableCell>
									<TableCell align="right">{row?.remarks}</TableCell>
									<TableCell align="right">{row?.category.label}</TableCell>
									<TableCell align="right">
										<div className="table-action-body">
											<Link component={RouterLink} to={`${row.id}`}>
												<EditOutlinedIcon />
											</Link>
											<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={open}
				openDialog={openDialog}
				content={`Do you want to archive ${currentDj?.firstName ? currentDj.firstName : ""} ${currentDj?.lastName ? currentDj.lastName : ""} ?`}
				Complete={() => {
					archive(currentDj?.id ? currentDj.id : "0")
				}}
			/>
		</Paper>
	)
}
export default DjList
