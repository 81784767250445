import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import ProcessParticipationTagsList from "./ProcessParticipationTagsList"
import { processParticipationTagsGQL, archiveProcessParticipationTagGQL, unarchiveProcessParticipationTagGQL } from "./processParticipationTagsGQL"
import { processParticipationTags as processParticipationTagsInterface } from "./__generated__/processParticipationTags"
import { archiveProcessParticipationTagVariables, archiveProcessParticipationTag } from "./__generated__/archiveProcessParticipationTag"
import "./processParticipationTags.scss"
import { unarchiveProcessParticipationTag } from "./__generated__/unarchiveProcessParticipationTag"
import ListHeader from "../shared/TableHeader"

const ProcessParticipationTags = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("processParticipationTags")
	const [getProcessParticipationTags, { loading: processParticipationTagsLoading, error: processParticipationTagsError, data: processParticipationTags }] =
		useLazyQuery<processParticipationTagsInterface>(processParticipationTagsGQL, {
			fetchPolicy: "network-only", // Doesn't check cache before making a network request
			variables: { includeArchived: true },
		})
	const [archiveProcessParticipationTagClick, { data, loading, error }] = useMutation<
		archiveProcessParticipationTag,
		archiveProcessParticipationTagVariables
	>(archiveProcessParticipationTagGQL)
	const [unarchiveProcessParticipationTagClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveProcessParticipationTag,
		archiveProcessParticipationTagVariables
	>(unarchiveProcessParticipationTagGQL)
	React.useEffect(() => {
		getProcessParticipationTags()
	}, [])
	const archive = async (id: string) => {
		const archiveProcessParticipationTagData = archiveProcessParticipationTagClick({
			variables: { id: id },
		})
		if ((await archiveProcessParticipationTagData)?.data?.archiveProcessParticipationTag?.__typename ? true : false) {
			getProcessParticipationTags()
		}
	}
	const unarchive = async (id: string) => {
		const unarchiveProcessParticipationTagData = unarchiveProcessParticipationTagClick({
			variables: { id: id },
		})
		if ((await unarchiveProcessParticipationTagData)?.data?.unarchiveProcessParticipationTag?.__typename ? true : false) {
			getProcessParticipationTags()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
			<ListHeader checked={checked} setChecked={setChecked} title={title} />
				
				<ProcessParticipationTagsList
					processParticipationTags={processParticipationTags?.processParticipationTags}
					archive={archive}
					unarchive={unarchive}
					checked={checked}
				/>
			</Paper>
		</div>
	)
}

export default ProcessParticipationTags
