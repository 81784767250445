import * as React from "react"
import { Paper } from "@mui/material"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import DJList from "./DJList"
import { djsGQL, archiveDjGQL, unarchiveDjGQL } from "./djGQL"
import { djs } from "./__generated__/djs"
import { archiveDj, archiveDjVariables } from "./__generated__/archiveDj"
import { unarchiveDj } from "./__generated__/unarchiveDj"
import ListHeader from "../shared/TableHeader"

const Dj = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("dj")
	const [getDjs, { loading: djsLoading, error: djsError, data: djs }] = useLazyQuery<djs>(djsGQL, {
		fetchPolicy: "network-only", // Doesn't check cache before making a network request
		variables: { includeArchived: true },
	})
	const [archiveDjClick, { data, loading, error }] = useMutation<archiveDj, archiveDjVariables>(archiveDjGQL)
	const [unarchiveDjClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<unarchiveDj, archiveDjVariables>(
		unarchiveDjGQL
	)

	React.useEffect(() => {
		getDjs()
	}, [])
	const archive = async (id: string) => {
		const archiveDjData = archiveDjClick({
			variables: { id: id },
		})
		if ((await archiveDjData)?.data?.archiveDj?.__typename ? true : false) {
			getDjs()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveDjData = unarchiveDjClick({
			variables: { id: id },
		})
		if ((await unarchiveDjData)?.data?.unarchiveDj?.__typename ? true : false) {
			getDjs()
		}
	}
	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<DJList djs={djs?.djs} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}
export default Dj
