import { useEffect, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { getUsersGQL, archiveUserGQL, unarchiveUserGQL } from "./usersGQL"
import UsersList from "./UsersList"
import { useTranslation } from "react-i18next"
import { users as usersInterface } from "./__generated__/users"
import { archiveUser, archiveUserVariables } from "./__generated__/archiveUser"
import { unarchiveUser, unarchiveUserVariables } from "./__generated__/unarchiveUser"
import ListHeader from "../shared/TableHeader"

const Users = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = useState(false)
	const title = t("users")
	const [getUsers, { loading: usersLoading, error: usersError, data: users }] = useLazyQuery<usersInterface>(getUsersGQL, {
		fetchPolicy: "network-only", // Doesn't check cache before making a network request
		variables: { includeArchived: true },
	})
	const [archiveUserClick, { data, loading, error }] = useMutation<archiveUser, archiveUserVariables>(archiveUserGQL)
	const [unarchiveUserClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<unarchiveUser, unarchiveUserVariables>(
		unarchiveUserGQL
	)

	useEffect(() => {
		getUsers()
	}, [])
	const archive = async (id: string) => {
		const archiveUserData = await archiveUserClick({
			variables: { id: id },
		})
		if (archiveUserData?.data?.archiveUser?.__typename) {
			getUsers()
		}
	}
	const unarchive = async (id: string) => {
		const unarchiveUserData = unarchiveUserClick({
			variables: { id: id },
		})
		if ((await unarchiveUserData)?.data?.unarchiveUser?.__typename ? true : false) {
			getUsers()
		}
	}
	return (
		<div className="div-table-wrapper">
			<ListHeader checked={checked} setChecked={setChecked} title={title} />
			<UsersList data={users?.users} archive={archive} unarchive={unarchive} checked={checked} />
		</div>
	)
}
export default Users
