import { gql } from "@apollo/client"
export const eventsByLocationReportGQL = gql`
	query eventsByLocationReport($from: LocalDate!, $to: LocalDate!) {
		eventsByLocationReport(from: $from, to: $to) {
			values {
				eventCount
				location {
					name
				}
			}
		}
	}
`

export const recommendationsByLocationReportGQL = gql`
	query recommendationsByLocationReport($from: LocalDate!, $to: LocalDate!) {
		recommendationsByLocationReport(from: $from, to: $to) {
			values {
				eventCount
				location {
					name
				}
			}
		}
	}
`

export const weeklyReportGQL = gql`
	query weeklyReport($from: LocalDate!, $to: LocalDate!) {
		weeklyReport(from: $from, to: $to) {
			values {
				bookingCount
				requestCount
				week
			}
		}
	}
`

