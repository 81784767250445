import { Grid } from "@mui/material"
import * as React from "react"
import PhoneNumber from "./PhoneNumber"

const PhoneNumbers = (props: { phoneNumberAmount: number; phoneNumbers: string[]; setPhoneNumbers: Function }) => {
	const [arr, setArr] = React.useState<any>([])
	React.useEffect(() => {
		let a: number[] = Array.from({ length: props.phoneNumberAmount }, (v, i) => i)
		setArr(a)
	}, [props.phoneNumberAmount])

	return (
		<>
			{arr.map((a: any, index: number) => {
				return (
					<Grid key={index} item xs={6}>
						<PhoneNumber index={index} array={props.phoneNumbers} setArray={props.setPhoneNumbers} />
					</Grid>
				)
			})}
		</>
	)
}
export default PhoneNumbers
