import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import EventConsultationsList from "./EventConsultationsList"
import { eventConsultationsGQL, archiveEventConsultationGQL, unarchiveEventConsultationGQL } from "./eventConsultationsGQL"
import { eventConsultations as eventConsultationsInterface } from "./__generated__/eventConsultations"
import { archiveEventConsultationVariables, archiveEventConsultation } from "./__generated__/archiveEventConsultation"
import "./eventConsultations.scss"
import { unarchiveEventConsultation } from "./__generated__/unarchiveEventConsultation"
import ListHeader from "../shared/TableHeader"

const EventConsultations = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("eventConsultations")
	const [getEventConsultations, { loading: eventConsultationsLoading, error: eventConsultationsError, data: eventConsultations }] =
		useLazyQuery<eventConsultationsInterface>(eventConsultationsGQL, {
			fetchPolicy: "network-only", // Doesn't check cache before making a network request
			variables: { includeArchived: true },
		})
	const [archiveEventConsultationClick, { data, loading, error }] = useMutation<archiveEventConsultation, archiveEventConsultationVariables>(
		archiveEventConsultationGQL
	)

	const [unarchiveEventConsultationClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveEventConsultation,
		archiveEventConsultationVariables
	>(unarchiveEventConsultationGQL)

	React.useEffect(() => {
		getEventConsultations()
	}, [])
	const archive = async (id: string) => {
		const archiveEventConsultationData = archiveEventConsultationClick({
			variables: { id: id },
		})
		if ((await archiveEventConsultationData)?.data?.archiveEventConsultation?.__typename ? true : false) {
			getEventConsultations()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveEventConsultationData = unarchiveEventConsultationClick({
			variables: { id: id },
		})
		if ((await unarchiveEventConsultationData)?.data?.unarchiveEventConsultation?.__typename ? true : false) {
			getEventConsultations()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<EventConsultationsList eventConsultations={eventConsultations?.eventConsultations} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}

export default EventConsultations
