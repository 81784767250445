import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { useTranslation } from "react-i18next"
import RecommendationsByLocationListHeader from "./RecommendationsByLocationListHeader"
import { recommendationsByLocationReport_recommendationsByLocationReport_values } from "../__generated__/recommendationsByLocationReport"

const RecommendationsByLocationList = ({
	data,
	from,
	setFrom,
	to,
	setTo,
}: {
	data?: recommendationsByLocationReport_recommendationsByLocationReport_values[]
	from: Date
	setFrom: Function
	to: Date
	setTo: Function
}) => {
	const { t, i18n } = useTranslation()
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<RecommendationsByLocationListHeader from={from} to={to} setFrom={setFrom} setTo={setTo} />
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("recommendation")}</TableCell>
							<TableCell align="right">{t("eventCount")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((row: recommendationsByLocationReport_recommendationsByLocationReport_values, id: number) => (
							<TableRow key={id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
								<TableCell component="th" scope="row">
									{row?.location?.name}
								</TableCell>
								<TableCell align="right">{row?.eventCount}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
}
export default RecommendationsByLocationList
