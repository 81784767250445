import { Box, ButtonGroup } from "@mui/material"
import BulletList from "./BulletList"
import OrderedList from "./OrderedList"

const List = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				"& > *": {
					m: 1,
				},
			}}
		>
			<ButtonGroup variant="outlined" aria-label="outlined button group">
				<BulletList />
				<OrderedList />
			</ButtonGroup>
		</Box>
	)
}
export default List
