import { gql } from "@apollo/client"
export const salutationsGQL = gql`
	query salutations($includeArchived: Boolean) {
		salutations(includeArchived: $includeArchived) {
			id
			label
			archivalTimestamp
		}
	}
`
export const createSalutationGQL = gql`
	mutation createSalutation($label: SalutationLabel!) {
		createSalutation(input: { label: $label }) {
			__typename
		}
	}
`
export const SalutationGQL = gql`
	query Salutation($id: ID!) {
		salutation(id: $id) {
			id
			label
		}
	}
`
export const updateSalutationGQL = gql`
	mutation updateSalutation($id: ID!, $label: SalutationLabel!) {
		updateSalutation(input: { id: $id, label: $label }) {
			__typename
		}
	}
`
export const archiveSalutationGQL = gql`
	mutation archiveSalutation($id: ID!) {
		archiveSalutation(id: $id) {
			__typename
		}
	}
`

export const unarchiveSalutationGQL = gql`
	mutation unarchiveSalutation($id: ID!) {
		unarchiveSalutation(id: $id) {
			__typename
		}
	}
`