import * as React from "react"
import { useTranslation } from "react-i18next"
import { TextField } from "@mui/material"
import { onlyPhoneNumber } from "../onlyPhoneNumber"
const PhoneNumber = (props: { index: number; array: string[]; setArray: Function }) => {
	const [phoneNumber, setPhoneNumber] = React.useState("")
	const { t, i18n } = useTranslation()
	const onChangePhoneNumber = (numb: string) => {
		let number = numb
		if (Number(number) < 0) {
			number = "0"
		}

		setPhoneNumber(number)
		let arr = [...props.array]
		if (arr.length === 0) {
			arr = [number]
		} else {
			arr[props.index] = number
		}
		props.setArray(arr)
	}

	return (
		<>
			<TextField
				id="outlined-basic"
				label={t("phoneNumber")}
				value={phoneNumber}
				onChange={e => {
					onChangePhoneNumber(onlyPhoneNumber(e.target.value))
				}}
				variant="outlined"
			/>
		</>
	)
}

export default PhoneNumber
