import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateEquipmentGQL, EquipmentGQL } from "../equipmentsGQL"
import { updateEquipment as updateEquipmentInterface, updateEquipmentVariables } from "../__generated__/updateEquipment"
import { Equipment, EquipmentVariables } from "../__generated__/Equipment"
import { EquipmentType } from "../../../__generated__/globalTypes"
import { useFormik } from "formik"
import * as yup from "yup"

const EditEquipment = () => {
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
		type: yup.string().required(`${t("type")} is required`),
		priceExcludingVat: yup.number().required(`${t("priceExcludingVat")} is required`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
			type: EquipmentType.light,
			priceExcludingVat: "",
		},
		validationSchema: validationSchema,
		onSubmit: async values => {
			const updateEquipmentData = await updateEquipment({ variables: {
				id: id!,
				label: values.label,
				type: values.type,
				priceExcludingVat: Math.round(parseFloat(values.priceExcludingVat) * 100),
			}})
			if (updateEquipmentData?.data?.updateEquipment?.__typename) {
				navigate("/equipments")
			}
		},
	})
	const [updateEquipment, { data, loading, error }] = useMutation<updateEquipmentInterface, updateEquipmentVariables>(updateEquipmentGQL)
	const { loading: loadingGetEquipment, data: getEquipmentData } = useQuery<Equipment, EquipmentVariables>(EquipmentGQL, {
		variables: { id: `${id}` },
	})
	React.useEffect(() => {
		if (!loadingGetEquipment) {
			const equipment = getEquipmentData?.equipment
			if (equipment) {
				formik.setFieldValue("label", equipment.label)
				formik.setFieldValue("type", equipment.type)
				formik.setFieldValue("priceExcludingVat", (equipment.priceExcludingVat / 100).toFixed(2))
			}
		}
	}, [loadingGetEquipment])
	let navigate = useNavigate()
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editEquipments")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="priceExcludingVat"
									name="priceExcludingVat"
									label={t("priceExcludingVat")}
									value={formik.values.priceExcludingVat}
									onChange={formik.handleChange}
									error={formik.touched.priceExcludingVat && Boolean(formik.errors.priceExcludingVat)}
									helperText={formik.touched.priceExcludingVat && formik.errors.priceExcludingVat}
									variant="outlined"
									type={"number"}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									select
									id="type"
									name="type"
									label={t("type")}
									value={formik.values.type}
									onChange={formik.handleChange}
									error={formik.touched.type && Boolean(formik.errors.type)}
									helperText={formik.touched.type && formik.errors.type}
									variant="outlined"
								>
									{Object.values(EquipmentType).map((category: EquipmentType, index: number) => {
										return (
											<MenuItem key={index} value={category}>
												{String(category)}
											</MenuItem>
										)
									})}
								</TextField>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditEquipment
