import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "../shared/ConfirmDialog"
import { eventOccasions_eventOccasions } from "./__generated__/eventOccasions"

const EventOccasionList = ({
	eventOccasions,
	archive,
	unarchive,
	checked,
}: {
	eventOccasions?: eventOccasions_eventOccasions[]
	archive: Function
	unarchive: Function
	checked: boolean
}) => {
	const { t, i18n } = useTranslation()
	const [open, openDialog] = React.useState(false)
	const [currentEventOccasion, setCurrentEventOccasion] = React.useState<eventOccasions_eventOccasions | null>(null)
	const openConfirmDialog = (row: eventOccasions_eventOccasions) => {
		setCurrentEventOccasion(row)
		openDialog(true)
	}
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("label")}</TableCell>
							<TableCell>{t("isMiscellaneous")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{eventOccasions?.map((row: eventOccasions_eventOccasions) =>
							row?.archivalTimestamp ? (
								checked ? (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{row?.label}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{String(row?.isMiscellaneous)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											<div className="table-action-body">
												<RestoreFromTrashIcon className="delete-style" onClick={() => unarchive(row.id)} />
											</div>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)
							) : (
								<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row?.label}
									</TableCell>
									<TableCell component="th" scope="row">
										{String(row?.isMiscellaneous)}
									</TableCell>
									<TableCell align="right">
										<div className="table-action-body">
											<Link component={RouterLink} to={`${row.id}`}>
												<EditOutlinedIcon />
											</Link>
											<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={open}
				openDialog={openDialog}
				content={`Do you want to archive ${currentEventOccasion?.label ? currentEventOccasion.label : ""} ?`}
				Complete={() => {
					archive(currentEventOccasion?.id ? currentEventOccasion.id : "")
				}}
			/>
		</Paper>
	)
}
export default EventOccasionList
