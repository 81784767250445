import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import Button from "@mui/material/Button"
import UndoIcon from '@mui/icons-material/Undo';

const UnDo = () => {
	// Using command chaining
	const active = useActive()
	const { undo } = useCommands()

	return (
		<Button
			onClick={() => {
				undo()
			}}
			variant="outlined"
			disabled={undo.enabled() === false}
		>
			<UndoIcon />
		</Button>
	)
}
export default UnDo
