import * as React from "react"
import { useNavigate } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import parsePhoneNumberFromString from "libphonenumber-js"
import { useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { createUserGQL } from "../usersGQL"
import PhoneNumbers from "../../shared/CreatePhoneNumbers/PhoneNumbers"
import { createUser, createUserVariables } from "../__generated__/createUser"
import "../users.scss"
import PasswordTextField from "../../shared/PasswordComponent"
import { useFormik } from "formik"
import * as yup from "yup"

const parseOrNull = (string: string) => {
	const value = parsePhoneNumberFromString(string, "DE")
	if (!value || !value.isPossible()) {
		return null
	}

	return value
}
const CreateUser = () => {
	const { t, i18n } = useTranslation()
	const [createUser, { data, loading, error }] = useMutation<createUser, createUserVariables>(createUserGQL)
	let navigate = useNavigate()
	const [phoneNumberAmount, setPhoneNumberAmount] = React.useState(1)
	const [phoneNumbers, setPhoneNumbers] = React.useState<string[]>([])

	const validationSchema = yup.object({
		emailAddress: yup
			.string()
			.required(`${t("emailAdress")} is required`)
			.email("Enter a valid email")
			.max(50, `${t("emailAdress")} can not be longer than 50 characters`),
		firstName: yup
			.string()
			.required(`${t("firstName")} is required`)
			.max(50, `${t("firstName")} can not be longer than 50 characters`),
		lastName: yup
			.string()
			.required(`${t("lastName")} is required`)
			.max(50, `${t("lastName")} can not be longer than 50 characters`),
		password: yup
			.string()
			.required(`${t("password")} is required`)
			.max(50, `${t("password")} can not be longer than 50 characters`),
	})

	const formik = useFormik({
		initialValues: {
			emailAddress: "",
			firstName: "",
			lastName: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			let phoneNumb: string[] = []
			phoneNumbers.forEach((value, index) => {
				phoneNumb = [...phoneNumb, String(parseOrNull(value)?.number)]
			})
			const numbers = phoneNumb
			numbers.length = phoneNumberAmount
			const createUserData = await createUser({ variables: { ...values, phoneNumbers: numbers } })
			if (createUserData?.data?.createUser?.__typename) {
				navigate("/users")
			}
		},
	})

	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createUser")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="emailAddress"
									name="emailAddress"
									label={t("emailAdress")}
									value={formik.values.emailAddress}
									onChange={formik.handleChange}
									error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
									helperText={formik.touched.emailAddress && formik.errors.emailAddress}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="firstName"
									name="firstName"
									label={t("firstName")}
									value={formik.values.firstName}
									onChange={formik.handleChange}
									error={formik.touched.firstName && Boolean(formik.errors.firstName)}
									helperText={formik.touched.firstName && formik.errors.firstName}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="lastName"
									name="lastName"
									label={t("lastName")}
									value={formik.values.lastName}
									onChange={formik.handleChange}
									error={formik.touched.lastName && Boolean(formik.errors.lastName)}
									helperText={formik.touched.lastName && formik.errors.lastName}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<PasswordTextField
									fullWidth
									id="password"
									name="password"
									label={t("password")}
									value={formik.values.password}
									onChange={formik.handleChange}
									error={formik.touched.password && Boolean(formik.errors.password)}
									helperText={formik.touched.password && formik.errors.password}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="outlined-select-currency"
									select
									label={t("phoneNumberAmount")}
									value={phoneNumberAmount}
									onChange={e => {
										setPhoneNumberAmount(Number(e.target.value))
									}}
									helperText="Please select your currency"
								>
									{[0, 1, 2, 3].map(option => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<PhoneNumbers phoneNumberAmount={phoneNumberAmount} phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} />
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default CreateUser
