import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateMagazineGQL, MagazineGQL } from "../magazinesGQL"
import { Magazine, MagazineVariables } from "../__generated__/Magazine"
import { updateMagazine as updateMagazineInterface, updateMagazineVariables } from "../__generated__/updateMagazine"
import { useFormik } from "formik"
import * as yup from "yup"

const EditMagazine = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		name: yup
			.string()
			.required(`${t("name")} is required`)
			.max(100, `${t("name")} can not be longer than 100 characters`),
	})
	const formik = useFormik({
		initialValues: {
			name: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const updateMagazineData = await updateMagazine({ variables: { id: id, ...values } })
			if (updateMagazineData?.data?.updateMagazine?.__typename) {
				navigate("/magazines")
			}
		},
	})
	const { id } = useParams()
	const [updateMagazine, { data, loading, error }] = useMutation<updateMagazineInterface, updateMagazineVariables>(updateMagazineGQL)

	const { loading: loadingGetMagazine, data: getMagazineData } = useQuery<Magazine, MagazineVariables>(MagazineGQL, {
		variables: { id: `${id}` },
	})
	React.useEffect(() => {
		if (!loadingGetMagazine) {
			formik.setFieldValue("name", getMagazineData?.magazine?.name ? getMagazineData.magazine.name : "")
		}
	}, [loadingGetMagazine])
	
	let navigate = useNavigate()

	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createMagazine")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="name"
									name="name"
									label={t("name")}
									value={formik.values.name}
									onChange={formik.handleChange}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditMagazine
