import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button } from "@mui/material"
import { useMutation } from "@apollo/client"
import { createProcessEmailTemplateGQL } from "../processEmailTemplatesGQL"
import {
	createProcessEmailTemplate as createProcessEmailTemplateInterface,
	createProcessEmailTemplateVariables,
} from "../__generated__/createProcessEmailTemplate"
import { useFormik } from "formik"
import * as yup from "yup"

const CreateProcessEmailTemplate = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		subject: yup
			.string()
			.required(`${t("subject")} is required`)
			.max(30, `${t("subject")} can not be longer than 30 characters`),
		message: yup
			.string()
			.required(`${t("message")} is required`)
			.max(150, `${t("message")} can not be longer than 150 characters`),
	})
	const formik = useFormik({
		initialValues: {
			subject: "",
			message: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: createProcessEmailTemplateVariables) => {
			const createProcessEmailTemplateData = await createProcessEmailTemplate({ variables: values })
			if (createProcessEmailTemplateData?.data?.createProcessEmailTemplate?.__typename) {
				navigate("/process-templates")
			}
		},
	})
	const [createProcessEmailTemplate, { data, loading, error }] = useMutation<createProcessEmailTemplateInterface, createProcessEmailTemplateVariables>(
		createProcessEmailTemplateGQL
	)
	let navigate = useNavigate()
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createProcessEmailTemplates")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "35ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="subject"
									name="subject"
									label={t("subject")}
									value={formik.values.subject}
									onChange={formik.handleChange}
									error={formik.touched.subject && Boolean(formik.errors.subject)}
									helperText={formik.touched.subject && formik.errors.subject}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									id="message"
									name="message"
									label={t("message")}
									value={formik.values.message}
									onChange={formik.handleChange}
									error={formik.touched.message && Boolean(formik.errors.message)}
									helperText={formik.touched.message && formik.errors.message}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default CreateProcessEmailTemplate
