import { Box, ButtonGroup } from "@mui/material"
import HeadingLevel from "./HeadingLevel"
const Heading = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				"& > *": {
					m: 1,
				},
			}}
		>
			<ButtonGroup variant="outlined" aria-label="outlined button group">
				{[1, 2, 3].map((level: number) => {
					return <HeadingLevel level={level} key={level} />
				})}
			</ButtonGroup>
		</Box>
	)
}
export default Heading
