import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateEventConsultationGQL, EventConsultationGQL } from "../eventConsultationsGQL"
import { updateEventConsultation as updateEventConsultationInterface, updateEventConsultationVariables } from "../__generated__/updateEventConsultation"
import { EventConsultation, EventConsultationVariables } from "../__generated__/EventConsultation"
import { useFormik } from "formik"
import * as yup from "yup"

const EditEventConsultation = () => {
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const updateEventConsultationData = await updateEventConsultation({ variables: { id: id, ...values } })
			if (updateEventConsultationData?.data?.updateEventConsultation?.__typename) {
				navigate("/event-consultations")
			}
		},
	})
	const [updateEventConsultation, { data, loading, error }] = useMutation<updateEventConsultationInterface, updateEventConsultationVariables>(
		updateEventConsultationGQL
	)
	const { loading: loadingGetEventConsultation, data: getEventConsultationData } = useQuery<EventConsultation, EventConsultationVariables>(
		EventConsultationGQL,
		{
			variables: { id: `${id}` },
		}
	)
	React.useEffect(() => {
		if (!loadingGetEventConsultation) {
			formik.setFieldValue("label", getEventConsultationData?.eventConsultation?.label ? getEventConsultationData.eventConsultation.label : "")
		}
	}, [loadingGetEventConsultation])
	let navigate = useNavigate()
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editEventConsultations")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditEventConsultation
