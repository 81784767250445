import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "../shared/ConfirmDialog"
import { processEmailTemplates_processEmailTemplates } from "./__generated__/processEmailTemplates"

const ProcessEmailTemplateList = ({
	processEmailTemplates,
	archive,
	unarchive,
	checked,
}: {
	processEmailTemplates?: processEmailTemplates_processEmailTemplates[]
	archive: Function
	unarchive: Function
	checked: boolean
}) => {
	const { t, i18n } = useTranslation()
	const [open, openDialog] = React.useState(false)
	const [currentProcessEmailTemplate, setCurrentProcessEmailTemplate] = React.useState<processEmailTemplates_processEmailTemplates | null>(null)
	const openConfirmDialog = (row: processEmailTemplates_processEmailTemplates) => {
		setCurrentProcessEmailTemplate(row)
		openDialog(true)
	}
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("subject")}</TableCell>
							<TableCell align="right">{t("message")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{processEmailTemplates?.map((row: processEmailTemplates_processEmailTemplates) =>
							row?.archivalTimestamp ? (
								checked ? (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{row?.subject}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.message}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											<div className="table-action-body">
												<RestoreFromTrashIcon className="delete-style" onClick={() => unarchive(row.id)} />
											</div>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)
							) : (
								<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row?.subject}
									</TableCell>
									<TableCell align="right">{row?.message}</TableCell>
									<TableCell align="right">
										<div className="table-action-body">
											<Link component={RouterLink} to={`${row.id}`}>
												<EditOutlinedIcon />
											</Link>
											<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={open}
				openDialog={openDialog}
				content={`Do you want to archive ${currentProcessEmailTemplate?.subject ? currentProcessEmailTemplate.subject : ""} ?`}
				Complete={() => {
					archive(currentProcessEmailTemplate?.id ? currentProcessEmailTemplate.id : "0")
				}}
			/>
		</Paper>
	)
}
export default ProcessEmailTemplateList
