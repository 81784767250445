import { useMutation } from "@apollo/client"
import { signOutGQL } from "./LogoutGQL"
import { signOut } from "./__generated__/signOut"
import { Navigate } from "react-router-dom"

const Logout = () => {
	const [deAuthenticate, { loading }] = useMutation<signOut>(signOutGQL)
	//this gql query is sent to backend to end the user session and to clear cookies
	deAuthenticate()
	return loading ? <Navigate to="/" /> : <></>
}
export default Logout
