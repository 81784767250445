import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import WeddingFairsList from "./WeddingFairsList"
import { weddingFairsGQL, archiveWeddingFairGQL, unarchiveWeddingFairGQL } from "./weddingFairsGQL"
import { weddingFairs } from "./__generated__/weddingFairs"
import { archiveWeddingFair, archiveWeddingFairVariables } from "./__generated__/archiveWeddingFair"
import "./weddingFairs.scss"
import { unarchiveWeddingFair } from "./__generated__/unarchiveWeddingFair"
import ListHeader from "../shared/TableHeader"

const WeddingFairs = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("weddingFairs")
	const [getWeddingFairs, { loading: weddingFairsLoading, error: weddingFairsError, data: weddingFairs }] = useLazyQuery<weddingFairs>(weddingFairsGQL, {
		fetchPolicy: "network-only", // Doesn't check cache before making a network request
		variables: { includeArchived: true },
	})
	const [archiveWeddingFairClick, { data, loading, error }] = useMutation<archiveWeddingFair, archiveWeddingFairVariables>(archiveWeddingFairGQL)

	const [unarchiveWeddingFairClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveWeddingFair,
		archiveWeddingFairVariables
	>(unarchiveWeddingFairGQL)

	React.useEffect(() => {
		getWeddingFairs()
	}, [])
	const archive = async (id: string) => {
		const archiveWeddingFairData = await archiveWeddingFairClick({
			variables: { id: id },
		})
		if (archiveWeddingFairData?.data?.archiveWeddingFair?.__typename) {
			getWeddingFairs()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveWeddingFairData = unarchiveWeddingFairClick({
			variables: { id: id },
		})
		if ((await unarchiveWeddingFairData)?.data?.unarchiveWeddingFair?.__typename ? true : false) {
			getWeddingFairs()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<WeddingFairsList weddingFairs={weddingFairs?.weddingFairs} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}

export default WeddingFairs
