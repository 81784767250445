import { useState } from "react"
import Logout from "../shared/Logout"
import { useTranslation } from "react-i18next"
import { me_me_User, me_me_AuthenticationRequiredError } from "./__generated__/me"
import "./headerComp.scss"

const GreetingHeader = ({ data }: { data?: me_me_User | me_me_AuthenticationRequiredError }) => {
	const { t, i18n } = useTranslation()
	const [logout, setLogout] = useState<boolean>(false)
	return (
		<div className="greeting-layout">
			<div className="greeting-app-name-style">{t("mmpt")}</div>
			<div className="hallo-header">
				<div>{t("hello")}</div>
				<strong>
					{data?.__typename === "User" ? data.firstName : ""} {data?.__typename === "User" ? data?.lastName : ""}
				</strong>
				(
				<div className="logout-style" onClick={() => setLogout(!logout)}>
					{t("logOff")}
					{logout ? <Logout></Logout> : <></>}
				</div>
				)
			</div>
		</div>
	)
}

export default GreetingHeader
