import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { weeklyReportGQL } from "../dashboardGQL"
import BookingsChartChart from "./BookingsChartChart"
import { weeklyReport, weeklyReportVariables } from "../__generated__/weeklyReport"
import { formatDate } from "../../shared/formatDate"

const BookingsCharts = () => {
	const [to, setTo] = useState(new Date())
	const [from, setFrom] = useState(new Date(to.getFullYear(), to.getMonth()-1, to.getDate()))
	const [getBookingsCharts, { loading: bookingsChartsLoading, error: bookingsChartsError, data: bookingsCharts }] = useLazyQuery<
		weeklyReport,
		weeklyReportVariables
	>(weeklyReportGQL, {
		variables: {
			from: formatDate(from),
			to: formatDate(to),
		},
	})
	useEffect(() => {
		getBookingsCharts()
	}, [])

	useEffect(() => {
		getBookingsCharts()
	}, [from, to])

	return (
		<div className="div-table-wrapper" style={{ maxWidth: "650px" }}>
			{bookingsCharts ? (
				<BookingsChartChart data={bookingsCharts?.weeklyReport.values} from={from} to={to} setFrom={setFrom} setTo={setTo} />
			) : (
				<div></div>
			)}
		</div>
	)
}
export default BookingsCharts
