import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import EventOccasionsList from "./EventOccasionList"
import { eventOccasionsGQL, archiveEventOccasionGQL, unarchiveEventOccasionGQL } from "./eventOccasionGQL"
import "./eventOccasion.scss"
import { eventOccasions as eventOccasionsInterface, eventOccasions_eventOccasions } from "./__generated__/eventOccasions"
import { archiveEventOccasion, archiveEventOccasionVariables } from "./__generated__/archiveEventOccasion"
import { unarchiveEventOccasion } from "./__generated__/unarchiveEventOccasion"
import ListHeader from "../shared/TableHeader"

const EventOccasions = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("eventOccasion")
	const [getEventOccasions, { loading: eventOccasionsLoading, error: eventOccasionsError, data: eventOccasions }] = useLazyQuery<eventOccasionsInterface>(
		eventOccasionsGQL,
		{
			fetchPolicy: "network-only", // Doesn't check cache before making a network request
			variables: { includeArchived: true },
		}
	)
	const [archiveEventOccasionClick, { data, loading, error }] = useMutation<archiveEventOccasion, archiveEventOccasionVariables>(archiveEventOccasionGQL)

	const [unarchiveEventOccasionClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveEventOccasion,
		archiveEventOccasionVariables
	>(unarchiveEventOccasionGQL)

	React.useEffect(() => {
		getEventOccasions()
	}, [])
	const archive = async (id: string) => {
		const archiveEventOccasionData = await archiveEventOccasionClick({
			variables: { id: id },
		})
		if (archiveEventOccasionData?.data?.archiveEventOccasion?.__typename) {
			getEventOccasions()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveEventOccasionData = unarchiveEventOccasionClick({
			variables: { id: id },
		})
		if ((await unarchiveEventOccasionData)?.data?.unarchiveEventOccasion?.__typename ? true : false) {
			getEventOccasions()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<EventOccasionsList eventOccasions={eventOccasions?.eventOccasions} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}

export default EventOccasions
