import * as React from "react"
import { useTranslation } from "react-i18next"
import { TextField } from "@mui/material"
import { onlyPhoneNumber } from "../onlyPhoneNumber"

const PhoneNumber = (props: { index: number; array: string[]; setArray: Function; loadingGetUser: boolean }) => {
	const [phoneNumber, setPhoneNumber] = React.useState("")
	const { t, i18n } = useTranslation()
	React.useEffect(() => {
		if (!props.loadingGetUser) {
			setPhoneNumber(props.array[props.index] ? props.array[props.index] : "")
		}
	}, [props.loadingGetUser])
	const onChangePhoneNumber = (numb: string) => {
		let number = numb
		setPhoneNumber(number)
		let arr = [...props.array]
		if (arr.length === 0) {
			arr = [number]
		} else {
			arr.splice(props.index, 1, number)
			arr[props.index] = number
		}
		props.setArray(arr)
	}

	return (
		<>
			<TextField
				id="outlined-basic"
				label={t("phoneNumber")}
				value={phoneNumber}
				type={"tel"}
				inputProps={{
					pattern: "^[0-9.+]+$",
				}}
				onChange={e => {
					onChangePhoneNumber(onlyPhoneNumber(e.target.value))
				}}
				variant="outlined"
			/>
		</>
	)
}

export default PhoneNumber
