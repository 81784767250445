import MInlineTextMark from './MInlineTextMark'
import { error } from '../../../error'


export type MInlineTextNode =
	MInlineTextNodeLineBreak |
	MInlineTextNodeSignature |
	MInlineTextNodeText |
	MInlineTextNodeVariable


export default class MInlineText {

	constructor(
		readonly nodes: readonly MInlineTextNode[]
	) {}


	static readonly empty = new MInlineText([])


	static fromJson(json: any): MInlineText {
		if (typeof json === 'string') {
			return this.ofString(json)
		}

		return new MInlineText(json.map((json:any) => {
			if (typeof json === 'string') {
				return new MInlineTextNodeText(json)
			}

			const [type, ...data] = json
			switch (type) {
				case 'line break':
					return new MInlineTextNodeLineBreak()

				case 'signature':
					return new MInlineTextNodeSignature()

				case 'text':
					if (typeof data === 'string') {
						return new MInlineTextNodeText(data)
					}
					else {
						return new MInlineTextNodeText(data[0], data[1].map((it:any) => MInlineTextMark.fromJson(it)))
					}

				case 'variable':
					return new MInlineTextNodeVariable(data[0], data[1]?.map((it:any) => MInlineTextMark.fromJson(it)) ?? [])

				default:
					return error(`Unknown type: ${type}`)
			}
		}))
	}


	static ofString(text: string): MInlineText {
		return new MInlineText(text ? [new MInlineTextNodeText(text)] : []) // FIXME what about line breaks
	}


	static parse(value: string): MInlineText {
		return this.fromJson(JSON.parse(value))
	}


	serialize(): string {
		return JSON.stringify(this.toJson())
	}


	toJson(): any {
		if (this.nodes.length === 1 && this.nodes[0] instanceof MInlineTextNodeText && this.nodes[0].marks.length === 0) {
			return this.nodes[0].value
		}

		return this.nodes.map(it => it.toJson())
	}
}


export class MInlineTextNodeLineBreak {

	readonly type = 'line break'


	toJson(): any {
		return ['line break']
	}
}


export class MInlineTextNodeSignature {

	readonly type = 'signature'


	toJson(): any {
		return ['signature']
	}
}


export class MInlineTextNodeText {

	readonly type = 'text'


	constructor(
		readonly value: string,
		readonly marks: MInlineTextMark[] = []
	) {}


	toJson(): any {
		if (this.marks.length === 0) {
			return this.value
		}
		else {
			return ['text', this.value, this.marks.map(it => it.toJson())]
		}
	}
}


export class MInlineTextNodeVariable {

	readonly type = 'variable'


	constructor(
		readonly id: string,
		readonly marks: MInlineTextMark[] = []
	) {}


	toJson(): any {
		if (this.marks.length === 0) {
			return ['variable', this.id]
		}
		else {
			return ['variable', this.id, this.marks.map(it => it.toJson())]
		}
	}
}