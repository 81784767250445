import { Link as RouterLink } from "react-router-dom"
import { Typography, Link } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import "./documentTemplateBlocks.scss"

const DocumentTemplateBlocksHeader = () => {
	const { t, i18n } = useTranslation()
	return (
		<div className="paper-header">
			<div className="title-header">
				<Typography variant="h4">{t("documentTemplateBlocks")}</Typography>
			</div>

			<div className="title-header">
				<Link component={RouterLink} to={"create"}>
					<AddIcon />
				</Link>
			</div>
		</div>
	)
}
export default DocumentTemplateBlocksHeader
