import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateMusicPreferenceGQL, MusicPreferenceGQL } from "../musicPreferencesGQL"
import { updateMusicPreference, updateMusicPreferenceVariables } from "../__generated__/updateMusicPreference"
import { MusicPreference, MusicPreferenceVariables } from "../__generated__/MusicPreference"
import { useFormik } from "formik"
import * as yup from "yup"

const EditMusicPreference = () => {
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const updateMusicPreferenceData = await updateMusicPreference({ variables: { id: id, ...values } })
			if (updateMusicPreferenceData?.data?.updateMusicPreference?.__typename) {
				navigate("/music-preferences")
			}
		},
	})
	const [updateMusicPreference, { data, loading, error }] = useMutation<updateMusicPreference, updateMusicPreferenceVariables>(updateMusicPreferenceGQL)
	const { loading: loadingGetMusicPreference, data: getMusicPreferenceData } = useQuery<MusicPreference, MusicPreferenceVariables>(MusicPreferenceGQL, {
		variables: { id: `${id}` },
	})
	React.useEffect(() => {
		if (!loadingGetMusicPreference) {
			formik.setFieldValue("label", getMusicPreferenceData?.musicPreference?.label ? getMusicPreferenceData.musicPreference.label : "")
		}
	}, [loadingGetMusicPreference])
	let navigate = useNavigate()

	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editMusicPreferences")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditMusicPreference
