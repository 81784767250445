import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { useTranslation } from "react-i18next"
import RequestsChartHeader from "./RequestsChartHeader"
import { weeklyReport_weeklyReport_values } from "../__generated__/weeklyReport"
import { ArgumentAxis, ValueAxis, Chart, LineSeries } from "@devexpress/dx-react-chart-material-ui"

const UsersList = ({
	data,
	from,
	setFrom,
	to,
	setTo,
}: {
	data: weeklyReport_weeklyReport_values[]
	from: Date
	setFrom: Function
	to: Date
	setTo: Function
}) => {
	const { t, i18n } = useTranslation()
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<RequestsChartHeader from={from} to={to} setFrom={setFrom} setTo={setTo} />
			<Chart data={data}>
				<ArgumentAxis />
				<ValueAxis />

				<LineSeries valueField="bookingCount" argumentField="week" />
			</Chart>
		</Paper>
	)
}
export default UsersList
