import * as React from "react"
import { Paper, TextFieldProps, TextField, Stack, Typography, Button, MenuItem, Box } from "@mui/material"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DesktopDatePicker"
import { eventSeasonByDateGQL, assignEventSeasonToDateGQL } from "./eventSeasonGQL"
import { eventSeasonByDate, eventSeasonByDateVariables } from "./__generated__/eventSeasonByDate"
import { assignEventSeasonToDate, assignEventSeasonToDateVariables } from "./__generated__/assignEventSeasonToDate"
import { useLazyQuery, useMutation } from "@apollo/client"
import { formatDate } from "../shared/formatDate"
import { useTranslation } from "react-i18next"
import { EventSeason as EventSeasonInterface } from "../../__generated__/globalTypes"
import "./eventSeason.scss"
import { useFormik } from "formik"
import * as yup from "yup"

const EventSeason = () => {
	const { t, i18n } = useTranslation()
	const [dateValue, setValue] = React.useState<Date>(new Date())
	const validationSchema = yup.object({
		eventSeasonValue: yup
			.string()
			.required(`${t("selectEventSeason")} is required`)
			.max(100, `${t("selectEventSeason")} can not be longer than 100 characters`),
	})
	const formik = useFormik({
		initialValues: {
			eventSeasonValue: EventSeasonInterface.low,
		},
		validationSchema: validationSchema,
		onSubmit: async (values: { eventSeasonValue: EventSeasonInterface }) => {
			console.log(values)
			await assignEventSeasonToDateFunction({ variables: { date: formatDate(dateValue), season: values.eventSeasonValue } })
			setChangeEventSeason(false)
		},
	})
	const [getEventSeasonByDate, { loading: loadingEventSeasonData, data: getEventSeasonData }] = useLazyQuery<eventSeasonByDate, eventSeasonByDateVariables>(
		eventSeasonByDateGQL,
		{
			variables: { date: dateValue ? formatDate(dateValue) : "" },
		}
	)
	const [assignEventSeasonToDateFunction, { data, loading, error }] = useMutation<assignEventSeasonToDate, assignEventSeasonToDateVariables>(
		assignEventSeasonToDateGQL
	)
	const [changeEventSeason, setChangeEventSeason] = React.useState(false)
	const handleChange = (newValue: Date) => {
		setValue(newValue)
	}
	React.useEffect(() => {
		getEventSeasonByDate()
	}, [dateValue])
	React.useEffect(() => {
		if (!loadingEventSeasonData) {
			formik.setFieldValue("eventSeasonValue", getEventSeasonData?.eventSeasonByDate ? getEventSeasonData.eventSeasonByDate : EventSeasonInterface.low)
		}
	}, [loadingEventSeasonData])

	const changeEventSeasonHandler = () => {
		setChangeEventSeason(true)
	}

	return (
		<div className="div-table-wrapper margins">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<div className="title-header title-flex">
					<div className="title-move">
						<Typography variant="h4">{t("selectDate")}</Typography>
					</div>
					
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label="Date desktop"
							inputFormat="yyyy-MM-dd"
							value={dateValue}
							onChange={date => {
								date ? handleChange(date) : console.log(null)
							}}
							renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
				{changeEventSeason === false ? (
					<div className="season margins">
						<div className="seasonVertical">
							<Typography variant="h5">{t("eventSeason")}:</Typography>
							<Typography variant="h5">
								{formik.values.eventSeasonValue === null
									? t("noEventSeasonValue")
									: t(formik.values.eventSeasonValue === "low" ? "low" : "high")}
							</Typography>
						</div>
						<div className="change-season-btn-padding">
							<Button onClick={changeEventSeasonHandler} variant="outlined">
								{formik.values.eventSeasonValue === null ? t("addEventSeason") : t("changeEventSeason")}
							</Button>
						</div>
					</div>
				) : (
					<div className="season">
						<Box
							component="form"
							sx={{
								"& .MuiTextField-root": { m: 1, width: "25ch" },
							}}
							onSubmit={formik.handleSubmit}
						>
							<TextField
								fullWidth
								select
								id="eventSeasonValue"
								name="eventSeasonValue"
								label={t("selectEventSeason")}
								value={formik.values.eventSeasonValue}
								onChange={formik.handleChange}
								error={formik.touched.eventSeasonValue && Boolean(formik.errors.eventSeasonValue)}
								helperText={formik.touched.eventSeasonValue && formik.errors.eventSeasonValue}
								variant="outlined"
							>
								<MenuItem value={EventSeasonInterface.low}>{t(EventSeasonInterface.low)}</MenuItem>
								<MenuItem value={EventSeasonInterface.high}>{t(EventSeasonInterface.high)}</MenuItem>
							</TextField>
							<div className="create-user-button">
								<Button variant="outlined" type="submit">
									{t("submit")}
								</Button>
							</div>
						</Box>
					</div>
				)}
			</Paper>
		</div>
	)
}
export default EventSeason
