import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateEventOccasionGQL, EventOccasionGQL } from "../eventOccasionGQL"
import "../eventOccasion.scss"
import { updateEventOccasion as updateEventOccasionInterface, updateEventOccasionVariables } from "../__generated__/updateEventOccasion"
import { EventOccasion as EventOccasionInterface, EventOccasionVariables } from "../__generated__/EventOccasion"
import { useFormik } from "formik"
import * as yup from "yup"

const EditEventOccasion = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
		isMiscellaneous: yup.boolean().required(`${t("isMiscellaneous")} is required`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
			isMiscellaneous: true,
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const updateEventOccasionData = await updateEventOccasion({ variables: { id, ...values } })
			if (updateEventOccasionData?.data?.updateEventOccasion?.__typename) {
				navigate("/event-occasion")
			}
		},
	})
	const { id } = useParams()
	const [updateEventOccasion, { data, loading, error }] = useMutation<updateEventOccasionInterface, updateEventOccasionVariables>(updateEventOccasionGQL)
	const { loading: loadingGetEventOccasion, data: getEventOccasionData } = useQuery<EventOccasionInterface, EventOccasionVariables>(EventOccasionGQL, {
		variables: { id: `${id}` },
	})
	React.useEffect(() => {
		if (!loadingGetEventOccasion) {
			formik.setFieldValue("label", getEventOccasionData?.eventOccasion?.label ? getEventOccasionData.eventOccasion.label : "")
			formik.setFieldValue(
				"isMiscellaneous",
				getEventOccasionData?.eventOccasion?.isMiscellaneous ? getEventOccasionData.eventOccasion.isMiscellaneous : false
			)
		}
	}, [loadingGetEventOccasion])
	let navigate = useNavigate()

	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editEventOccasion")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "35ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>

							<Grid item xs={6}>
								<FormControl>
									<FormLabel>{t("isMiscellaneous")}</FormLabel>

									<Checkbox
										checked={formik.values.isMiscellaneous}
										id="isMiscellaneous"
										onChange={formik.handleChange}
										//setIsMiscellaneous(e.target.value === "true")
									/>
								</FormControl>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditEventOccasion
