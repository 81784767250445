import * as React from "react"
import {
	BoldExtension,
	ItalicExtension,
	HistoryExtension,
	HeadingExtension,
	HardBreakExtension,
	LinkExtension,
	BulletListExtension,
	OrderedListExtension,
	ListItemExtension,
	MentionAtomExtension,
} from "remirror/extensions"
// FontSizeExtension, yet to be decided
import { prosemirrorNodeToHtml } from "remirror"
import { EditorComponent, Remirror, useRemirror } from "@remirror/react"
import { Divider, Paper, OutlinedInput } from "@mui/material"
import { LiteralUnion } from "remirror"
import { Schema } from "prosemirror-model"
import FontStyles from "./FontStyles/FontStyles"
import History from "./History/History"
import Heading from "./Heading/Heading"
import { editorDocumentToText, editorDocumentForText } from "./EditorContent/EditorContent"
import MText from "./EditorContent/model/MText"
import List from "./Lists/Lists"
import { editorSchema } from "./EditorContent/EditorSchema"
import { SmallExtension } from "./CostumExtensions/SmallExtension"
import { SignatureExtension } from "./CostumExtensions/SignatureExtension/SignatureExtension"
import { VariableExtension } from "./CostumExtensions/VariableExtension/VariableExtension"
import ExtraExtensions from "./ExtraExtensions/ExtraExtensions"
import { useQuery } from "@apollo/client"
import { variablesGQL } from "./CostumExtensions/VariableExtension/VariablesGQL"
import "remirror/styles/all.css"
import "./Editor.scss"
const extensions = () => [
	new HardBreakExtension(),
	new BoldExtension(),
	new ItalicExtension(),
	new HistoryExtension(),
	new LinkExtension({ autoLink: true }),
	new HeadingExtension(),
	new BulletListExtension(),
	new OrderedListExtension(),
	new ListItemExtension(),
	new SmallExtension(),
	new SignatureExtension(),
	new VariableExtension(),
]

const Editor = ({
	content,
	setContent,
	isCreate,
	notEditable,
	Mtext,
}: {
	content: string
	setContent: Function
	isCreate?: boolean
	notEditable?: boolean
	Mtext?: any
}) => {
	const { manager, state, setState } = useRemirror({
		extensions,
	})
	const { loading: djCategoriesLoading, error: variablesError, data: variables } = useQuery(variablesGQL)
	if (content) {
		if (notEditable) {
			return (
				<div className="remirror-theme">
					<Remirror
						manager={manager}
						editable={false}
						onChange={parameter => {
							// Update the state to the latest value.
							setContent(JSON.stringify(editorDocumentToText(parameter.state.doc, parameter.state.schema).toJson()))
						}}
						initialContent={
							content
								? editorDocumentForText(MText.fromJson(JSON.parse(content)), manager.schema).toJSON()
								: { type: "doc", content: [{ type: "paragraph" }] }
						}
					>
						<EditorComponent />
					</Remirror>
				</div>
			)
		} else {
			return (
				<div className="remirror-theme">
					<Remirror
						manager={manager}
						onChange={parameter => {
							// Update the state to the latest value.
							setContent(JSON.stringify(editorDocumentToText(parameter.state.doc, parameter.state.schema).toJson()))
						}}
						initialContent={
							content
								? editorDocumentForText(MText.fromJson(JSON.parse(content)), manager.schema).toJSON()
								: { type: "doc", content: [{ type: "paragraph" }] }
						}
					>
						<Divider />
						<Paper>
							<div className="header-wrapper">
								<div className="header-flex">
									<History />
									<FontStyles />
									<Heading />
								</div>
								<div className="header-flex">
									<List />
									<ExtraExtensions />
								</div>
							</div>
							<Divider />
							<EditorComponent />
						</Paper>
					</Remirror>
				</div>
			)
		}
	} else if (isCreate) {
		return (
			<div className="remirror-theme">
				<Remirror
					manager={manager}
					onChange={parameter => {
						// Update the state to the latest value.
						setContent(JSON.stringify(editorDocumentToText(parameter.state.doc, parameter.state.schema).toJson()))
					}}
				>
					<Divider />
					<Paper>
						<div className="header-wrapper">
							<div className="header-flex">
								<History />
								<FontStyles />
								<Heading />
							</div>
							<div className="header-flex">
								<List />
								<ExtraExtensions />
							</div>
						</div>
						<Divider />
						<EditorComponent />
					</Paper>
				</Remirror>
			</div>
		)
	} else {
		if (notEditable) {
		
			return (
				<div className="remirror-theme">
					<Remirror
						manager={manager}
						editable={false}
						onChange={parameter => {
							// Update the state to the latest value.
							setContent(JSON.stringify(editorDocumentToText(parameter.state.doc, parameter.state.schema).toJson()))
						}}
						initialContent={
							Mtext
								? editorDocumentForText(Mtext, manager.schema).toJSON()
								: { type: "doc", content: [{ type: "paragraph" }] }
						}
					>
						<EditorComponent />
					</Remirror>
				</div>
			)
		}
		return <div></div>
	}
}
export default Editor
//const { insertParagraph } = useCommands()
