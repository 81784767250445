import { useTranslation } from "react-i18next"
import EventsByLocations from "./EventsByLocation/EventsByLocation"
import RecommendationsByLocations from "./RecommendationsByLocationReport/RecommendationsByLocation"
import BookingsCharts from "./BookingsChart/BookingsChart"
import RequestsCharts from "./RequestsChart/RequestsChart"
import "./dashboard.scss"

const Dashboard = () => {
	const { t, i18n } = useTranslation()
	return (
		<div className="layout-overview">
			<div className="layout-row">
				<EventsByLocations />
				<RecommendationsByLocations />
			</div>
			<div className="layout-row">
				<BookingsCharts />
				<RequestsCharts />
			</div>
		</div>
	)
}
export default Dashboard
