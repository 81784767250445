import { gql } from "@apollo/client"
export const eventOccasionsGQL = gql`
	query eventOccasions($includeArchived: Boolean) {
		eventOccasions(includeArchived: $includeArchived) {
			id
			label
			isMiscellaneous
			archivalTimestamp
		}
	}
`
export const createEventOccasionGQL = gql`
	mutation createEventOccasion($label: EventOccasionLabel!, $isMiscellaneous: Boolean!) {
		createEventOccasion(input: { label: $label, isMiscellaneous: $isMiscellaneous }) {
			__typename
		}
	}
`
export const EventOccasionGQL = gql`
	query EventOccasion($id: ID!) {
		eventOccasion(id: $id) {
			id
			label
			isMiscellaneous
		}
	}
`
export const updateEventOccasionGQL = gql`
	mutation updateEventOccasion($id: ID!, $label: EventOccasionLabel!, $isMiscellaneous: Boolean!) {
		updateEventOccasion(input: { id: $id, label: $label, isMiscellaneous: $isMiscellaneous }) {
			__typename
		}
	}
`
export const archiveEventOccasionGQL = gql`
	mutation archiveEventOccasion($id: ID!) {
		archiveEventOccasion(id: $id) {
			__typename
		}
	}
`

export const unarchiveEventOccasionGQL = gql`
	mutation unarchiveEventOccasion($id: ID!) {
		unarchiveEventOccasion(id: $id) {
			__typename
		}
	}
`