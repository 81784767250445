import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { weeklyReportGQL } from "../dashboardGQL"
import RequestsChartChart from "./RequestsChartChart"
import { weeklyReport, weeklyReportVariables } from "../__generated__/weeklyReport"
import { formatDate } from "../../shared/formatDate"

const RequestsCharts = () => {
	const [to, setTo] = useState(new Date())
	const [from, setFrom] = useState(new Date(to.getFullYear(), to.getMonth()-1, to.getDate()))
	const [getRequestsCharts, { loading: requestsChartsLoading, error: requestsChartsError, data: requestsCharts }] = useLazyQuery<
		weeklyReport,
		weeklyReportVariables
	>(weeklyReportGQL, {
		variables: {
			from: formatDate(from),
			to: formatDate(to),
		},
	})
	useEffect(() => {
		getRequestsCharts()
	}, [])

	useEffect(() => {
		getRequestsCharts()
	}, [from, to])

	return (
		<div className="div-table-wrapper" style={{ maxWidth: "650px" }}>
			{requestsCharts ? (
				<RequestsChartChart data={requestsCharts?.weeklyReport.values} from={from} to={to} setFrom={setFrom} setTo={setTo} />
			) : (
				<div></div>
			)}
		</div>
	)
}
export default RequestsCharts
