import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "../shared/ConfirmDialog"
import { documentTemplateBlocks_documentTemplateBlocks } from "./__generated__/documentTemplateBlocks"

const DocumentTemplateBlockList = ({ documentTemplateBlocks }: { documentTemplateBlocks?: documentTemplateBlocks_documentTemplateBlocks[] }) => {
	const { t, i18n } = useTranslation()
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("label")}</TableCell>
							<TableCell align="right">{t("code")}</TableCell>
							<TableCell align="right">{t("documentTypes")}</TableCell>
							<TableCell align="right">{t("salutation")}</TableCell>
							<TableCell align="right">{t("target")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{documentTemplateBlocks?.map((row: documentTemplateBlocks_documentTemplateBlocks) => (
							<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
								<TableCell component="th" scope="row">
									{row?.label}
								</TableCell>
								<TableCell align="right">{row?.code}</TableCell>
								<TableCell align="right">{row?.documentTypes}</TableCell>
								<TableCell align="right">{row?.salutation?.label}</TableCell>
								<TableCell align="right">{row?.target}</TableCell>

								<TableCell align="right">
									<div className="table-action-body">
										<Link component={RouterLink} to={`${row.id}`}>
											<EditOutlinedIcon />
										</Link>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
}
export default DocumentTemplateBlockList
