import * as React from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function ConfirmDialog({ open, openDialog, content, Complete }: { open: boolean; openDialog: Function; content: string; Complete: Function }) {
	const handleClose = () => {
		openDialog(false)
	}
	const completeFunc = () => {
		Complete()
		openDialog(false)
	}
	const { t, i18n } = useTranslation()
	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{t("actionDialog")}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{content}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("close")}</Button>
					<Button onClick={() => completeFunc()} autoFocus>
						{t("archive")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
