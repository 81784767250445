import React from "react"
import ReactDOM from "react-dom"
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./i18n"
import "./index.scss"

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API_URL,
	credentials: "include",
})
const client = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache(),
})
ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
