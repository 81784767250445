import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateProcessParticipationTagGQL, ProcessParticipationTagGQL } from "../processParticipationTagsGQL"
import {
	updateProcessParticipationTag as updateProcessParticipationTagInterface,
	updateProcessParticipationTagVariables,
} from "../__generated__/updateProcessParticipationTag"
import { ProcessParticipationTag, ProcessParticipationTagVariables } from "../__generated__/ProcessParticipationTag"
import { noLowerThan0 } from "../../shared/noLessThan0"
import { useFormik } from "formik"
import * as yup from "yup"

const EditProcessParticipationTag = () => {
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
		description: yup
			.string()
			.required(`${t("description")} is required`)
			.max(200, `${t("description")} can not be longer than 100 characters`),
		priority: yup
			.number()
			.min(0, `${t("priority")} can not be less than 0`)
			.required(`${t("priority")} is required`),
		limitPerProcess: yup.number().min(0, `${t("limitPerProcess")} can not be less than 0`),
		isPrimary: yup.boolean().required(`${t("isPrimary")} is required`),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
			description: "",
			priority: 0,
			limitPerProcess: undefined,
			isPrimary: false,
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const updateProcessParticipationTagData = await updateProcessParticipationTag({ variables: { id: id, ...values } })
			if (updateProcessParticipationTagData?.data?.updateProcessParticipationTag?.__typename) {
				navigate("/process-participation-tags")
			}
		},
	})
	const [updateProcessParticipationTag, { data, loading, error }] = useMutation<
		updateProcessParticipationTagInterface,
		updateProcessParticipationTagVariables
	>(updateProcessParticipationTagGQL)
	const { loading: loadingGetProcessParticipationTag, data: getProcessParticipationTagData } = useQuery<
		ProcessParticipationTag,
		ProcessParticipationTagVariables
	>(ProcessParticipationTagGQL, {
		variables: { id: `${id}` },
	})
	React.useEffect(() => {
		if (!loadingGetProcessParticipationTag) {
			formik.setFieldValue(
				"label",
				getProcessParticipationTagData?.processParticipationTag?.label ? getProcessParticipationTagData.processParticipationTag.label : ""
			)
			formik.setFieldValue(
				"description",
				getProcessParticipationTagData?.processParticipationTag?.description ? getProcessParticipationTagData.processParticipationTag.description : ""
			)
			formik.setFieldValue(
				"priority",
				getProcessParticipationTagData?.processParticipationTag?.priority ? getProcessParticipationTagData.processParticipationTag.priority : 0
			)
			if (getProcessParticipationTagData?.processParticipationTag?.limitPerProcess) {
				formik.setFieldValue("limitPerProcess", getProcessParticipationTagData.processParticipationTag.limitPerProcess)
			}
			formik.setFieldValue(
				"isPrimary",
				getProcessParticipationTagData?.processParticipationTag?.isPrimary ? getProcessParticipationTagData.processParticipationTag.isPrimary : false
			)
		}
	}, [loadingGetProcessParticipationTag])
	let navigate = useNavigate()

	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editProcessParticipationTags")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "35ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="description"
									name="description"
									label={t("description")}
									value={formik.values.description}
									onChange={formik.handleChange}
									error={formik.touched.description && Boolean(formik.errors.description)}
									helperText={formik.touched.description && formik.errors.description}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="priority"
									name="priority"
									label={t("priority")}
									type="number"
									value={formik.values.priority}
									onChange={formik.handleChange}
									error={formik.touched.priority && Boolean(formik.errors.priority)}
									helperText={formik.touched.priority && formik.errors.priority}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="limitPerProcess"
									name="limitPerProcess"
									label={t("limitPerProcess")}
									type="number"
									value={formik.values.limitPerProcess}
									onChange={formik.handleChange}
									error={formik.touched.limitPerProcess && Boolean(formik.errors.limitPerProcess)}
									helperText={formik.touched.limitPerProcess && formik.errors.limitPerProcess}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControl>
									<FormLabel>{t("isPrimary")}</FormLabel>
									<Checkbox checked={formik.values.isPrimary} id="isPrimary" onChange={formik.handleChange} />
								</FormControl>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditProcessParticipationTag
