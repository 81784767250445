import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import { Button, SvgIcon } from "@mui/material"

const SmallButton = () => {
	const chain = useChainedCommands()
	const active = useActive()
	const commands = useCommands()

	return (
		<Button
			onClick={ () => {
							commands.toggleSmall()
							commands.focus()
					  }
			}
			style={{
				backgroundColor: active.small() ? "rgb(25, 118, 210)" : undefined,
				color: active.small() ? "rgb(255, 255, 255)" : undefined,
				borderColor: active.small() ? "rgb(255, 255, 255)" : undefined,
			}}
			variant="outlined"
			disabled={commands.toggleSmall.enabled() === false}
		>
			<SvgIcon component={"div"} className="heading-small-flex">
				{"Small"}
			</SvgIcon>
		</Button>
	)
}
export default SmallButton
