import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateProcessEmailTemplateGQL, ProcessEmailTemplateGQL } from "../processEmailTemplatesGQL"
import {
	updateProcessEmailTemplate as updateProcessEmailTemplateInterface,
	updateProcessEmailTemplateVariables,
} from "../__generated__/updateProcessEmailTemplate"
import { ProcessEmailTemplate, ProcessEmailTemplateVariables } from "../__generated__/ProcessEmailTemplate"
import { useFormik } from "formik"
import * as yup from "yup"

const EditProcessEmailTemplate = () => {
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const validationSchema = yup.object({
		subject: yup
			.string()
			.required(`${t("subject")} is required`)
			.max(30, `${t("subject")} can not be longer than 30 characters`),
		message: yup
			.string()
			.required(`${t("message")} is required`)
			.max(150, `${t("message")} can not be longer than 150 characters`),
	})
	const formik = useFormik({
		initialValues: {
			subject: "",
			message: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const updateProcessEmailTemplateData = await updateProcessEmailTemplate({ variables: { id, ...values } })
			if (updateProcessEmailTemplateData?.data?.updateProcessEmailTemplate?.__typename) {
				navigate("/process-templates")
			}
		},
	})
	const [updateProcessEmailTemplate, { data, loading, error }] = useMutation<updateProcessEmailTemplateInterface, updateProcessEmailTemplateVariables>(
		updateProcessEmailTemplateGQL
	)
	const { loading: loadingGetProcessEmailTemplate, data: getProcessEmailTemplateData } = useQuery<ProcessEmailTemplate, ProcessEmailTemplateVariables>(
		ProcessEmailTemplateGQL,
		{
			variables: { id: `${id}` },
		}
	)
	React.useEffect(() => {
		if (!loadingGetProcessEmailTemplate) {
			formik.setFieldValue("subject", getProcessEmailTemplateData?.processEmailTemplate?.subject ? getProcessEmailTemplateData.processEmailTemplate.subject : "")
			formik.setFieldValue("message", getProcessEmailTemplateData?.processEmailTemplate?.message ? getProcessEmailTemplateData.processEmailTemplate.message : "")
		}
	}, [loadingGetProcessEmailTemplate])
	let navigate = useNavigate()

	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editProcessEmailTemplates")}</Typography>
			</div>
			<div className="container-centering">
			<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "35ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="subject"
									name="subject"
									label={t("subject")}
									value={formik.values.subject}
									onChange={formik.handleChange}
									error={formik.touched.subject && Boolean(formik.errors.subject)}
									helperText={formik.touched.subject && formik.errors.subject}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									multiline
									id="message"
									name="message"
									label={t("message")}
									value={formik.values.message}
									onChange={formik.handleChange}
									error={formik.touched.message && Boolean(formik.errors.message)}
									helperText={formik.touched.message && formik.errors.message}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditProcessEmailTemplate
