import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js/min"

export function formatInGermany(string: string | null) {
	const value = parsePhoneNumberFromString(string ? string : "", "DE")

	if (value !== undefined) {
		if (value.country === "DE") {
			// return formatNational(value)
			return formatInternational(value)
		} else {
			return formatInternational(value)
		}
	}
	return ""
}
function formatInternational(value: PhoneNumber) {
	return value.formatInternational()
}

function formatNational(value: PhoneNumber) {
	return value.formatNational()
}
