import React from "react"
import { UploadContext } from "@remirror/core"
import { NodeViewComponentProps } from "@remirror/react"


export type SignatureComponentProps = NodeViewComponentProps & {
	context?: UploadContext
	abort: () => void
}

export const SignatureComponent: React.FC<SignatureComponentProps> = ({ node }) => {
	return (
		<div className="signature-flex">
			<img className="signature-flex" src={"https://placekitten.com/200/300"}></img>
		</div>
	)
}
