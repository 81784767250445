import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import DjCategoriesList from "./DjCategoriesList"
import { djCategoriesGQL, archiveDjCategoryGQL, unarchiveDjCategoryGQL } from "./djCategoryGQL"
import { djCategories as djCategoriesInterface } from "./__generated__/djCategories"
import { archiveDjCategoryVariables, archiveDjCategory } from "./__generated__/archiveDjCategory"
import { unarchiveDjCategory, unarchiveDjCategoryVariables } from "./__generated__/unarchiveDjCategory"
import ListHeader from "../shared/TableHeader"

const DjCategories = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("djCategories")
	const [getDjCategories, { loading: djCategoriesLoading, error: djCategoriesError, data: djCategories }] = useLazyQuery<djCategoriesInterface>(
		djCategoriesGQL,
		{
			fetchPolicy: "network-only", // Doesn't check cache before making a network request
			variables: { includeArchived: true },
		}
	)
	React.useEffect(() => {
		getDjCategories()
	}, [])
	const [archiveDjCategoryClick, { data, loading, error }] = useMutation<archiveDjCategory, archiveDjCategoryVariables>(archiveDjCategoryGQL)
	const [unarchiveDjCategoryClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveDjCategory,
		unarchiveDjCategoryVariables
	>(unarchiveDjCategoryGQL)
	const archive = async (id: string) => {
		const archiveDjCategoryData = archiveDjCategoryClick({
			variables: { id: id },
		})
		if ((await archiveDjCategoryData)?.data?.archiveDjCategory?.__typename ? true : false) {
			getDjCategories()
		}
	}
	const unarchive = async (id: string) => {
		const unarchiveDjCategoryData = unarchiveDjCategoryClick({
			variables: { id: id },
		})
		if ((await unarchiveDjCategoryData)?.data?.unarchiveDjCategory?.__typename ? true : false) {
			getDjCategories()
		}
	}
	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<DjCategoriesList djCategories={djCategories?.djCategories} archive={archive} unarchive={unarchive} checked={checked}/>
			</Paper>
		</div>
	)
}
export default DjCategories
