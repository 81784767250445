import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js"
export function onlyPhoneNumber(numberInput: string) {
	const value = parsePhoneNumberFromString(numberInput ? numberInput : "", "DE")
	if (value !== undefined) {
		if (value.country === "DE") {
			// return formatNational(value)
			return formatInternational(numberInput)
		} else {
			return formatInternational(numberInput)
		}
	}
	return new AsYouType("DE").input(numberInput)
}
function formatInternational(value: string) {
	return new AsYouType().input(value)
}

function formatNational(value: string) {
	return new AsYouType("DE").input(value)
}
