import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import EquipmentsList from "./EquipmentsList"
import { equipmentsGQL, archiveEquipmentGQL, unarchiveEquipmentGQL } from "./equipmentsGQL"
import { equipments as equipmentsInterface } from "./__generated__/equipments"
import { archiveEquipmentVariables, archiveEquipment } from "./__generated__/archiveEquipment"
import "./equipments.scss"
import { unarchiveEquipment } from "./__generated__/unarchiveEquipment"
import ListHeader from "../shared/TableHeader"

const Equipments = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("equipments")
	const [getEquipments, { loading: equipmentsLoading, error: equipmentsError, data: equipments }] = useLazyQuery<equipmentsInterface>(equipmentsGQL, {
		fetchPolicy: "network-only", // Doesn't check cache before making a network request
		variables: { includeArchived: true },
	})
	const [archiveEquipmentClick, { data, loading, error }] = useMutation<archiveEquipment, archiveEquipmentVariables>(archiveEquipmentGQL)
	const [unarchiveEquipmentClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveEquipment,
		archiveEquipmentVariables
	>(unarchiveEquipmentGQL)
	React.useEffect(() => {
		getEquipments()
	}, [])

	const archive = async (id: string) => {
		const archiveEquipmentData = archiveEquipmentClick({
			variables: { id: id },
		})
		if ((await archiveEquipmentData)?.data?.archiveEquipment?.__typename ? true : false) {
			getEquipments()
		}
	}
	const unarchive = async (id: string) => {
		const unarchiveEquipmentData = unarchiveEquipmentClick({
			variables: { id: id },
		})
		if ((await unarchiveEquipmentData)?.data?.unarchiveEquipment?.__typename ? true : false) {
			getEquipments()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<EquipmentsList equipments={equipments?.equipments} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}

export default Equipments
