import { gql } from "@apollo/client"

export const processParticipationTagsGQL = gql`
	query processParticipationTags($includeArchived: Boolean) {
		processParticipationTags(includeArchived: $includeArchived) {
			id
			label
			description
			priority
			limitPerProcess
			isPrimary
			archivalTimestamp
		}
	}
`
export const createProcessParticipationTagGQL = gql`
	mutation createProcessParticipationTag($label: TagLabel!, $description: Description!, $priority: Int!, $limitPerProcess: Int = null, $isPrimary: Boolean!) {
		createProcessParticipationTag(
			input: { label: $label, description: $description, priority: $priority, limitPerProcess: $limitPerProcess, isPrimary: $isPrimary }
		) {
			__typename
		}
	}
`
export const ProcessParticipationTagGQL = gql`
	query ProcessParticipationTag($id: ID!) {
		processParticipationTag(id: $id) {
			id
			label
			description
			priority
			limitPerProcess
			isPrimary
		}
	}
`
export const updateProcessParticipationTagGQL = gql`
	mutation updateProcessParticipationTag(
		$id: ID!
		$label: TagLabel!
		$description: Description!
		$priority: Int!
		$limitPerProcess: Int = null
		$isPrimary: Boolean!
	) {
		updateProcessParticipationTag(
			input: { id: $id, label: $label, description: $description, priority: $priority, limitPerProcess: $limitPerProcess, isPrimary: $isPrimary }
		) {
			__typename
		}
	}
`
export const archiveProcessParticipationTagGQL = gql`
	mutation archiveProcessParticipationTag($id: ID!) {
		archiveProcessParticipationTag(id: $id) {
			__typename
		}
	}
`

export const unarchiveProcessParticipationTagGQL = gql`
	mutation unarchiveProcessParticipationTag($id: ID!) {
		unarchiveProcessParticipationTag(id: $id) {
			__typename
		}
	}
`
