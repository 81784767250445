import { Box, ButtonGroup } from "@mui/material"
import BoldButton from "./BoldButton"
import ItalicButton from "./ItalicButton"
import SmallButton from "./SmallButton"
const FontStyles = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				"& > *": {
					m: 1,
				},
			}}
		>
			<ButtonGroup variant="outlined" aria-label="outlined button group">
				<BoldButton />
                <ItalicButton/>
				<SmallButton/>
			</ButtonGroup>
		</Box>
	)
}
export default FontStyles
