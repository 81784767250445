import { gql } from "@apollo/client"

export const djCategoriesGQL = gql`
	query djCategories($includeArchived:Boolean){
		djCategories (includeArchived:$includeArchived){
			compensationPerHourOfCommute
			compensationPerOvertimeUnit
			compensationInHighSeason
			compensationInLowSeason
			id
			label
			priceExcludingVatInHighSeason
			priceExcludingVatInLowSeason
			pricePerOvertimeUnit
			archivalTimestamp
		}
	}
`
export const createDJCategoryGQL = gql`
	mutation createDjCategory(
		$label: DjCategoryLabel!
		$priceExcludingVatInHighSeason: Cents!
		$priceExcludingVatInLowSeason: Cents!
		$pricePerOvertimeUnit: Cents!
		$compensationInHighSeason: Cents!
		$compensationInLowSeason: Cents!
		$compensationPerHourOfCommute: Cents!
		$compensationPerOvertimeUnit: Cents!
	) {
		createDjCategory(
			input: {
				label: $label
				priceExcludingVatInHighSeason: $priceExcludingVatInHighSeason
				priceExcludingVatInLowSeason: $priceExcludingVatInLowSeason
				pricePerOvertimeUnit: $pricePerOvertimeUnit
				compensationInHighSeason: $compensationInHighSeason
				compensationInLowSeason: $compensationInLowSeason
				compensationPerHourOfCommute: $compensationPerHourOfCommute
				compensationPerOvertimeUnit: $compensationPerOvertimeUnit
			}
		) {
			__typename
		}
	}
`
export const djCategoryGQL = gql`
	query djCategory($id: ID!) {
		djCategory(id: $id) {
			compensationPerHourOfCommute
			compensationPerOvertimeUnit
			compensationInHighSeason
			compensationInLowSeason
			id
			label
			priceExcludingVatInHighSeason
			priceExcludingVatInLowSeason
			pricePerOvertimeUnit
		}
	}
`
export const updateDjCategoryGQL = gql`
	mutation updateDjCategory(
		$id: ID!
		$label: DjCategoryLabel!
		$priceExcludingVatInHighSeason: Cents!
		$priceExcludingVatInLowSeason: Cents!
		$pricePerOvertimeUnit: Cents!
		$compensationInHighSeason: Cents!
		$compensationInLowSeason: Cents!
		$compensationPerHourOfCommute: Cents!
		$compensationPerOvertimeUnit: Cents!
	) {
		updateDjCategory(
			input: {
				id: $id
				label: $label
				priceExcludingVatInHighSeason: $priceExcludingVatInHighSeason
				priceExcludingVatInLowSeason: $priceExcludingVatInLowSeason
				pricePerOvertimeUnit: $pricePerOvertimeUnit
				compensationInHighSeason: $compensationInHighSeason
				compensationInLowSeason: $compensationInLowSeason
				compensationPerHourOfCommute: $compensationPerHourOfCommute
				compensationPerOvertimeUnit: $compensationPerOvertimeUnit
			}
		) {
			__typename
		}
	}
`
export const archiveDjCategoryGQL = gql`
	mutation archiveDjCategory($id: ID!) {
		archiveDjCategory(id: $id) {
			__typename
		}
	}
`

export const unarchiveDjCategoryGQL = gql`
	mutation unarchiveDjCategory($id: ID!) {
		unarchiveDjCategory(id: $id) {
			__typename
		}
	}
`
