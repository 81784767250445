import * as React from "react"
import { InputAdornment, TextField } from "@mui/material"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { TextFieldProps } from "@mui/material"

const PasswordTextField = (props: TextFieldProps) => {
	const [textFieldType, setTextFieldType] = React.useState("password")
	const textFieldTypeChange = () => {
		if (textFieldType === "text") {
			setTextFieldType("password")
		}
		if (textFieldType === "password") {
			setTextFieldType("text")
		}
	}
	const Icon = (props: any) => {
		if (textFieldType === "text") {
			return <VisibilityOutlinedIcon {...props} />
		}
		if (textFieldType === "password") {
			return <VisibilityOffOutlinedIcon {...props} />
		}
		return <VisibilityOffOutlinedIcon {...props} />
	}
	return (
		<div>
			<TextField
				{...props}
				type={textFieldType}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<Icon onClick={textFieldTypeChange} />
						</InputAdornment>
					),
				}}
			/>
		</div>
	)
}
export default PasswordTextField
