import { gql } from "@apollo/client"
export const weddingFairsGQL = gql`
	query weddingFairs($includeArchived: Boolean) {
		weddingFairs(includeArchived: $includeArchived) {
			id
			name
			archivalTimestamp
		}
	}
`
export const createWeddingFairGQL = gql`
	mutation createWeddingFair($name: WeddingFairName!) {
		createWeddingFair(input: { name: $name }) {
			__typename
		}
	}
`
export const WeddingFairGQL = gql`
	query weddingFair($id: ID!) {
		weddingFair(id: $id) {
			id
			name
		}
	}
`
export const updateWeddingFairGQL = gql`
	mutation updateWeddingFair($id: ID!, $name: WeddingFairName!) {
		updateWeddingFair(input: { id: $id, name: $name }) {
			__typename
		}
	}
`
export const archiveWeddingFairGQL = gql`
	mutation archiveWeddingFair($id: ID!) {
		archiveWeddingFair(id: $id) {
			__typename
		}
	}
`

export const unarchiveWeddingFairGQL = gql`
	mutation unarchiveWeddingFair($id: ID!) {
		unarchiveWeddingFair(id: $id) {
			__typename
		}
	}
`