import { gql } from "@apollo/client"
export const magazinesGQL = gql`
	query magazines($includeArchived: Boolean) {
		magazines(includeArchived: $includeArchived) {
			id
			name
			archivalTimestamp
		}
	}
`
export const createMagazineGQL = gql`
	mutation createMagazine($name: MagazineName!) {
		createMagazine(input: { name: $name }) {
			__typename
		}
	}
`
export const MagazineGQL = gql`
	query Magazine($id: ID!) {
		magazine(id: $id) {
			id
			name
		}
	}
`
export const updateMagazineGQL = gql`
	mutation updateMagazine($id: ID!, $name: MagazineName!) {
		updateMagazine(input: { id: $id, name: $name }) {
			__typename
		}
	}
`
export const archiveMagazineGQL = gql`
	mutation archiveMagazine($id: ID!) {
		archiveMagazine(id: $id) {
			__typename
		}
	}
`

export const unarchiveMagazineGQL = gql`
	mutation unarchiveMagazine($id: ID!) {
		unarchiveMagazine(id: $id) {
			__typename
		}
	}
`
