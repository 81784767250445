import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const defaultNS = "translation"
export const resources = {
	du: {
		translation: {
			mmpt: "MMPT Kundendatenbank",
			registration: "Anmeldung",
			emailAdress: "Email-Adresse",
			password: "Passwort",
			register: "Anmelden",
			hello: "Hallo",
			logOff: "abmelden",
			dashboard: "Dashboard",
			users: "Users",
			firstName: "First Name",
			lastName: "Last Name",
			actions: "Actions",
			createUser: "Create User",
			phoneNumberAmount: "Select amount of phone numbers",
			phoneNumber: "Phone Number",
			submit: "Submit",
			editUser: "Edit User",
			dj: "Dj",
			djCategories: "DJ Categories",
			djs: "DJs",
			label: "Label",
			priceExcludingVatInHighSeason: "Price excluding vat in high season",
			priceExcludingVatInLowSeason: "Price excluding vat in low season",
			pricePerOvertimeUnit: "Price per overtime unit",
			compensationPerHourOfCommute: "Compensation per hour of commute",
			compensationPerOvertimeUnit: "Compensation per overtime unit",
			compensationInHighSeason: "Compensation in high season",
			compensationInLowSeason: "Compensation in low season",
			createDjCategory: "Create DJ Category",
			editDjCategory: "Edit DJ Category",
			close: "Close",
			delete: "Delete",
			actionDialog: "Action Dialog",
			alias: "Alias",
			businessPhoneNumber: "Business Phone Number",
			homePhoneNumber: "Home Phone Number",
			mobilePhoneNumber: "Mobile Phone Number",
			remarks: "Remarks",
			djCategory: "DJ Category",
			createDj: "Create DJ",
			editDj: "Edit DJ",
			magazines: "Magazines",
			createMagazine: "Create Magazine",
			editMagazine: "Edit Magazine",
			magazine: "Magazine",
			name: "Name",
			weddingFairs: "Wedding Fairs",
			createWeddingFairs: "Create Wedding Fairs",
			editWeddingFairs: "Edit Wedding Fairs",
			referrals: "Referrals",
			musicPreferences: "Music Preferences",
			createMusicPreferences: "Create Music Preference",
			editMusicPreferences: "Edit Music Preferences",
			events: "Events",
			eventConsultations: "Event Consultations",
			createEventConsultations: "Create Event Consultations",
			editEventConsultations: "Edit Event Consultations",
			eventOccasion: "Event Occasion",
			createEventOccasion: "Create Event Occasion",
			editEventOccasion: "Edit Event Occasion",
			isMiscellaneous: "Miscellaneous",
			processTags: "Process Tags",
			createProcessTags: "Create Process Tags",
			editProcessTags: "Edit Process Tags",
			process: "Process",
			equipments: "Equipments",
			createEquipments: "Create Equipment",
			editEquipments: "Edit Equipment",
			type: "Type",
			priceExcludingVat: "Price Excluding VAT",
			processEmailTemplates: "Process Email Templates",
			createProcessEmailTemplates: "Create Process Email Templates",
			editProcessEmailTemplates: "Edit Process Email Templates",
			message: "Message",
			subject: "Subject",
			description: "Description",
			priority: "Priority",
			limitPerProcess: "Limit Per Process",
			isPrimary: "Primary",
			processParticipationTags: "Process Participation Tags",
			createProcessParticipationTags: "Create Process Participation Tags",
			editProcessParticipationTags: "Edit Process Participation Tags",
			eventSeason: "Event Season",
			low: "Low",
			high: "High",
			selectDate: "Select Date",
			noEventSeasonValue: "There is no event season value for this date",
			addEventSeason: "Add an event season to date",
			changeEventSeason: "Change event season for this date",
			selectEventSeason: "Select Event Season",
			noChanges: "No changes",
			documentTemplateBlocks: "Document Template Blocks",
			code: "Code",
			documentTypes: "Document Types",
			salutation: "Salutation",
			target: "Target",
			createDocumentTemplateBlocks: "Create Document Template Blocks",
			editDocumentTemplateBlocks: "Edit Document Template Blocks",
			selectVariable: "Please select the variable",
			variable: "Variable",
			editSalutations: "Edit Salutations",
			createSalutations: "Create Salutations",
			salutations: "Salutations ",
			eventCount:"Count",
			location:"Location",
			eventsLocation:"Events by location",
			from:"From",
			to:"To",
			recommendation:"Recommendation",
			recommendationsLocation:"Recommendations by location",
			bookingsChart:"Bookings per week",
			requestsChart:"Requests per week",
			editDocument:"Edit Document",
			archive:"Archive",
			recipient:"Recipient",
			contract:"New Contract",
			proposal:"New Proposal",
			farewell:"Farewell",
			greeting:"Greeting",
			postscript:"Postscript",
			preamble:"Preamble",
			bookingConfirmation:"Booking Confirmation",
			offer:"Offer",
			regarding:"Regarding",
			showArchived:"Show Archived",
			addressPreview:"Address Preview"
		},
	},
} as const

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "du", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option
		ns: ["translation"],
		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ",",
			format(value, format) {
				if (format === "uppercase") return value.toUpperCase()
				return value
			},
		},
	})

export default i18n
