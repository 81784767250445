import { gql } from "@apollo/client"

export const equipmentsGQL = gql`
	query equipments($includeArchived: Boolean) {
		equipments(includeArchived: $includeArchived) {
			id
			label
			priceExcludingVat
			type
			archivalTimestamp
		}
	}
`
export const createEquipmentGQL = gql`
	mutation createEquipment($label: EquipmentLabel!, $priceExcludingVat: Cents!, $type: EquipmentType!) {
		createEquipment(input: { label: $label, priceExcludingVat: $priceExcludingVat, type: $type }) {
			__typename
		}
	}
`
export const EquipmentGQL = gql`
	query Equipment($id: ID!) {
		equipment(id: $id) {
			id
			label
			priceExcludingVat
			type
		}
	}
`
export const updateEquipmentGQL = gql`
	mutation updateEquipment($id: ID!, $label: EquipmentLabel!, $priceExcludingVat: Cents!, $type: EquipmentType!) {
		updateEquipment(input: { id: $id, label: $label, priceExcludingVat: $priceExcludingVat, type: $type }) {
			__typename
		}
	}
`
export const archiveEquipmentGQL = gql`
	mutation archiveEquipment($id: ID!) {
		archiveEquipment(id: $id) {
			__typename
		}
	}
`

export const unarchiveEquipmentGQL = gql`
	mutation unarchiveEquipment($id: ID!) {
		unarchiveEquipment(id: $id) {
			__typename
		}
	}
`
