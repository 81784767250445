import { gql } from "@apollo/client"

export const DocumentGQL = gql`
	query document($id: ID!) {
		document(id: $id) {
			archivalTimestamp
			content
			creationTimestamp
			id
			modificationTimestamp
			name
			recipientAddress
			recipient {
				id
			}
			state
			type
		}
	}
`

export const updateDocumentGQL = gql`
	mutation updateDocument($content: Text!, $id: ID!, $name: DocumentName!, $recipientAddress: InlineText, $recipientId: ID) {
		updateDocument(input: { content: $content, id: $id, name: $name, recipientAddress: $recipientAddress, recipientId: $recipientId }) {
			__typename
		}
	}
`
