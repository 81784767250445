import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem, SelectChangeEvent } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateDocumentTemplateBlockGQL, DocumentTemplateBlockGQL } from "../documentTemplateBlocksGQL"
import {
	updateDocumentTemplateBlock as updateDocumentTemplateBlockInterface,
	updateDocumentTemplateBlockVariables,
} from "../__generated__/updateDocumentTemplateBlock"
import { DocumentTemplateBlock, DocumentTemplateBlockVariables } from "../__generated__/DocumentTemplateBlock"
import { DocumentTemplateBlockTarget, DocumentType } from "../../../__generated__/globalTypes"
import MyEditor from "../../shared/Editor/Editor"
import { emptyStringIsNull } from "../../shared/emptyStringIsNull"
import { salutationsGQL } from "../../Salutations/salutationsGQL"
import { salutations as salutationsInterface, salutations_salutations } from "../../Salutations/__generated__/salutations"
import { useFormik } from "formik"
import * as yup from "yup"

const EditDocumentTemplateBlock = () => {
	let navigate = useNavigate()
	const { id } = useParams()
	const { t, i18n } = useTranslation()
	const [content, setContent] = React.useState<any>(null)
	const [initialContent, setInitialContent] = React.useState<any>(null)
	const validationSchema = yup.object({
		label: yup
			.string()
			.required(`${t("label")} is required`)
			.max(100, `${t("label")} can not be longer than 100 characters`),
		target: yup.string(),
		documentTypes: yup
			.array()
			.required(`${t("documentTypes")} is required`)
			.min(1, `${t("documentTypes")} Should have atleast one item`),
		code: yup.string(),
		salutationId: yup.string(),
	})
	const formik = useFormik({
		initialValues: {
			label: "",
			target: undefined,
			documentTypes: [],
			code: undefined,
			salutationId: undefined,
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const editDocumentTemplateBlockData = await editDocumentTemplateBlock({ variables: { id: id, content: content, ...values } })
			if (editDocumentTemplateBlockData?.data?.updateDocumentTemplateBlock?.__typename) {
				navigate("/document-template-blocks")
			}
		},
	})
	const {
		loading: documentTemplateBlockLoading,
		error: documentTemplateBlockError,
		data: documentTemplateBlock,
	} = useQuery<DocumentTemplateBlock, DocumentTemplateBlockVariables>(DocumentTemplateBlockGQL, {
		variables: { id: id ? id : "" },
	})
	const [salutation, setSalutation] = React.useState<string>("")
	const { loading: salutationsLoading, error: salutationsError, data: salutations } = useQuery<salutationsInterface>(salutationsGQL)

	React.useEffect(() => {
		if (!documentTemplateBlockLoading) {
			formik.setFieldValue("label", documentTemplateBlock?.documentTemplateBlock?.label ? documentTemplateBlock.documentTemplateBlock.label : "")
			formik.setFieldValue(
				"documentTypes",
				documentTemplateBlock?.documentTemplateBlock?.documentTypes ? documentTemplateBlock.documentTemplateBlock.documentTypes : []
			)

			if (documentTemplateBlock?.documentTemplateBlock?.code) {
				formik.setFieldValue("code", documentTemplateBlock.documentTemplateBlock.code)
			}

			if (documentTemplateBlock?.documentTemplateBlock?.target) {
				formik.setFieldValue("target", documentTemplateBlock.documentTemplateBlock.target)
			}

			if (documentTemplateBlock?.documentTemplateBlock?.salutation?.id) {
				formik.setFieldValue("salutationId", documentTemplateBlock.documentTemplateBlock.salutation.id)
			}

			setInitialContent(documentTemplateBlock?.documentTemplateBlock?.content ? documentTemplateBlock.documentTemplateBlock.content : null)
		}
	}, [documentTemplateBlockLoading])
	const [editDocumentTemplateBlock, { data, loading, error }] = useMutation(updateDocumentTemplateBlockGQL)
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editDocumentTemplateBlocks")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="label"
									name="label"
									label={t("label")}
									value={formik.values.label}
									onChange={formik.handleChange}
									error={formik.touched.label && Boolean(formik.errors.label)}
									helperText={formik.touched.label && formik.errors.label}
									variant="outlined"
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									fullWidth
									select
									id="target"
									name="target"
									label={t("target")}
									value={formik.values.target}
									onChange={formik.handleChange}
									error={formik.touched.target && Boolean(formik.errors.target)}
									helperText={formik.touched.target && formik.errors.target}
									variant="outlined"
								>
									<MenuItem value={undefined}>No Value</MenuItem>
									{Object.values(DocumentTemplateBlockTarget).map((category: DocumentTemplateBlockTarget, index: number) => {
										return (
											<MenuItem key={index} value={category}>
												{String(category)}
											</MenuItem>
										)
									})}
								</TextField>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									select
									id="documentTypes"
									name="documentTypes"
									label={t("documentTypes")}
									value={formik.values.documentTypes}
									onChange={formik.handleChange}
									error={formik.touched.documentTypes && Boolean(formik.errors.documentTypes)}
									helperText={formik.touched.documentTypes && formik.errors.documentTypes}
									variant="outlined"
									SelectProps={{
										multiple: true,
									}}
								>
									{Object.values(DocumentType).map((category: DocumentType, index: number) => {
										return (
											<MenuItem key={index} value={category}>
												{String(category)}
											</MenuItem>
										)
									})}
								</TextField>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="code"
									name="code"
									label={t("code")}
									value={formik.values.code}
									onChange={formik.handleChange}
									error={formik.touched.code && Boolean(formik.errors.code)}
									helperText={formik.touched.code && formik.errors.code}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									select
									id="salutationId"
									name="salutationId"
									label={t("salutations")}
									value={formik.values.salutationId}
									onChange={formik.handleChange}
									error={formik.touched.salutationId && Boolean(formik.errors.salutationId)}
									helperText={formik.touched.salutationId && formik.errors.salutationId}
									variant="outlined"
								>
									<MenuItem value={undefined}>No Value</MenuItem>
									{salutations?.salutations ? (
										salutations?.salutations.map((category: salutations_salutations, index: number) => {
											return (
												<MenuItem key={index} value={category.id}>
													{String(category.label)}
												</MenuItem>
											)
										})
									) : (
										<MenuItem value={""}>No Value</MenuItem>
									)}
								</TextField>
							</Grid>

							<Grid item xs={12}>
								{!documentTemplateBlockLoading && initialContent !== null ? (
									<MyEditor content={initialContent} setContent={setContent} />
								) : (
									<div></div>
								)}
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditDocumentTemplateBlock
