import { gql } from "@apollo/client"

export const documentTemplateBlocksGQL = gql`
	query documentTemplateBlocks($documentType: DocumentType = null) {
		documentTemplateBlocks(documentType: $documentType) {
			code
			content
			documentTypes
			id
			label
			salutation {
				id
				label
			}
			target
		}
	}
`
export const createDocumentTemplateBlockGQL = gql`
	mutation createDocumentTemplateBlock(
		$code: DocumentTemplateBlockCode = null
		$content: Text!
		$documentTypes: [DocumentType!]!
		$label: DocumentTemplateBlockLabel!
		$salutationId: ID = null
		$target: DocumentTemplateBlockTarget = null
	) {
		createDocumentTemplateBlock(
			input: { code: $code, content: $content, documentTypes: $documentTypes, label: $label, salutationId: $salutationId, target: $target }
		) {
			__typename
		}
	}
`
export const DocumentTemplateBlockGQL = gql`
	query DocumentTemplateBlock($id: ID!) {
		documentTemplateBlock(id: $id) {
			code
			content
			documentTypes
			id
			label
			salutation {
				id
				label
			}
			target
		}
	}
`
export const updateDocumentTemplateBlockGQL = gql`
	mutation updateDocumentTemplateBlock(
		$id: ID!
		$code: DocumentTemplateBlockCode = null
		$content: Text!
		$documentTypes: [DocumentType!]!
		$label: DocumentTemplateBlockLabel!
		$salutationId: ID = null
		$target: DocumentTemplateBlockTarget = null
	) {
		updateDocumentTemplateBlock(
			input: { id: $id, code: $code, content: $content, documentTypes: $documentTypes, label: $label, salutationId: $salutationId, target: $target }
		) {
			__typename
		}
	}
`