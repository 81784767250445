import {
	ApplySchemaAttributes,
	command,
	CommandFunction,
	ExtensionTag,
	getTextSelection,
	MarkExtension,
	MarkExtensionSpec,
	MarkSpecOverride,
	PrimitiveSelection,
	extension,
	toggleMark,
} from "@remirror/core"
/**
 * Adds a file node to the editor
 */

export interface SmallOptions {}

@extension<SmallOptions>({ defaultOptions: {} })

export class SmallExtension extends MarkExtension<SmallOptions> {
	get name() {
		return "small" as const
	}

	createTags() {
		return [ExtensionTag.FormattingMark, ExtensionTag.FontStyle]
	}

	createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
		return {
			...override,
			attrs: extra.defaults(),
			parseDOM: [{ tag: "small" }, { style: "font-size=small" }, { style: "font-size=smaller" }, ...(override.parseDOM ?? [])],
			toDOM: node => ["small", extra.dom(node), 0],
		}
	}

	@command()
	toggleSmall(selection?: PrimitiveSelection): CommandFunction {
		return toggleMark({ type: this.type, selection })
	}

	@command()
	setSmall(selection?: PrimitiveSelection): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc)
			dispatch?.(tr.addMark(from, to, this.type.create()))
			return true
		}
	}

	@command()
	removeSmall(selection?: PrimitiveSelection): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc)
			if (!tr.doc.rangeHasMark(from, to, this.type)) {
				return false
			}

			dispatch?.(tr.removeMark(from, to, this.type))

			return true
		}
	}
}

declare global {
	namespace Remirror {
		interface AllExtensions {
			small: SmallExtension
		}
	}
}
