import MCountry from './MCountry'


export default class MAddress {

	static fieldSelection(): string {
		return `{
			city
			country ${MCountry.fieldSelection()}
			line1
			line2
			postalCode
		}`
	}


	readonly city: string | null
	readonly country: MCountry
	readonly line1: string | null
	readonly line2: string | null
	readonly postalCode: string | null


	constructor(properties: {
		readonly city: string | null
		readonly country: MCountry
		readonly line1: string | null
		readonly line2: string | null
		readonly postalCode: string | null
	}) {
		this.city = properties.city
		this.country = properties.country
		this.line1 = properties.line1
		this.line2 = properties.line2
		this.postalCode = properties.postalCode
	}


	format(multiline = false): string | null {
		let formatted = this.formatInCountry(multiline)
		if (this.country.code !== 'DE' || !formatted) {
			if (formatted) {
				formatted += multiline ? '\n' : ', '
			}
			else {
				formatted = ''
			}

			formatted += this.country.name
		}

		return formatted
	}


	formatInCountry(multiline = false): string | null {
		let formatted = this.line1
		if (this.postalCode || this.city) {
			if (formatted) {
				formatted += multiline ? '\n' : ', '
			}
			else {
				formatted = ''
			}

			formatted += this.postalCode ?? ''

			if (this.city) {
				if (this.postalCode) {
					formatted += ' '
				}
				formatted += this.city
			}
		}

		return formatted
	}


	static fromJson(json: any): MAddress {
		return new this({
			city: json.city,
			country: MCountry.fromJson(json.country),
			line1: json.line1,
			line2: json.line2,
			postalCode: json.postalCode
		})
	}
}
