import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button } from "@mui/material"
import { useMutation } from "@apollo/client"
import { createMagazineGQL } from "../magazinesGQL"
import { createMagazine, createMagazineVariables } from "../__generated__/createMagazine"
import parsePhoneNumberFromString from "libphonenumber-js"
import { useFormik } from "formik"
import * as yup from "yup"

const CreateMagazine = () => {
	const { t, i18n } = useTranslation()
	const validationSchema = yup.object({
		name: yup
			.string()
			.required(`${t("name")} is required`)
			.max(100, `${t("name")} can not be longer than 100 characters`),
	})
	const [createMagazine, { data, loading, error }] = useMutation<createMagazine, createMagazineVariables>(createMagazineGQL)
	const formik = useFormik({
		initialValues: {
			name: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: createMagazineVariables) => {
			const createMagazineData = await createMagazine({ variables: values })
			if (createMagazineData?.data?.createMagazine?.__typename) {
				navigate("/magazines")
			}
		},
	})
	let navigate = useNavigate()
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("createMagazine")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="name"
									name="name"
									label={t("name")}
									value={formik.values.name}
									onChange={formik.handleChange}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default CreateMagazine
