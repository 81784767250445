import { UploadContext } from "@remirror/core"
import { NodeViewComponentProps } from "@remirror/react"
import { useQuery } from "@apollo/client"
import { variablesGQL } from "./VariablesGQL"
import type { VariableAttributes } from "./VariableExtension"
import { variables, variables_variables } from "./__generated__/variables"

export type VariablesComponentProps = NodeViewComponentProps & {
	context?: UploadContext
	abort: () => void
}
const VariableComponents: React.FC<VariablesComponentProps> = ({ node }) => {
	const { data: variablesData } = useQuery<variables>(variablesGQL)
	const attrs = node.attrs as VariableAttributes
	return (
		<>
			{variablesData?.variables.map((e: variables_variables) => {
				if (e?.id === attrs?.id ? attrs?.id : "") {
					return (
						<span key={e.id}>
							{e.label}
						</span>
					)
				}
			})}
		</>
	)
}
export default VariableComponents
