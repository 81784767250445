import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import ProcessTagsList from "./ProcessTagsList"
import { processTagsGQL, archiveProcessTagGQL, unarchiveProcessTagGQL } from "./processTagsGQL"
import { processTags as processTagsInterface } from "./__generated__/processTags"
import { archiveProcessTagVariables, archiveProcessTag } from "./__generated__/archiveProcessTag"
import "./processTags.scss"
import { unarchiveProcessTag } from "./__generated__/unarchiveProcessTag"
import ListHeader from "../shared/TableHeader"

const ProcessTags = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("processTags")
	const [getProcessTags, { loading: processTagsLoading, error: processTagsError, data: processTags }] = useLazyQuery<processTagsInterface>(processTagsGQL, {
		fetchPolicy: "network-only", // Doesn't check cache before making a network request
		variables: { includeArchived: true },
	})
	const [archiveProcessTagClick, { data, loading, error }] = useMutation<archiveProcessTag, archiveProcessTagVariables>(archiveProcessTagGQL)

	const [unarchiveProcessTagClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveProcessTag,
		archiveProcessTagVariables
	>(unarchiveProcessTagGQL)

	React.useEffect(() => {
		getProcessTags()
	}, [])
	const archive = async (id: string) => {
		const archiveProcessTagData = archiveProcessTagClick({
			variables: { id: id },
		})
		if ((await archiveProcessTagData)?.data?.archiveProcessTag?.__typename ? true : false) {
			getProcessTags()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveProcessTagData = unarchiveProcessTagClick({
			variables: { id: id },
		})
		if ((await unarchiveProcessTagData)?.data?.unarchiveProcessTag?.__typename ? true : false) {
			getProcessTags()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
			<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<ProcessTagsList processTags={processTags?.processTags} archive={archive} unarchive={unarchive}  checked={checked} />
			</Paper>
		</div>
	)
}

export default ProcessTags
