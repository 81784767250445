import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { eventsByLocationReportGQL } from "../dashboardGQL"
import EventsByLocationsList from "./EventsByLocationList"
import { eventsByLocationReport, eventsByLocationReportVariables } from "../__generated__/eventsByLocationReport"
import { formatDate } from "../../shared/formatDate"

const EventsByLocations = () => {
	const [to, setTo] = useState(new Date())
	const [from, setFrom] = useState(new Date(to.getFullYear(), to.getMonth(), to.getDate() - 7))
	const [getEventsByLocations, { loading: eventsByLocationsLoading, error: eventsByLocationsError, data: eventsByLocations }] = useLazyQuery<
		eventsByLocationReport,
		eventsByLocationReportVariables
	>(eventsByLocationReportGQL, {
		variables: {
			from: formatDate(from),
			to: formatDate(to),
		},
	})
	useEffect(() => {
		getEventsByLocations()
	}, [])

	useEffect(() => {
		getEventsByLocations()
	}, [from, to])

	return (
		<div className="div-table-wrapper" style={{ maxWidth: "650px" }}>
			<EventsByLocationsList data={eventsByLocations?.eventsByLocationReport.values} from={from} to={to} setFrom={setFrom} setTo={setTo} />
		</div>
	)
}
export default EventsByLocations
