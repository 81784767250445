/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DocumentState {
  draft = "draft",
  final = "final",
}

export enum DocumentTemplateBlockTarget {
  farewell = "farewell",
  greeting = "greeting",
  message = "message",
  postscript = "postscript",
  preamble = "preamble",
}

export enum DocumentType {
  contract = "contract",
  contractCoverLetter = "contractCoverLetter",
  processOverview = "processOverview",
  proposal = "proposal",
  questionnaire = "questionnaire",
}

export enum EquipmentType {
  light = "light",
  sound = "sound",
}

export enum EventSeason {
  high = "high",
  low = "low",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
