import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import MusicPreferencesList from "./MusicPreferencesList"
import { musicPreferencesGQL, archiveMusicPreferenceGQL, unarchiveMusicPreferenceGQL } from "./musicPreferencesGQL"
import { musicPreferences as musicPreferencesInterface } from "./__generated__/musicPreferences"
import { archiveMusicPreference, archiveMusicPreferenceVariables } from "./__generated__/archiveMusicPreference"
import "./musicPreferences.scss"
import { unarchiveMusicPreference } from "../MusicPreferences/__generated__/unarchiveMusicPreference"
import ListHeader from "../shared/TableHeader"

const MusicPreferences = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("musicPreferences")
	const [getMusicPreferences, { loading: musicPreferencesLoading, error: musicPreferencesError, data: musicPreferences }] =
		useLazyQuery<musicPreferencesInterface>(musicPreferencesGQL, {
			fetchPolicy: "network-only", // Doesn't check cache before making a network request
			variables: { includeArchived: true },
		})
	const [archiveMusicPreferenceClick, { data, loading, error }] = useMutation<archiveMusicPreference, archiveMusicPreferenceVariables>(
		archiveMusicPreferenceGQL
	)

	const [unarchiveMusicPreferenceClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveMusicPreference,
		archiveMusicPreferenceVariables
	>(unarchiveMusicPreferenceGQL)

	React.useEffect(() => {
		getMusicPreferences()
	}, [])
	const archive = async (id: string) => {
		const archiveMusicPreferenceData = await archiveMusicPreferenceClick({
			variables: { id: id },
		})
		if (archiveMusicPreferenceData?.data?.archiveMusicPreference?.__typename) {
			getMusicPreferences()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveMusicPreferenceData = unarchiveMusicPreferenceClick({
			variables: { id: id },
		})
		if ((await unarchiveMusicPreferenceData)?.data?.unarchiveMusicPreference?.__typename ? true : false) {
			getMusicPreferences()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<MusicPreferencesList musicPreferences={musicPreferences?.musicPreferences} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}

export default MusicPreferences
