import { Link as RouterLink } from "react-router-dom"
import { Typography, Link } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import TextField from "@mui/material/TextField"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DatePicker from "@mui/lab/DatePicker"

const RequestsChartHeader = ({ from, setFrom, to, setTo }: { from: Date; setFrom: Function; to: Date; setTo: Function }) => {
	const { t, i18n } = useTranslation()
	return (
		<div className="paper-header">
			<div className="title-header">
				<Typography variant="h4">{t("requestsChart")}</Typography>
			</div>

			<div className="title-header">
				<div className="date-input-margin">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label={t("from")}
							value={from}
							onChange={newValue => {
								setFrom(newValue)
							}}
							renderInput={params => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
				<div className="date-input-margin">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label={t("to")}
							value={to}
							onChange={newValue => {
								setTo(newValue)
							}}
							renderInput={params => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	)
}
export default RequestsChartHeader
