import * as React from "react"
import { Paper } from "@mui/material"
import { useLazyQuery, useMutation } from "@apollo/client"
import DocumentTemplateBlocksHeader from "./DocumentTemplateBlockHeader"
import DocumentTemplateBlocksList from "./DocumentTemplateBlockList"
import { documentTemplateBlocksGQL } from "./documentTemplateBlocksGQL"
import { documentTemplateBlocks as documentTemplateBlocksInterface } from "./__generated__/documentTemplateBlocks"
import "./documentTemplateBlocks.scss"

const DocumentTemplateBlocks = () => {
	const [getDocumentTemplateBlocks, { loading: documentTemplateBlocksLoading, error: documentTemplateBlocksError, data: documentTemplateBlocks }] =
		useLazyQuery<documentTemplateBlocksInterface>(documentTemplateBlocksGQL, {
			fetchPolicy: "network-only", // Doesn't check cache before making a network request
		})
	
	React.useEffect(() => {
		getDocumentTemplateBlocks()
	}, [])
	
	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<DocumentTemplateBlocksHeader />
				<DocumentTemplateBlocksList documentTemplateBlocks={documentTemplateBlocks?.documentTemplateBlocks} />
			</Paper>
		</div>
	)
}

export default DocumentTemplateBlocks
