import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"
import { useTranslation } from "react-i18next"
import { musicPreferences_musicPreferences } from "./__generated__/musicPreferences"
import ConfirmDialog from "../shared/ConfirmDialog"

const MusicPreferenceList = ({
	musicPreferences,
	archive,
	unarchive,
	checked,
}: {
	musicPreferences?: musicPreferences_musicPreferences[]
	archive: Function
	unarchive: Function
	checked: boolean
}) => {
	const { t, i18n } = useTranslation()
	const [open, openDialog] = React.useState(false)
	const [currentMusicPreference, setCurrentMusicPreference] = React.useState<musicPreferences_musicPreferences | null>()
	const openConfirmDialog = (row: musicPreferences_musicPreferences) => {
		setCurrentMusicPreference(row)
		openDialog(true)
	}
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("label")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{musicPreferences?.map((row: musicPreferences_musicPreferences) =>
							row?.archivalTimestamp ? (
								checked ? (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{row?.label}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											<div className="table-action-body">
												<RestoreFromTrashIcon className="delete-style" onClick={() => unarchive(row.id)} />
											</div>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)
							) : (
								<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row?.label}
									</TableCell>
									<TableCell align="right">
										<div className="table-action-body">
											<Link component={RouterLink} to={`${row.id}`}>
												<EditOutlinedIcon />
											</Link>
											<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={open}
				openDialog={openDialog}
				content={`Do you want to archive ${currentMusicPreference?.label ? currentMusicPreference.label : ""} ?`}
				Complete={() => {
					archive(currentMusicPreference?.id ? currentMusicPreference.id : "0")
				}}
			/>
		</Paper>
	)
}
export default MusicPreferenceList
