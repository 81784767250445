import { gql } from "@apollo/client"

export const eventSeasonByDateGQL = gql`
	query eventSeasonByDate($date: LocalDate!) {
		eventSeasonByDate(date: $date)
	}
`
export const assignEventSeasonToDateGQL = gql`
	mutation assignEventSeasonToDate($date: LocalDate!,$season:EventSeason!) {
		assignEventSeasonToDate(input: { date: $date,season: $season }) {
            date
            season
			__typename
		}
	}
`