import * as React from "react"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { getUserGQL, updateUserGQL } from "../usersGQL"
import parsePhoneNumberFromString from "libphonenumber-js"
import PhoneNumbers from "../../shared/EditPhoneNumbers/PhoneNumbers"
import { updateUser as updateUserInterface, updateUserVariables } from "../__generated__/updateUser"
import { user as userInterface, userVariables } from "../__generated__/user"
import "../users.scss"
import { useFormik } from "formik"
import * as yup from "yup"

const parseOrNull = (string: string) => {
	const value = parsePhoneNumberFromString(string, "DE")
	if (!value || !value.isPossible()) {
		return null
	}

	return value
}
const EditUser = () => {
	const { id } = useParams()
	const {
		loading: loadingGetUser,
		error,
		data,
	} = useQuery<userInterface, userVariables>(getUserGQL, {
		variables: { id: `${id}` },
	})
	const [phoneNumberAmount, setPhoneNumberAmount] = React.useState(0)
	const [phoneNumbers, setPhoneNumbers] = React.useState<string[]>([])
	const [editUser, { data: editUserData, loading: editUserLoading, error: editUserError }] = useMutation<updateUserInterface, updateUserVariables>(
		updateUserGQL
	)
	let navigate = useNavigate()
	const { t, i18n } = useTranslation()

	const validationSchema = yup.object({
		emailAddress: yup
			.string()
			.required(`${t("emailAdress")} is required`)
			.email("Enter a valid email")
			.max(50, `${t("emailAdress")} can not be longer than 50 characters`),
		firstName: yup
			.string()
			.required(`${t("firstName")} is required`)
			.max(50, `${t("firstName")} can not be longer than 50 characters`),
		lastName: yup
			.string()
			.required(`${t("lastName")} is required`)
			.max(50, `${t("lastName")} can not be longer than 50 characters`),
	})

	const formik = useFormik({
		initialValues: {
			emailAddress: "",
			firstName: "",
			lastName: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			let phoneNumb: string[] = []
			phoneNumbers.forEach((value, index) => {
				phoneNumb = [...phoneNumb, String(parseOrNull(value)?.number)]
			})
			const numbers = phoneNumb
			numbers.length = phoneNumberAmount
			const editUserDataSync = await editUser({ variables: { ...values, phoneNumbers: numbers, id: `${id}` } })
			if (editUserDataSync?.data?.updateUser?.__typename) {
				navigate("/users")
			}
		},
	})
	React.useEffect(() => {
		if (!loadingGetUser) {
			formik.setFieldValue("emailAddress", data?.user?.emailAddress ? data.user.emailAddress : "")
			formik.setFieldValue("firstName", data?.user?.firstName ? data.user.firstName : "")
			formik.setFieldValue("lastName", data?.user?.lastName ? data.user.lastName : "")
			setPhoneNumberAmount(Number(data?.user?.phoneNumbers.length ? data.user.phoneNumbers.length : ""))
			setPhoneNumbers(data?.user?.phoneNumbers ? data.user.phoneNumbers : [])
		}
	}, [loadingGetUser])
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editUser")}</Typography>
			</div>
			<div className="container-centering">
				<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="emailAddress"
									name="emailAddress"
									label={t("emailAdress")}
									value={formik.values.emailAddress}
									onChange={formik.handleChange}
									error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
									helperText={formik.touched.emailAddress && formik.errors.emailAddress}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="firstName"
									name="firstName"
									label={t("firstName")}
									value={formik.values.firstName}
									onChange={formik.handleChange}
									error={formik.touched.firstName && Boolean(formik.errors.firstName)}
									helperText={formik.touched.firstName && formik.errors.firstName}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									id="lastName"
									name="lastName"
									label={t("lastName")}
									value={formik.values.lastName}
									onChange={formik.handleChange}
									error={formik.touched.lastName && Boolean(formik.errors.lastName)}
									helperText={formik.touched.lastName && formik.errors.lastName}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="outlined-select-currency"
									select
									label={t("phoneNumberAmount")}
									value={phoneNumberAmount}
									onChange={e => {
										setPhoneNumberAmount(Number(e.target.value))
									}}
									helperText="Please select your currency"
								>
									{[0, 1, 2, 3].map(option => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<PhoneNumbers
								phoneNumberAmount={phoneNumberAmount}
								phoneNumbers={phoneNumbers}
								setPhoneNumbers={setPhoneNumbers}
								loadingGetUser={loadingGetUser}
							/>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditUser
