import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import Button from "@mui/material/Button"
import RedoIcon from '@mui/icons-material/Redo';

const ReDo = () => {
	// Using command chaining
	const active = useActive()
	const { redo } = useCommands()

	return (
		<Button
			onClick={() => {
				redo()
			}}
			variant="outlined"
			disabled={redo.enabled() === false}
		>
			<RedoIcon />
		</Button>
	)
}
export default ReDo
