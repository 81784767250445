import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Typography, TextField, Grid, Box, Button, MenuItem } from "@mui/material"
import { useMutation, useQuery } from "@apollo/client"
import { updateWeddingFairGQL, WeddingFairGQL } from "../weddingFairsGQL"
import { updateWeddingFair as updateWeddingFairInterface, updateWeddingFairVariables } from "../__generated__/updateWeddingFair"
import { weddingFair, weddingFairVariables } from "../__generated__/weddingFair"
import { useFormik } from "formik"
import * as yup from "yup"

const EditWeddingFair = () => {
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const validationSchema = yup.object({
		name: yup
			.string()
			.required(`${t("name")} is required`)
			.max(100, `${t("name")} can not be longer than 100 characters`),
	})
	const formik = useFormik({
		initialValues: {
			name: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const updateWeddingFairData = await updateWeddingFair({ variables: {id:id,...values} })
			if (updateWeddingFairData?.data?.updateWeddingFair?.__typename) {
				navigate("/wedding-fairs")
			}
		},
	})
	const [updateWeddingFair, { data, loading, error }] = useMutation<updateWeddingFairInterface, updateWeddingFairVariables>(updateWeddingFairGQL)
	const { loading: loadingGetWeddingFair, data: getWeddingFairData } = useQuery<weddingFair, weddingFairVariables>(WeddingFairGQL, {
		variables: { id: `${id}` },
	})
	React.useEffect(() => {
		if (!loadingGetWeddingFair) {
			formik.setFieldValue("name", getWeddingFairData?.weddingFair?.name ? getWeddingFairData.weddingFair.name : "")
		}
	}, [loadingGetWeddingFair])
	let navigate = useNavigate()
	
	return (
		<>
			<div className="create-user-title">
				<Typography variant="h4">{t("editWeddingFairs")}</Typography>
			</div>
			<div className="container-centering">
			<div className={"container-user-create"}>
					<Box
						component="form"
						sx={{
							"& .MuiTextField-root": { m: 1, width: "25ch" },
						}}
						onSubmit={formik.handleSubmit}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									id="name"
									name="name"
									label={t("name")}
									value={formik.values.name}
									onChange={formik.handleChange}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={formik.touched.name && formik.errors.name}
									variant="outlined"
								/>
							</Grid>
						</Grid>
						<div className="create-user-button">
							<Button variant="outlined" type="submit">
								{t("submit")}
							</Button>
						</div>
					</Box>
				</div>
			</div>
		</>
	)
}
export default EditWeddingFair

//export default EditWeddingFairs
