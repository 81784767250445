import { Link as RouterLink } from "react-router-dom"
import { Typography, Link, FormGroup, FormControlLabel, Switch } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import "../../index.scss"

const ListHeader = ({ checked, setChecked, title }: { checked: boolean; setChecked: Function; title: string }) => {
	const { t, i18n } = useTranslation()
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked)
	}
	return (
		<div className="paper-header">
			<div className="title-header">
				<Typography variant="h4">{title}</Typography>
			</div>
			<div className="paper-header-right">
				<div className="title-header">
					<FormGroup>
						<FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label={t("showArchived")} />
					</FormGroup>
				</div>
				<div className="title-header">
					<Link component={RouterLink} to={"create"}>
						<AddIcon />
					</Link>
				</div>
			</div>
		</div>
	)
}
export default ListHeader
