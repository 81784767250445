import { gql } from "@apollo/client"

export const signInGQL = gql`
	mutation Authentication($emailAddress: EmailAddress!, $password: Password!) {
		authenticate(input: { emailAddress: $emailAddress, password: $password }) {
			__typename
	
			... on AuthenticateOutputAuthenticated {
				user {
					emailAddress
					firstName
					lastName
					id
				}
			}
		}
	}
`
