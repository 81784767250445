import { ComponentType } from "react"
import {
	ApplySchemaAttributes,
	command,
	CommandFunction,
	DOMCompatibleAttributes,
	extension,
	ExtensionPriority,
	ExtensionTag,
	getTextSelection,
	Handler,
	keyBinding,
	KeyBindingProps,
	NodeExtension,
	NodeExtensionSpec,
	NodeSpecOverride,
	NodeWithPosition,
	omitExtraAttributes,
	PrimitiveSelection,
	ProsemirrorNode,
	Transaction,
} from "@remirror/core"
import { NodeViewComponentProps } from "@remirror/react"
import { SignatureComponent, SignatureComponentProps } from "./SignatureComponent"
export interface SignatureOptions {
	render?: (props: SignatureComponentProps) => React.ReactElement<HTMLElement> | null

	/**
	 * Called after the `commands.deleteFile` has been called.
	 */
	onDeleteFile?: Handler<(props: { tr: Transaction; pos: number; node: ProsemirrorNode }) => void>
}

/**
 * Adds a file node to the editor
 */
@extension<SignatureOptions>({
	defaultOptions: {
		render: SignatureComponent,
	},
	handlerKeys: ["onDeleteFile"],
})
export class SignatureExtension extends NodeExtension<SignatureOptions> {
	get name() {
		return "signature" as const
	}

	ReactComponent: ComponentType<NodeViewComponentProps> = props => {
		return this.options.render({ ...props, abort: () => {}, context: undefined })
	}

	createTags() {
		return [ExtensionTag.InlineNode]
	}

	createNodeSpec(extra: ApplySchemaAttributes, override: NodeSpecOverride): NodeExtensionSpec {
		return {
			attrs: {
				...extra.defaults(),
				url: { default: "" },
			},
			atom: true,
			draggable: true,
			group: "inline",
			inline: true,
			parseDOM: [{ tag: "signature" }],
			selectable: true,
			toDOM: () => ["signature"],
		}
	}

	@command()
	insertSignature( selection?: PrimitiveSelection): CommandFunction {
		return ({ tr, dispatch }) => {
			const { from, to } = getTextSelection(selection ?? tr.selection, tr.doc)
			const node = this.type.create()

			dispatch?.(tr.replaceRangeWith(from, to, node))

			return true
		}
	}

	@command()
	deleteFile(pos: number): CommandFunction {
		return ({ tr, state, dispatch }) => {
			const node = state.doc.nodeAt(pos)

			if (node && node.type === this.type) {
				tr.delete(pos, pos + 1).scrollIntoView()
				this.options.onDeleteFile({ tr, pos, node })
				dispatch?.(tr)
				return true
			}

			return false
		}
	}

	@keyBinding({ shortcut: ["Backspace", "Delete"] })
	backspaceShortcut(props: KeyBindingProps): boolean {
		const { tr, state } = props
		const { from, to, empty } = tr.selection

		if (!this.hasHandlers("onDeleteFile") || empty) {
			return false
		}

		// Collect a list of files nodes contained within this delete range
		const onDeleteFileCallbacks: NodeWithPosition[] = []
		state.doc.nodesBetween(from, to, (node, pos) => {
			if (node.type === this.type) {
				onDeleteFileCallbacks.push({ node, pos })
			}

			return true
		})

		// Call the onDeleteFile callback for each file being deleted.
		onDeleteFileCallbacks.forEach(({ node, pos }) => {
			this.options.onDeleteFile({ tr, node, pos })
		})

		// Don't need to handle the delete ourselves, just the callbacks
		return false
	}
}

declare global {
	namespace Remirror {
		interface AllExtensions {
			signature: SignatureExtension
		}
	}
}
