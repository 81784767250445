import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import SalutationsList from "./SalutationList"
import { salutationsGQL, archiveSalutationGQL, unarchiveSalutationGQL } from "./salutationsGQL"
import { salutations as salutationsInterface } from "./__generated__/salutations"
import { archiveSalutationVariables, archiveSalutation } from "./__generated__/archiveSalutation"
import "./salutations.scss"
import { unarchiveSalutation } from "./__generated__/unarchiveSalutation"
import ListHeader from "../shared/TableHeader"

const Salutations = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("salutations")
	const [getSalutations, { loading: salutationsLoading, error: salutationsError, data: salutations }] = useLazyQuery<salutationsInterface>(salutationsGQL, {
		fetchPolicy: "network-only", // Doesn't check cache before making a network request
		variables: { includeArchived: true },
	})
	const [archiveSalutationClick, { data, loading, error }] = useMutation<archiveSalutation, archiveSalutationVariables>(archiveSalutationGQL)

	const [unarchiveSalutationClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveSalutation,
		archiveSalutationVariables
	>(unarchiveSalutationGQL)

	React.useEffect(() => {
		getSalutations()
	}, [])
	const archive = async (id: string) => {
		const archiveSalutationData = archiveSalutationClick({
			variables: { id: id },
		})
		if ((await archiveSalutationData)?.data?.archiveSalutation?.__typename ? true : false) {
			getSalutations()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveSalutationData = unarchiveSalutationClick({
			variables: { id: id },
		})
		if ((await unarchiveSalutationData)?.data?.unarchiveSalutation?.__typename ? true : false) {
			getSalutations()
		}
	}

	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<SalutationsList salutations={salutations?.salutations} archive={archive} unarchive={unarchive} checked={checked} />
			</Paper>
		</div>
	)
}

export default Salutations
