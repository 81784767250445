import { gql } from "@apollo/client"
export const musicPreferencesGQL = gql`
	query musicPreferences($includeArchived: Boolean){
		musicPreferences (includeArchived: $includeArchived) {
			id
			label
			archivalTimestamp
		}
	}
`
export const createMusicPreferenceGQL = gql`
	mutation createMusicPreference($label: MusicPreferenceLabel!) {
		createMusicPreference(input: { label: $label }) {
			__typename
		}
	}
`
export const MusicPreferenceGQL = gql`
	query MusicPreference($id: ID!) {
		musicPreference(id: $id) {
			id
			label
		}
	}
`
export const updateMusicPreferenceGQL = gql`
	mutation updateMusicPreference($id: ID!, $label: MusicPreferenceLabel!) {
		updateMusicPreference(input: { id: $id, label: $label }) {
			__typename
		}
	}
`
export const archiveMusicPreferenceGQL = gql`
	mutation archiveMusicPreference($id: ID!) {
		archiveMusicPreference(id: $id) {
			__typename
		}
	}
`
export const unarchiveMusicPreferenceGQL = gql`
	mutation unarchiveMusicPreference($id: ID!) {
		unarchiveMusicPreference(id: $id) {
			__typename
		}
	}
`
