import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "../shared/ConfirmDialog"
import { users_users } from "./__generated__/users"
import "./users.scss"

const UsersList = ({ data, archive, unarchive, checked }: { data?: users_users[]; archive: Function; unarchive: Function; checked: boolean }) => {
	const { t, i18n } = useTranslation()
	const [open, openDialog] = React.useState(false)
	const [currentUser, setCurrentUser] = React.useState<users_users | null>(null)
	const openConfirmDialog = (row: users_users) => {
		setCurrentUser(row)
		openDialog(true)
	}
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("emailAdress")}</TableCell>
							<TableCell align="right">{t("firstName")}</TableCell>
							<TableCell align="right">{t("lastName")}</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((row: users_users) =>
							row.archivalTimestamp ? (
								checked ? (
									<TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{row?.emailAddress}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.firstName}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{row?.lastName}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											<div className="table-action-body">
												<Link component={RouterLink} to={`${row?.id}`}>
													<EditOutlinedIcon />
												</Link>
												<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
											</div>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)
							) : (
								<TableRow key={row?.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row?.emailAddress}
									</TableCell>
									<TableCell align="right">{row?.firstName}</TableCell>
									<TableCell align="right">{row?.lastName}</TableCell>
									<TableCell align="right">
										<div className="table-action-body">
											<Link component={RouterLink} to={`${row?.id}`}>
												<EditOutlinedIcon />
											</Link>
											<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={open}
				openDialog={openDialog}
				content={`Do you want to archive ${currentUser?.firstName ? currentUser.firstName : ""} ${currentUser?.lastName ? currentUser.lastName : ""} `}
				Complete={() => archive(currentUser?.id ? currentUser.id : "0")}
			/>
		</Paper>
	)
}
export default UsersList
