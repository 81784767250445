import * as React from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "@apollo/client"
import ProcessEmailTemplatesList from "./ProcessEmailTemplatesList"
import { processEmailTemplatesGQL, archiveProcessEmailTemplateGQL, unarchiveProcessEmailTemplateGQL } from "./processEmailTemplatesGQL"
import { processEmailTemplates as processEmailTemplatesInterface } from "./__generated__/processEmailTemplates"
import { archiveProcessEmailTemplateVariables, archiveProcessEmailTemplate } from "./__generated__/archiveProcessEmailTemplate"
import "./processEmailTemplates.scss"
import { unarchiveProcessEmailTemplate } from "./__generated__/unarchiveProcessEmailTemplate"
import ListHeader from "../shared/TableHeader"

const ProcessEmailTemplates = () => {
	const { t, i18n } = useTranslation()
	const [checked, setChecked] = React.useState(false)
	const title = t("processEmailTemplates")
	const [getProcessEmailTemplates, { loading: processEmailTemplatesLoading, error: processEmailTemplatesError, data: processEmailTemplates }] =
		useLazyQuery<processEmailTemplatesInterface>(processEmailTemplatesGQL, {
			fetchPolicy: "network-only", // Doesn't check cache before making a network request
			variables: { includeArchived: true },
		})
	const [archiveProcessEmailTemplateClick, { data, loading, error }] = useMutation<archiveProcessEmailTemplate, archiveProcessEmailTemplateVariables>(
		archiveProcessEmailTemplateGQL
	)

	const [unarchiveProcessEmailTemplateClick, { data: unarchiveData, loading: unarchiveLoading, error: unarchiveError }] = useMutation<
		unarchiveProcessEmailTemplate,
		archiveProcessEmailTemplateVariables
	>(unarchiveProcessEmailTemplateGQL)

	React.useEffect(() => {
		getProcessEmailTemplates()
	}, [])
	const archive = async (id: string) => {
		const archiveProcessEmailTemplateData = archiveProcessEmailTemplateClick({
			variables: { id: id },
		})
		if ((await archiveProcessEmailTemplateData)?.data?.archiveProcessEmailTemplate?.__typename ? true : false) {
			getProcessEmailTemplates()
		}
	}

	const unarchive = async (id: string) => {
		const unarchiveProcessEmailTemplateData = unarchiveProcessEmailTemplateClick({
			variables: { id: id },
		})
		if ((await unarchiveProcessEmailTemplateData)?.data?.unarchiveProcessEmailTemplate?.__typename ? true : false) {
			getProcessEmailTemplates()
		}
	}
	return (
		<div className="div-table-wrapper">
			<Paper sx={{ width: "100%", mb: 2 }}>
				<ListHeader checked={checked} setChecked={setChecked} title={title} />
				<ProcessEmailTemplatesList
					processEmailTemplates={processEmailTemplates?.processEmailTemplates}
					archive={archive}
					unarchive={unarchive}
					checked={checked}
				/>
			</Paper>
		</div>
	)
}

export default ProcessEmailTemplates
