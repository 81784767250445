import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import { Button, SvgIcon } from "@mui/material"
import "../Editor.scss"
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';

const OrderedList = () => {
	// Using command chaining
	const chain = useChainedCommands()
	const { toggleOrderedList, setTextCase } = useCommands()

	return (
		<Button
			onClick={() => {
				chain // Begin a chain
					.toggleOrderedList()
					.focus()
					.run() // A chain must always be terminated with `.run()`
			}}
			variant="outlined"
			disabled={toggleOrderedList.enabled() === false}
		>
			<FormatListNumberedOutlinedIcon />
		</Button>
	)
}
export default OrderedList
