import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { recommendationsByLocationReportGQL } from "../dashboardGQL"
import RecommendationsByLocationsList from "./RecommendationsByLocationList"
import { recommendationsByLocationReport, recommendationsByLocationReportVariables } from "../__generated__/recommendationsByLocationReport"
import { formatDate } from "../../shared/formatDate"

const RecommendationsByLocations = () => {
	const [to, setTo] = useState(new Date())
	const [from, setFrom] = useState(new Date(to.getFullYear(), to.getMonth(), to.getDate() - 7))
	const [
		getRecommendationsByLocations,
		{ loading: recommendationsByLocationsLoading, error: recommendationsByLocationsError, data: recommendationsByLocations },
	] = useLazyQuery<recommendationsByLocationReport, recommendationsByLocationReportVariables>(recommendationsByLocationReportGQL, {
		variables: {
			from: formatDate(from),
			to: formatDate(to),
		},
	})
	useEffect(() => {
		getRecommendationsByLocations()
	}, [])

	useEffect(() => {
		getRecommendationsByLocations()
	}, [from, to])

	return (
		<div className="div-table-wrapper" style={{ maxWidth: "650px" }}>
			<RecommendationsByLocationsList
				data={recommendationsByLocations?.recommendationsByLocationReport.values}
				from={from}
				to={to}
				setFrom={setFrom}
				setTo={setTo}
			/>
		</div>
	)
}
export default RecommendationsByLocations
