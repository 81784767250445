import { useQuery, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Grid, Paper, Button, Snackbar, Alert, Typography, TextField, MenuItem, Box } from "@mui/material"
import { useFormik } from "formik"
import * as yup from "yup"
import { ProcessGQL } from "./ProcessNewDocumentGQL"
import { documentTemplateBlocksGQL } from "../DocumentTemplateBlock/documentTemplateBlocksGQL"
import { createDocumentVariables, createDocument as createDocumentInterface } from "./__generated__/createDocument"
import {
	documentTemplateBlocks as documentTemplateBlocksInterface,
	documentTemplateBlocks_documentTemplateBlocks,
} from "../DocumentTemplateBlock//__generated__/documentTemplateBlocks"
import { createDocumentGQL } from "./ProcessNewDocumentGQL"
import Editor from "../shared/Editor/Editor"
import MText, { MTextNode, MTextNodeParagraph } from "../shared/Editor/EditorContent/model/MText"
import MInlineText, { MInlineTextNodeText, MInlineTextNodeVariable } from "../shared/Editor/EditorContent/model/MInlineText"
import { MInlineTextMarkStrong } from "../shared/Editor/EditorContent/model/MInlineTextMark"
import MAddress from "../shared/MAddress"

const ProcessNewDocument = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()
	const { type, processId } = JSON.parse(id ? id : "")
	const { loading: loadingGetProcess, data: getProcessData } = useQuery(ProcessGQL, {
		variables: { id: `${processId}` },
	})
	const {
		loading: documentTemplateBlocksLoading,
		error: documentTemplateBlocksError,
		data: documentTemplateBlocks,
	} = useQuery<documentTemplateBlocksInterface>(documentTemplateBlocksGQL, {
		variables: { documentType: `${type}` },
	})
	const [createDocument, { data, loading, error }] = useMutation<createDocumentInterface, createDocumentVariables>(createDocumentGQL)
	const subjectContent = new MText([
		new MTextNodeParagraph(
			new MInlineText([
				new MInlineTextNodeText("Vorgangsnummer: ", [new MInlineTextMarkStrong()]),
				new MInlineTextNodeVariable("process.externalId", [new MInlineTextMarkStrong()]),
			])
		),
	])
	const validationSchema = yup.object({
		recipient: yup.object().required(`${t("recipient")} is required`),
		farewell: yup.object(),
		greeting: yup.object(),
		message: yup.object(),
		postscript: yup.object(),
		preamble: yup.object(),
		name: yup
			.string()
			.required(`${t("name")} is required`)
			.max(40, `${t("name")} can not be longer than 40 characters`),
	})
	const formik = useFormik({
		initialValues: {
			recipient: `` as any,
			farewell: `` as unknown as documentTemplateBlocks_documentTemplateBlocks,
			greeting: `` as unknown as documentTemplateBlocks_documentTemplateBlocks,
			message: `` as unknown as documentTemplateBlocks_documentTemplateBlocks,
			postscript: `` as unknown as documentTemplateBlocks_documentTemplateBlocks,
			preamble: `` as unknown as documentTemplateBlocks_documentTemplateBlocks,
			name: type === "proposal" ? t("offer") : (t("bookingConfirmation") as string),
		},
		validationSchema: validationSchema,
		onSubmit: async (values: any) => {
			const name = values.name
			const recipient = values.recipient
			let nodes: MTextNode[] = []
			if (type === "proposal") {
				nodes = nodes.concat(subjectContent.nodes)
			}
			if (values.greeting !== ``) {
				nodes = nodes.concat(MText.fromJson(JSON.parse(values.greeting.content)).nodes)
			}

			if (values.preamble !== ``) {
				nodes = nodes.concat(MText.fromJson(JSON.parse(values.preamble.content)).nodes)
			}

			if (values.message !== ``) {
				nodes = nodes.concat(MText.fromJson(JSON.parse(values.message.content)).nodes)
			}
			if (values.farewell !== ``) {
				nodes = nodes.concat(MText.fromJson(JSON.parse(values.farewell.content)).nodes)
			}

			if (!nodes.length) nodes.push(new MTextNodeParagraph(MInlineText.empty))
			const content = new MText(nodes)
			const recipientName = recipient?.name
			const recipientAddressRaw = new MAddress({
				city: recipient?.address?.city,
				country: recipient?.address?.country.code,
				line1: recipient?.address?.line1,
				line2: recipient?.address?.line2,
				postalCode: recipient?.address?.postalCode,
			}).format(true)
			const recipientAddress: any = recipientAddressRaw
				? MInlineText.ofString(recipientName ? `${recipientName}\n${recipientAddressRaw}` : recipientAddressRaw)
				: recipientName
				? MInlineText.ofString(recipientName)
				: MInlineText.empty
			const createDocumentData = await createDocument({
				variables: {
					content: content.serialize() as unknown as Text,
					name,
					processId: getProcessData?.process?.id,
					recipientAddress: recipientAddress.serialize(),
					recipientId: recipient.id,
					type: type,
				},
			})
			if (createDocumentData?.data?.createDocument?.__typename) {
				window.location.replace(`${process.env.VUE_APP_Frontend_URL}/preise.php`)
				// window.history.back()
			}
		},
	})

	const [recipients, setRecipients] = useState<any[]>([])
	const [farewells, setFarewells] = useState<any[]>([])
	const [greetings, setGreetings] = useState<any[]>([])
	const [messages, setMessages] = useState<any[]>([])
	const [postscripts, setPostscripts] = useState<any[]>([])
	const [preambles, setPreambles] = useState<any[]>([])

	function templateBlockFilling(templateBlocks: any[]) {
		let farewellArr: any[] = []
		let greetingArr: any[] = []
		let messageArr: any[] = []
		let postscriptArr: any[] = []
		let preambleArr: any[] = []
		templateBlocks.forEach(templateBlock => {
			switch (templateBlock.target) {
				case "farewell":
					farewellArr = [...farewellArr, templateBlock]

					break
				case "greeting":
					greetingArr = [...greetingArr, templateBlock]
					setGreetings([...greetings, templateBlock])
					break
				case "message":
					messageArr = [...messageArr, templateBlock]
					setMessages([...messages, templateBlock])
					break
				case "postscript":
					postscriptArr = [...postscriptArr, templateBlock]
					setPostscripts([...postscripts, templateBlock])
					break
				case "preamble":
					preambleArr = [...preambleArr, templateBlock]
					setPreambles([...preambles, templateBlock])
					break
				default:
					break
			}
		})
		setFarewells(farewellArr)
		setGreetings(greetingArr)
		setMessages(messageArr)
		setPostscripts(postscriptArr)
		setPreambles(preambleArr)
	}
	useEffect(() => {
		if (getProcessData) {
			const participations = getProcessData?.process?.participations

			const recipients: any[] = []
			participations.forEach((participation: any) => {
				if (participation.contact) {
					const contact = participation.contact
					if (contact.person1.firstName || contact.person1.lastName || contact.companyName) recipients.push({ ...contact.person1, contact })
					if (contact.person2.firstName || contact.person2.lastName) recipients.push({ ...contact.person2, contact })
				}
			})
			setRecipients(recipients)
		}
	}, [getProcessData])
	useEffect(() => {
		if (documentTemplateBlocks !== undefined) {
			templateBlockFilling(documentTemplateBlocks?.documentTemplateBlocks)
		}
	}, [documentTemplateBlocks])
	//recipients
	return (
		<Paper
			sx={{
				margin: "20px",
				width: "100%",
				height: "100%",
			}}
		>
			<Box sx={{ padding: "20px ", "& .MuiTextField-root": { m: 1, width: "45ch" } }} component="form" onSubmit={formik.handleSubmit}>
				<div className="create-user-title">
					<Typography variant="h4">{type === "contract" ? t("contract") : t("proposal")}</Typography>
				</div>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							select
							id="recipient"
							name="recipient"
							label={t("recipient")}
							value={formik.values.recipient}
							onChange={formik.handleChange}
							variant="outlined"
							error={formik.touched.recipient && Boolean(formik.errors.recipient)}
							helperText={formik.touched.recipient && formik.errors.recipient}
						>
							{recipients?.map((contact: any, index: number) => {
								return (
									<MenuItem key={contact.id} value={contact}>
										{`${contact.firstName} ${contact.lastName}`}
									</MenuItem>
								)
							})}
						</TextField>
					</Grid>
					{formik.values.recipient?.id ? (
						<>
							<Grid item xs={12}>
								<Typography variant="subtitle1">{t("addressPreview")}</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle1">
									{formik.values.recipient?.address?.line1 ? formik.values.recipient.address.line1 : ""}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle1">
									{formik.values.recipient?.address?.line2 ? formik.values.recipient.address.line2 : ""}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle1">
									{formik.values.recipient?.address?.city ? formik.values.recipient.address.city : ""}
								</Typography>
							</Grid>
						</>
					) : (
						<></>
					)}

					<>
						{type === "proposal" ? (
							<>
								<Grid item xs={6}>
									<Typography variant="h5">{t("regarding")}</Typography>
								</Grid>
								<Grid item xs={6}>
									<Editor Mtext={subjectContent} content={""} setContent={() => {}} notEditable></Editor>
								</Grid>
							</>
						) : (
							<></>
						)}

						<Grid item xs={6}>
							<TextField
								fullWidth
								select
								id="greeting"
								name="greeting"
								label={t("greeting")}
								value={formik.values.greeting}
								onChange={formik.handleChange}
								variant="outlined"
								error={formik.touched.greeting && Boolean(formik.errors.greeting)}
								helperText={formik.touched.greeting && formik.errors.greeting}
							>
								<MenuItem value={``}>~</MenuItem>
								{greetings?.map((documentBlock: any) => {
									return (
										<MenuItem key={documentBlock.id} value={documentBlock}>
											{documentBlock.label}
										</MenuItem>
									)
								})}
							</TextField>
						</Grid>

						<Grid item xs={6}>
							{formik.values.greeting?.content ? (
								<Editor content={formik.values.greeting?.content as unknown as string} setContent={() => {}} notEditable></Editor>
							) : (
								<></>
							)}
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								select
								id="preamble"
								name="preamble"
								label={t("preamble")}
								value={formik.values.preamble}
								onChange={formik.handleChange}
								variant="outlined"
								error={formik.touched.preamble && Boolean(formik.errors.preamble)}
								helperText={formik.touched.preamble && formik.errors.preamble}
							>
								<MenuItem value={``}>~</MenuItem>
								{preambles?.map((documentBlock: any) => {
									return (
										<MenuItem key={documentBlock.id} value={documentBlock}>
											{documentBlock.label}
										</MenuItem>
									)
								})}
							</TextField>
						</Grid>
						<Grid item xs={6}>
							{formik.values.preamble?.content ? (
								<Editor content={formik.values.preamble?.content as unknown as string} setContent={() => {}} notEditable></Editor>
							) : (
								<></>
							)}
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								select
								id="message"
								name="message"
								label={t("message")}
								value={formik.values.message}
								onChange={formik.handleChange}
								variant="outlined"
								error={formik.touched.message && Boolean(formik.errors.message)}
								helperText={formik.touched.message && formik.errors.message}
							>
								<MenuItem value={``}>~</MenuItem>
								{messages?.map((documentBlock: any) => {
									return (
										<MenuItem key={documentBlock.id} value={documentBlock}>
											{documentBlock.label}
										</MenuItem>
									)
								})}
							</TextField>
						</Grid>
						<Grid item xs={6}>
							{formik.values.message?.content ? (
								<Editor content={formik.values.message?.content as unknown as string} setContent={() => {}} notEditable></Editor>
							) : (
								<></>
							)}
						</Grid>

						<Grid item xs={6}>
							<TextField
								fullWidth
								select
								id="postscript"
								name="postscript"
								label={t("postscript")}
								value={formik.values.postscript}
								onChange={formik.handleChange}
								variant="outlined"
								error={formik.touched.postscript && Boolean(formik.errors.postscript)}
								helperText={formik.touched.postscript && formik.errors.postscript}
							>
								<MenuItem value={``}>~</MenuItem>
								{postscripts?.map((documentBlock: any) => {
									return (
										<MenuItem key={documentBlock.id} value={documentBlock}>
											{documentBlock.label}
										</MenuItem>
									)
								})}
							</TextField>
						</Grid>
						<Grid item xs={6}>
							{formik.values.postscript?.content ? (
								<Editor content={formik.values.postscript?.content as unknown as string} setContent={() => {}} notEditable></Editor>
							) : (
								<></>
							)}
						</Grid>

						<Grid item xs={6}>
							<TextField
								fullWidth
								select
								id="farewell"
								name="farewell"
								label={t("farewell")}
								value={formik.values.farewell}
								onChange={formik.handleChange}
								variant="outlined"
								error={formik.touched.farewell && Boolean(formik.errors.farewell)}
								helperText={formik.touched.farewell && formik.errors.farewell}
							>
								<MenuItem value={``}>~</MenuItem>
								{farewells?.map((documentBlock: any) => {
									return (
										<MenuItem key={documentBlock.id} value={documentBlock}>
											{documentBlock.label}
										</MenuItem>
									)
								})}
							</TextField>
						</Grid>
						<Grid item xs={6}>
							{formik.values.farewell?.content ? (
								<Editor content={formik.values.farewell?.content as unknown as string} setContent={() => {}} notEditable></Editor>
							) : (
								<></>
							)}
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Button variant="outlined" type="submit">
										{t("submit")}
									</Button>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										id="name"
										name="name"
										label={t("name")}
										value={formik.values.name}
										onChange={formik.handleChange}
										variant="outlined"
										error={formik.touched.name && Boolean(formik.errors.name)}
										helperText={formik.touched.name && formik.errors.name}
									></TextField>
								</Grid>
							</Grid>
						</Grid>
					</>
				</Grid>
			</Box>
		</Paper>
	)
}

export default ProcessNewDocument
