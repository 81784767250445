import React from "react"
import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined"
import { variablesGQL } from "../CostumExtensions/VariableExtension/VariablesGQL"
import { useQuery } from "@apollo/client"
import { variables, variables_variables } from "../CostumExtensions/VariableExtension/__generated__/variables"
import { Dialog, DialogTitle, DialogContent, Box, FormControl, InputLabel, Select, OutlinedInput, MenuItem, DialogActions, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

const VariableButton = () => {
	const { insertVariable } = useCommands()
	const { loading: djCategoriesLoading, error: variablesError, data: variablesData } = useQuery<variables>(variablesGQL)
	const [open, setOpen] = React.useState(false)
	const handleClickOpen = () => {
		setOpen(true)
	}
	const { t, i18n } = useTranslation()
	const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
		if (reason !== "backdropClick") {
			setOpen(false)
		}
	}
	return (
		<>
			<Button
				onClick={() => {
					handleClickOpen()
				}}
				variant="outlined"
			>
				<CodeOutlinedIcon />
			</Button>
			<Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
				<DialogTitle>{t("selectVariable")}</DialogTitle>
				<DialogContent>
					<Box component="form" sx={{ display: "flex", flexWrap: "wrap" }} className="variable-popup">
						<FormControl sx={{ m: 1, minWidth: 120 }}>
							<InputLabel>{t("variable")}</InputLabel>
							<Select input={<OutlinedInput label="Variable" />}>
								{variablesData?.variables.map((e: variables_variables) => {
									return (
										<MenuItem
											key={e.id}
											onClick={event => {
												insertVariable({ id: e.id })
												setOpen(false)
											}}
										>
											{e.label}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}
export default VariableButton
