import * as React from "react"
import { Link as RouterLink } from "react-router-dom"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, FormGroup, FormControlLabel, Switch } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash"
import { useTranslation } from "react-i18next"
import ConfirmDialog from "../shared/ConfirmDialog"
import { djCategories_djCategories } from "./__generated__/djCategories"

const DjCategoriesList = ({ djCategories, archive, unarchive, checked }: { djCategories?: djCategories_djCategories[]; archive: Function; unarchive: Function, checked:boolean }) => {
	const { t, i18n } = useTranslation()
	const [open, openDialog] = React.useState(false)
	const [currentDjCategory, setCurrentDjCategory] = React.useState<djCategories_djCategories | null>(null)
	const openConfirmDialog = (row: djCategories_djCategories) => {
		setCurrentDjCategory(row)
		openDialog(true)
	}
	return (
		<Paper sx={{ width: "100%", mb: 2 }}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{t("label")}</TableCell>
							<TableCell align="right">{t("priceExcludingVatInHighSeason")}</TableCell>
							<TableCell align="right">{t("priceExcludingVatInLowSeason")}</TableCell>
							<TableCell align="right">{t("pricePerOvertimeUnit")}</TableCell>
							<TableCell align="right">{t("compensationPerHourOfCommute")}</TableCell>
							<TableCell align="right">{t("compensationPerOvertimeUnit")}</TableCell>
							<TableCell align="right">{t("compensationInHighSeason")}</TableCell>
							<TableCell align="right">{t("compensationInLowSeason")}</TableCell>
							<TableCell align="right">
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{djCategories?.map((row: djCategories_djCategories) =>
							row?.archivalTimestamp ? (
								checked ? (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell sx={{ backgroundColor: "lightgray" }} component="th" scope="row">
											{row.label}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{(row.priceExcludingVatInHighSeason / 100).toFixed(2)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{(row.priceExcludingVatInLowSeason / 100).toFixed(2)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{(row.pricePerOvertimeUnit / 100).toFixed(2)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{(row.compensationPerHourOfCommute / 100).toFixed(2)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{(row.compensationPerOvertimeUnit / 100).toFixed(2)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{(row.compensationInHighSeason / 100).toFixed(2)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											{(row.compensationInLowSeason / 100).toFixed(2)}
										</TableCell>
										<TableCell sx={{ backgroundColor: "lightgray" }} align="right">
											<div className="table-action-body">
												<RestoreFromTrashIcon className="delete-style" onClick={() => unarchive(row.id)} />
											</div>
										</TableCell>
									</TableRow>
								) : (
									<></>
								)
							) : (
								<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										{row.label}
									</TableCell>
									<TableCell align="right">{(row.priceExcludingVatInHighSeason / 100).toFixed(2)}</TableCell>
									<TableCell align="right">{(row.priceExcludingVatInLowSeason / 100).toFixed(2)}</TableCell>
									<TableCell align="right">{(row.pricePerOvertimeUnit / 100).toFixed(2)}</TableCell>
									<TableCell align="right">{(row.compensationPerHourOfCommute / 100).toFixed(2)}</TableCell>
									<TableCell align="right">{(row.compensationPerOvertimeUnit / 100).toFixed(2)}</TableCell>
									<TableCell align="right">{(row.compensationInHighSeason / 100).toFixed(2)}</TableCell>
									<TableCell align="right">{(row.compensationInLowSeason / 100).toFixed(2)}</TableCell>
									<TableCell align="right">
										<div className="table-action-body">
											<Link component={RouterLink} to={`${row.id}`}>
												<EditOutlinedIcon />
											</Link>
											<RemoveCircleOutlineIcon className="delete-style" onClick={() => openConfirmDialog(row)} />
										</div>
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ConfirmDialog
				open={open}
				openDialog={openDialog}
				content={`Do you want to archive ${currentDjCategory?.label ? currentDjCategory.label : ""}?`}
				Complete={() => {
					archive(currentDjCategory?.id ? currentDjCategory.id : "0")
				}}
			/>
		</Paper>
	)
}
export default DjCategoriesList
