import { useActive, useChainedCommands, useCommands } from "@remirror/react"
import { Button, SvgIcon } from "@mui/material"
import "../Editor.scss"

const HeadingButton = ({ level }: { level: number }) => {
	// Using command chaining
	const chain = useChainedCommands()
	const active = useActive()
	const { toggleHeading, setTextCase } = useCommands()

	return (
		<Button
			onClick={() => {
				chain // Begin a chain
					.toggleHeading({ level })
					.focus()
					.run() // A chain must always be terminated with `.run()`
			}}
			style={{
				backgroundColor: active.heading({ level }) ? "rgb(25, 118, 210)" : undefined,
				color: active.heading({ level }) ? "rgb(255, 255, 255)" : undefined,
				borderColor: active.heading({ level }) ? "rgb(255, 255, 255)" : undefined,
				padding:" 8px 19px"
			}}
			variant="outlined"
			disabled={toggleHeading.enabled({ level }) === false}
		>
			<SvgIcon component={"div"} className="heading-flex">
				H{level.toString()}
			</SvgIcon>
		</Button>
	)
}
export default HeadingButton
