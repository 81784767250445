import { Box, ButtonGroup } from "@mui/material"
import SignatureButton from "./SignatureExtension"
import VariableButton from "./VariableExtension"

const ExtraExtensions = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				"& > *": {
					m: 1,
				},
			}}
		>
			<ButtonGroup variant="outlined" aria-label="outlined button group">
				<SignatureButton />
				<VariableButton />
			</ButtonGroup>
		</Box>
	)
}
export default ExtraExtensions
